<template>
	<v-layout align-start>
		<v-container>
			<v-breadcrumbs class="pl-0" :items="items">
				<template v-slot:item="{ item }">
					<v-breadcrumbs-item
						:to="item.href"
						:disabled="item.disabled"
					>
						{{ item.text}}
					</v-breadcrumbs-item>
				</template>
			</v-breadcrumbs>
			<v-toolbar
				flat
			>
				<v-toolbar-title>Departamentos</v-toolbar-title>
				
				<v-spacer></v-spacer>
				<v-btn
					color="#1486bd"
					dark
					@click="dialog = true"
				>
					<span class="text-capitalize text-body">nuevo</span> 
				</v-btn>
				<v-dialog
				v-model="dialog"
				max-width="500px"
				>
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ formTitle }}</span>
						</v-card-title>

						<v-card-text>
							<v-container>
								<v-form ref="form">
									<p class="mb-2">Nombre</p>
									<v-text-field
										v-model="nameDepartament"
										class="pt-0"
										label="Nombre"
										placeholder="Nombre"
										single-line
										dense
										outlined
										:rules="nameRules"
									>
									</v-text-field>
								</v-form>
							</v-container>
						</v-card-text>

						<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							:disabled="loadingData"
							@click="close"
						>
							<span class="text-capitalize">Cancelar</span>
						</v-btn>
						<v-btn
							color="blue darken-1"
							dark
							:loading="loadingData"
							@click="saveDepartament"
						>
							<span class="text-capitalize">Guardar</span>						
						</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="400px">
					<v-card>
						<p style="color: #1874BF" class="text-h6 text-center pt-4">Informe de departamento</p>
						<v-card-text v-if="(user.idRol == 1)" class="text-center">
							{{`¿Esta seguro de ${editedItem.estado ? 'desactivar' : 'activar'} el departamento ${editedItem.nombreDepartamento}?`}}
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
							<v-btn dark color="blue darken-1" @click="deleteItemConfirm">Si</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
			<v-container v-if="loading" class="d-flex justify-center">
				<v-progress-circular 
					indeterminate
					color="primary"
				></v-progress-circular>
			</v-container>
			<template v-if="departaments.length > 0">
				<v-data-table
					:headers="headers"
					:items="departaments"
					item-key="idDepartamento"
					class="elevation-1 font-weight-light caption "
					
					@click:row="clickRow"
					fixed-header
				>
					<template v-slot:[`item.estado`]="{item}">
						{{item.estado ? 'Activo' : 'Inactivo'}}
					</template>
					<template v-slot:[`item.accion`]="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{on,attrs}">
								<v-btn
									icon
									color="primary"
									class="mr-2"
									@click.stop="editItem(item)"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</template>
							<span>Editar</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{on,attrs}">
								<v-btn
									icon
									:color="item.estado ? 'error' : 'success'"
									class="mr-2"
									@click.stop="deleteItem(item)"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>{{item.estado ? 'mdi-cancel' : 'mdi-check'}}</v-icon>
								</v-btn>
							</template>
							<span>{{item.estado ? 'Desactivar' : 'Activar'}}</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</template>
			<template v-else>
				<v-card elevation="0" outlined v-if="loading == false">
					<v-card-text class="text-center pb-0">No existen Departamentos Registrados.</v-card-text>
					<v-card-text class="text-center pt-0"><v-btn color="blue darken-1" dark small class="my-2 px-2" tile elevation="0" @click="selectDepartaments">Volver a Intentar</v-btn></v-card-text>
				</v-card>
			</template>
			<v-snackbar
				v-model="snackbar"
				:color="colorSnackbar"
			>
				<span class="text-center">{{ textSnackbar }}</span>
			</v-snackbar>
		</v-container>
	</v-layout>  
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
export default {
	data(){
		return {
			dialog: false,
			dialogDelete: false,
			headers: [
				{text: "Nombre", value: "nombreDepartamento", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Estado", value: "estado", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Acciones", value: "accion", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			],
			departaments: [],
			editedIndex: -1,
			nameDepartament: '',
			idDepartament: '',
			creationDate: new Date().toISOString().substring(0, 10),
			nameRules: [
				value => !!value || 'Ingrese un Departamento',
			],
			loading: false,
			loadingData: false,
			snackbar: false,
			colorSnackbar: '',
			textSnackbar: '',
			editedItem: {},
			defaultItem: {
				name: '',
				calories: 0,
				fat: 0,
				carbs: 0,
				protein: 0,
			},
			items: [
				{
					text: 'Departamento',
					disabled: true,
					href: 'departament',
				}
			],
		}
	},
	computed: {
		...mapState(['user']),
		formTitle () {
			return this.editedIndex === -1 ? 'Nuevo departamento' : 'Editar departamento';
		},
	},
	watch: {
		dialog (val) {
			val || this.close()
		},
		dialogDelete (val) {
			val || this.closeDelete()
		},
	},
	created () {
		this.selectDepartaments();
	},
	methods: {
		clickRow(item){
            console.log(item);
			//this.$router.push('/group/'+item.idSemestre);
		},
		async deleteDepartament(data){
			try {
				let response = await axios.put('departaments/deleteDepartament',data);
				if (response.status == 200) {
					this.closeDelete();
					this.notification('warning',response.data['msg']);
					this.selectDepartaments();
				}
			} catch (error) {
				console.log(error);
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		async insertDepartament(data){
			try {
				let response = await axios.post('departaments/insertDepartament',data);
				if (response.status == 200) {
					this.close();
					this.loadingData = false;
					this.notification('success',response.data['msg']);
					this.selectDepartaments();
				}
			} catch (error) {
				console.log(error);
				this.loadingData = false;
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		async updateDepartament(data){
			try {
				let response = await axios.put('departaments/updateDepartament',data);
				if (response.status == 200) {
					this.close();
					this.loadingData = false;
					this.notification('success',response.data['msg']);
					this.selectDepartaments();
				}
			} catch (error) {
				console.log(error);
				this.loadingData = false;
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		saveDepartament () {
			if (this.$refs.form.validate()) {
				if (this.editedIndex > -1) {
					//modificar
					let data = {
						idDepartament: this.idDepartament,
						nameDepartament: this.nameDepartament.trim().toUpperCase()
					}
					this.loadingData = true;
					this.updateDepartament(data);
				} else {
					let data = {
						nameDepartament: this.nameDepartament.trim().toUpperCase(),
					};
					this.loadingData = true;
					
					this.insertDepartament(data);
				}
			}
		},

		async selectDepartaments(){
			try {
				this.loading = true;
				let response = await axios.get('departaments/selectDepartaments/null');
				if (response.status == 200) {
					this.departaments = response.data;
					this.loading = false;
				}
			} catch (error) {
				console.log(error);
				this.loading = false;
			}
		},

		notification(type,text){
			this.snackbar = true;
			this.colorSnackbar = type;
			this.textSnackbar = text;
		},

		editItem (item) {
			this.editedIndex = 1;
			this.editedItem = Object.assign({}, item);
			this.idDepartament = this.editedItem.idDepartamento;
			this.nameDepartament = this.editedItem.nombreDepartamento;
			this.dialog = true;
		},

		deleteItem (item) {
			this.editedItem = Object.assign({}, item)
			this.dialogDelete = true
		},

		deleteItemConfirm () {
			let data = {
				idDepartament: this.editedItem.idDepartamento,
				state: this.editedItem.estado ? 0 : 1,
			}
			this.deleteDepartament(data);
		},

		close () {
			this.nameDepartament = '';
			this.editedIndex = -1;
			this.$refs.form.reset();
			this.dialog = false;
		},

		closeDelete () {
			this.dialogDelete = false;
			this.editedItem = '';
		},

		
	}
}
</script>

<style>
	.td-click td{
		cursor: pointer;
	}
</style>
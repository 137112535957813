<template>
    <v-layout align-start>
        <v-container>
            <v-card
                outlined
            >
                <v-card-title class="py-3 text-body-1 font-weight-medium white--text" style="background-color: #1486bd;">
                    <p class="mb-0">Bienvenido {{user.nombres}}</p>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <p class="font-weight-bold mb-1 text-body-1">Informacion de Usuario</p>
                            <p class="font-weight-bold mb-1">Nombres: <span class="font-weight-regular">{{user.nombres}}</span> </p>
                            <p class="font-weight-bold mb-1">Apellidos: <span class="font-weight-regular">{{user.apellidos}}</span></p>
                            <p class="font-weight-bold mb-1">Ci: <span class="font-weight-regular">{{user.ci}}</span></p>
                            <p class="font-weight-bold mb-1">Tipo de Usuario: <span class="font-weight-regular">{{user.rol}}</span></p>
                        </v-col>
                    </v-row> 
                </v-card-text>
            </v-card>
        </v-container>
    </v-layout>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return{

        }
    },
    computed:{
        ...mapState(['user']),
    },
    created(){

    },
    methods:{

    }
}
</script>

<style>

</style>
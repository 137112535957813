<template>
    <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="700"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="letter"
        pdf-orientation="landscape"
        pdf-content-width="800px"
        :html-to-pdf-options="htmlToPdfOptions"

        
        ref="html2Pdf"
    >
        <section class="mx-5 mt-4 section" slot="pdf-content">
            <div class="row pl-0">
				<div class="col-6 pb-0 pl-0">
					<p class="mt-0 mb-0 text-small">{{ departament }} - BOLIVIA</p>
					<p v-if="user.idDepartamento == idDepartament" class="mt-0 mb-0 text-medium">{{user.sucursal}}</p>
					<p v-if="user.idDepartamento == idDepartament" class="mt-0 mb-0 text-medium">{{user.telefonoSucursal}}</p> 
				</div>
				<div class="col-6 pb-0">
					<div class="row justify-content-end">
						<img :src="require('../../assets/blueLogo2.png')" height="80" class="pt-n4" alt="...">
					</div> 
				</div>
			</div>
            <div class="container mt-n2 pt-0 pb-2">
				<div class="row mt-0 justify-content-center">
					<p class="font-weight-bold h5">RECIBO DE CAJA</p>
				</div>
			</div>
            <div class="row mt-0 mb-1">
                <div class="col-8">
                    <div class="row">
                        <p class="mt-1 font-weight-bold text-small">Nombre: </p><p class="mt-1 pl-1 text-small">{{ item.nombre}}</p>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row justify-content-between">
                        <p class="mt-1 text-small">Recibo de egreso Nro </p><p class="mt-1 text-small">{{item.codigo }}</p>
                    </div>
                    <div class="row justify-content-between">
                        <p class="mt-0 text-small">Fecha: </p><p class="mt-0 text-small">{{ item.fechaRegistro }}</p>
                    </div>
                </div>
            </div>
            <div class="row mt-0 border border-secondary rounded">
                <div class="col-6 pt-2 pl-2 pr-2 pb-0">
					<div class="container p-0 ml-0 mr-1 mt-0 mb-0">
						<div class="d-flex flex-row justify-content-between mx-0 my-0">
							<p class="font-weight-bold text-small mb-0">TOTAL: </p><p class=" text-small mb-0">{{item.total+' Bs.'}}</p>
						</div>
					</div>    
                </div>
                <div class="col-6 align-self-center">
                    
                </div>
				<div class="col-12 px-2 pt-0 pb-2">
					<div class="container p-0 ml-0 mr-1 mt-0 mb-0">
						<div class="d-flex flex-row">
							<p class="mb-1 font-weight-bold text-small">DETALLE: </p>
							<p class="mb-1 ml-2 text-small">{{' '+item.detalleEgreso}}</p>
						</div>
					</div>
				</div>
            </div>
            <div class="row">
				<div class="col-12 px-0">
					<div class="row justify-content-around mt-5">
						<div class="col-5 d-flex justify-content-center pt-1 px-0">
							<div class="border-top border-secondary">
								<p class="h6 small pt-1 px-2">{{ item.nombre }}</p>
							</div>
						</div>
						<div class="col-5 d-flex justify-content-center pt-1 px-0 ">
							<div class="border-top border-secondary">
								<p class="h6 small pt-1 px-2">{{ item.nombres + ' ' + item.apellidos }}</p>
							</div>
						</div>
					</div>
				</div>
            </div>
            <div class="container mt-0">
                <div class="row justify-content-around mt-0">
                    <div class="col-10 rounded px-2 py-2" style="background-color: #1486bd;">
                        <p class="text-white text-center mb-0 h6 small">Nota: no se aceptan devoluciones bajo ningun concepto (Salud, Horarios, Viajes, Accidentes, etc.)</p>
                    </div>
                </div>
            </div>
        </section>
    </vue-html2pdf>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import { mapState } from 'vuex';
export default {
    props:{
        item:{
            type: Object,
            default: () => {},
        },
        user: {
            type: Object,
            default: () => {}
        },
        departament: {
            type: String,
            default: () => ''
        }
    },
    components: {
        VueHtml2pdf
    },
    data(){
        return{
            htmlToPdfOptions: {
                margin: [0,0,0,0],
            
				filename: `Recibo N°.pdf`,
            
                image: {
                    type: 'jpeg', 
                    quality: 1
                },
            
                enableLinks: false,
            
                html2canvas: {
                    scale: 2,
                    useCORS: true
                },
            
                jsPDF: {
                    unit: 'in',
                    format: 'letter',
                    orientation: 'portrait'
                }
            },
        }
    },
    computed:{
		...mapState(['idDepartament']),

    },
    created(){

    },
	watch:{
		item(newValue){
			this.htmlToPdfOptions.filename = `Recibo N° ${newValue.codigo}.pdf`;
		}
	},
    methods:{
        generatePdf(){
            this.$refs.html2Pdf.generatePdf();
        }
    }
}
</script>

<style scoped>
	.mr-5,
    .mx-5 {
    margin-right: 3rem !important;
    }
	.ml-5,
    .mx-5 {
    margin-left: 3rem !important;
    }
	.ml-2,
	.mx-2 {
		margin-left: 0.5rem !important;
	}
	.section{
		font-family: "Avenir", Helvetica, Arial, sans-serif;
	}
	.section::before{
		content: "";
		background-image: 
				url('../../assets/blueLogo.png');
		background-size: 500px;
		background-position: center;
		position: absolute;
		top: 0px;
		right: 0px;/*322px;*/
		bottom: 0px; /*265px;*/
		left: 0px;/*323px;*/
		opacity: 0.25;
	}
	.row {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-right: -15px;
		margin-left: -15px;
    }
	.mt-0,
	.my-0 {
		margin-top: 0 !important;
	}
	.pl-0,
	.px-0 {
		padding-left: 0 !important;
	}
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
	.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
	.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
	.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
	.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
	.col-xl-auto {
		position: relative;
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
	}
	.col-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
    }
	.pb-0,
	.py-0 {
		padding-bottom: 0 !important;
	}
	.text-small{
		font-size: 13px;
	}
	.text-medium{
		font-size: 11px;
	}
	.text-tb-small{
		font-size: 10px;
	}
	.mb-0,
	.my-0 {
		margin-bottom: 0 !important;
	}
	.justify-content-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.mt-n2,
	.my-n2 {
		margin-top: -0.5rem !important;
	}
	.container,
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
    }
	.pt-0,
	.py-0 {
		padding-top: 0 !important;
	}
	.pb-2,
	.py-2 {
		padding-bottom: 0.5rem !important;
	}
	.mb-2,
	.my-2 {
		margin-bottom: 0.5rem !important;
	}
	.justify-content-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
    }
	.col-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
    }
	.align-self-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.font-weight-bold {
		font-weight: 700 !important;
	}
	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		margin-bottom: 0.5rem;
		font-weight: 500;
		line-height: 1.2;
	}
	h5, .h5 {
		font-size: 1.25rem;
	}
	h6, .h6 {
		font-size: 1rem;
	}
	.text-center {
		text-align: center !important;
	}
	.font-weight-light {
		font-weight: 300 !important;
	}
	.mb-1,
	.my-1 {
		margin-bottom: 0.25rem !important;
	}
	.col-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.justify-content-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.pr-1,
	.px-1 {
		padding-right: 0.25rem !important;
	}

	.pb-1,
	.py-1 {
		padding-bottom: 0.25rem !important;
	}

	.pl-1,
	.px-1 {
		padding-left: 0.25rem !important;
	}
	.pl-2,
	.px-2 {
		padding-left: 1rem !important;
	}
	
	.pr-2,
	.px-2 {
		padding-right: 1rem !important;
	}
	.text-right {
		text-align: right !important;
	}
	small,
	.small {
		font-size: 0.875em;
		font-weight: 400;
	}
	.p-0 {
		padding: 0 !important;
	}
	.pt-1,
	.py-1 {
		padding-top: 0.25rem !important;
	}
	.justify-content-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.mt-5,
	.my-5 {
		margin-top: 3rem !important;
	}
	.col-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.border {
		border: 1px solid #dee2e6 !important;
	}
	.border-top {
		border-top: 1px solid #dee2e6 !important;
	}
	.border-secondary {
		border-color: #6c757d !important;
	}
	.d-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.mt-4,
	.my-4 {
		margin-top: 1.5rem !important;
	}
	.align-items-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.rounded {
		border-radius: 0.25rem !important;
	}
	.text-white {
		color: #fff !important;
	}
	.col-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
    }
	.pr-2,
	.px-2 {
		padding-right: 0.5rem !important;
	}
	.pt-2,
	.py-2 {
		padding-top: 0.5rem !important;
	}
	.pl-2,
	.px-2 {
		padding-left: 0.5rem !important;
	}
	.ml-1,
	.mx-1 {
		margin-left: 0.25rem !important;
	}
	.mr-1,
	.mx-1 {
		margin-right: 0.25rem !important;
	}
	.mb-4,
	.my-4 {
		margin-bottom: 1.5rem !important;
	}
	.col-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
    }
	.col-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
    }
	.mt-1,
	.my-1 {
		margin-top: 0.25rem !important;
	}
	.flex-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
    }
</style>
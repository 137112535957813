<template>
	<v-layout align-start>
		<v-container>
			<v-breadcrumbs class="pl-0" :items="items">
				<template v-slot:item="{ item }">
					<v-breadcrumbs-item
						:to="item.href"
						:disabled="item.disabled"
					>
						{{ item.text}}
					</v-breadcrumbs-item>
				</template>
			</v-breadcrumbs>
			<v-toolbar
				flat
			>
				<v-toolbar-title>Usuarios</v-toolbar-title>
				
				<v-spacer></v-spacer>
				<v-btn
					color="#1486bd"
					dark
					@click="dialog = true"
				>
					<span class="text-capitalize text-body">nuevo</span> 
				</v-btn>
				<v-dialog
				v-model="dialog"
				max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ formTitle }}</span>
						</v-card-title>

						<v-card-text>
							<v-container>
								<v-form ref="form">
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            class="py-0"
                                        >
                                            <p class="mb-2">Nombres</p>
                                            <v-text-field
                                                v-model="formUser.names"
                                                class="pt-0"
                                                label="Nombres"
                                                placeholder="Nombres"
                                                single-line
                                                dense
                                                outlined
												autocomplete="null"
                                                :rules="nameRules"
                                            >
                                            </v-text-field>
                                        </v-col>
										<v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            class="py-0"
                                        >
                                            <p class="mb-2">Apellidos</p>
                                            <v-text-field
                                                v-model="formUser.surnames"
                                                class="pt-0"
                                                label="Apellidos"
                                                placeholder="Apellidos"
                                                single-line
                                                dense
                                                outlined
												autocomplete="null"
                                                :rules="surnameRules"
                                            >
                                            </v-text-field>
                                        </v-col>
										<v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            class="py-0"
                                        >
                                            <p class="mb-2">Ci</p>
                                            <v-text-field
                                                v-model="formUser.ci"
                                                class="pt-0"
                                                label="Ci"
                                                placeholder="Ci"
                                                single-line
                                                dense
                                                outlined
												autocomplete="null"
                                                :rules="ciRules"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            class="py-0"
                                        >
                                            <p class="mb-2">Telefono</p>
                                            <v-text-field
                                                v-model="formUser.phone"
                                                class="pt-0"
                                                label="Telefono"
                                                placeholder="Telefono"
                                                single-line
                                                dense
                                                outlined
												autocomplete="null"
                                                :rules="phoneRules"
                                            >
                                            </v-text-field>

                                        </v-col>
										<v-col
											cols="12"
											sm="6"
											md="6"
											class="py-0"
										>
											<p class="mb-2">Usuario</p>
											<v-text-field
												v-model="formUser.user"
												class="pt-0"
												label="Usuario"
												placeholder="Usuario"
												single-line
												dense
												autocomplete="null"
												outlined
											>
											</v-text-field>
										</v-col>
										<v-col
											cols="12"
											sm="6"
											md="6"
											class="py-0"
										>
											<p class="mb-2">Contraseña</p>
											<v-text-field
												v-model="formUser.password"
												class="pt-0"
												:append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
												:type="passwordShow ? 'text' : 'password'"
												label="Password"
												placeholder="Password"
												@click:append="passwordShow = !passwordShow"
												autocomplete="null"
												single-line
												dense
												outlined
											>
											</v-text-field>

										</v-col>
										<v-col
                                            cols="12"
                                            sm="12"
                                            md="12"
                                            class="py-0"
                                        >
                                            <p class="mb-2">Email</p>
                                            <v-text-field
                                                v-model="formUser.email"
                                                class="pt-0"
                                                label="Email"
                                                placeholder="Email"
                                                single-line
                                                dense
                                                outlined
												autocomplete="null"
                                                :rules="emailRules"
                                            >
                                            </v-text-field>

                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            class="py-0"
                                        >
                                            <p class="mb-2">Sucursal</p>
                                            <v-select
                                                v-model="formUser.idBranchoffice"
                                                :items="branchoffices"
                                                item-text="nombreSucursal"
                                                item-value="idSucursal"
                                                label="Sucursal"
                                                persistent-hint
                                                dense
                                                outlined
                                                single-line
                                                :rules="branchofficeRules"
                                            ></v-select>
                                        </v-col>
										<v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            class="py-0"
                                        >
                                            <p class="mb-2">Rol</p>
                                            <v-select
                                                v-model="formUser.idRole"
                                                :items="roles"
                                                item-text="nombreRol"
                                                item-value="idRol"
                                                label="Rol"
                                                persistent-hint
                                                dense
                                                outlined
                                                single-line
                                                :rules="roleRules"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
								</v-form>
							</v-container>
						</v-card-text>

						<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							:disabled="loadingData"
							@click="close"
						>
							<span class="text-capitalize">Cancelar</span>
						</v-btn>
						<v-btn
							color="blue darken-1"
							dark
							:loading="loadingData"
							@click="saveUser"
						>
							<span class="text-capitalize">Guardar</span>						
						</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="400px">
					<v-card>
						<p style="color: #1874BF" class="text-h6 text-center pt-4">Informe de Usuario</p>
						<v-card-text v-if="(user.idRol == 1)" class="text-center">
							{{`¿Esta seguro de ${editedItem.estado ? 'desactivar' : 'activar'} el usuario ${editedItem.nombres}?`}}
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
							<v-btn dark color="blue darken-1" @click="deleteItemConfirm">Si</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
			<v-container v-if="loading" class="d-flex justify-center">
				<v-progress-circular 
					indeterminate
					color="primary"
				></v-progress-circular>
			</v-container>
			<template v-if="users.length > 0">
				<v-data-table
					:headers="headers"
					:items="users"
					item-key="idUsuario"
					class="elevation-1 font-weight-light caption"
					
					fixed-header
				>
                    <template v-slot:[`item.fechaInicio`]="{item}">
                        {{formatDate(item.fechaInicio.substring(0, 10))}}
                    </template>
					<template v-slot:[`item.password`]="{item}">
						{{show ? item.password : '.......'}}
						<v-btn small icon @click="showPassword(item)">
							<v-icon small>{{show ? 'mdi-eye-off' : 'mdi-eye'}}</v-icon>
						</v-btn>
					</template>
					<template v-slot:[`item.estado`]="{item}">
						{{item.estado ? 'Activo' : 'Inactivo'}}
					</template>
					<template v-slot:[`item.accion`]="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{on,attrs}">
								<v-btn
									icon
									color="primary"
									class="mr-2"
									@click.stop="editItem(item)"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</template>
							<span>Editar</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{on,attrs}">
								<v-btn
									icon
									color="warning"
									class="mr-2"
									@click.stop="changeCredentials(item)"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-account-key</v-icon>
								</v-btn>
							</template>
							<span>Cambiar Credenciales</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{on,attrs}">
								<v-btn
									icon
									:color="item.estado ? 'error' : 'success'"
									class="mr-2"
									@click.stop="deleteItem(item)"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>{{item.estado ? 'mdi-cancel' : 'mdi-check'}}</v-icon>
								</v-btn>
							</template>
							<span>{{item.estado ? 'Desactivar' : 'Activar'}}</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</template>
			<template v-else>
				<v-card elevation="0" outlined v-if="loading == false">
					<v-card-text class="text-center pb-0">No existen Usuarios Registrados.</v-card-text>
					<v-card-text class="text-center pt-0"><v-btn color="blue darken-1" dark small class="my-2 px-2" tile elevation="0" @click="selectUsers">Volver a Intentar</v-btn></v-card-text>
				</v-card>
			</template>
			<v-snackbar
				v-model="snackbar"
				:color="colorSnackbar"
				text
			>
				<span class="text-center">{{ textSnackbar }}</span>
			</v-snackbar>
			<CredentialChangeUser ref="credentialChange" @notification="notificationChange" @deleteUserSelected="deleteUserSelected" :user="userSelected"/>
		</v-container>
	</v-layout>  
</template>

<script>
import axios from 'axios';
import crypto from 'crypto-js';
import CredentialChangeUser from '@/components/credential/credential-change-user-component.vue';
import { mapState } from 'vuex';
export default {
	components:{
		CredentialChangeUser,
	},
	data(){
		return {
			dialog: false,
			dialogDelete: false,
			headers: [
				{text: "Ci", value: "ci", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Apellidos", value: "apellidos", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Nombres", value: "nombres", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Email", value: "email", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				//{text: "Usuario", value: "usuario", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				//{text: "Password", value: "password", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Rol", value: "nombreRol", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Estado", value: "estado", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Acciones", value: "accion", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			],
			users: [],
			editedIndex: -1,
            formUser:{
                idUser: '',
				names: '',
				surnames: '',
				ci: '',
				email: '',
				phone: '',
				idBranchoffice: '',
				idRole: '',
                creationDate: new Date().toISOString().substring(0, 10),
				user: '',
				password: '',
            },
			nameRules: [
				value => !!value || 'Ingrese nombres',
			],
            surnameRules: [
                value => !!value || 'Ingrese apellidos'
            ],
            ciRules: [
                value => !!value || 'Ingrese un ci'
            ],
            emailRules: [
                value => !!value || 'Ingrese un email',
                value => /.+@.+\..+/.test(value) || 'Debe ingresar un email correcto',
            ],
            phoneRules: [
                value => !!value || 'Ingrese un numero de telefono',
                value => /^[0-9]+$/.test(value) || 'Debe ingresar un telefono valido'
            ],
			branchofficeRules: [
                value => !!value || 'Seleccione una sucursal'
            ],
            roleRules: [
                value => !!value || 'Seleccione un rol',
            ],
			loading: false,
			loadingData: false,
			snackbar: false,
			colorSnackbar: '',
			textSnackbar: '',
			editedItem: {},
			defaultItem: {
				name: '',
				calories: 0,
				fat: 0,
				carbs: 0,
				protein: 0,
			},
			items: [
				{
					text: 'Usuarios',
					disabled: false,
					href: '/user',
				},
			],
            menu2: false,
            branchoffices: [],
			roles: [],
			show: false,
			passwordShow: false,
			userSelected: {},
		}
	},
	computed: {
		...mapState(['user','idDepartament']),
		formTitle () {
			return this.editedIndex === -1 ? 'Nuevo Usuario' : 'Editar Usuario';
		},
        computedDateFormatted () {
            return this.formatDate(this.formUser.startDate);
        },
	},
	watch: {
		dialog (val) {
			val || this.close()
		},
		dialogDelete (val) {
			val || this.closeDelete()
		},
	},
	created () {
		this.selectUsers();
        this.selectBrancoffices();
		this.selectRoles();
	},
	methods: {
		notificationChange(response){
			this.notification(response.type,response.text);
			this.selectUsers();
		},
		deleteUserSelected(){
			this.userSelected = {};
		},
		changeCredentials(item){
			this.userSelected = item;
			this.$refs.credentialChange.openDialog();
		},
		showPassword(item){
			console.log(item);
			this.show = !this.show;
		},
		clickRow(){

		},
		async deleteUser(data){
			try {
				let response = await axios.put('users/deleteUser',data);
				if (response.status == 200) {
					this.closeDelete();
					this.notification('warning',response.data['msg']);
					this.selectUsers();
				}
			} catch (error) {
				console.log(error);
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		async insertUser(data){
			try {
				let response = await axios.post('users/insertUser',data);
				if (response.status == 200) {
					this.close();
					this.loadingData = false;
					this.notification('success',response.data['msg']);
					this.selectUsers();
				}
			} catch (error) {
				console.log(error);
				this.loadingData = false;
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		async updateUser(data){
			try {
				let response = await axios.put('users/updateUser',data);
				if (response.status == 200) {
					this.close();
					this.loadingData = false;
					this.notification('success',response.data['msg']);
					this.selectUsers();
				}
			} catch (error) {
				console.log(error);
				this.loadingData = false;
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		saveUser () {
			if (this.$refs.form.validate()) {
				if (this.editedIndex > -1) {
					//modificar
					let data = {
						names: this.formUser.names.toUpperCase().trim(),
						surnames: this.formUser.surnames.toUpperCase().trim(),
						ci: this.formUser.ci.trim(),
						email: this.formUser.email.trim(),
						phone: this.formUser.phone.trim(),
						idBranchoffice: this.formUser.idBranchoffice,
						idRole: this.formUser.idRole,
						idUser: this.formUser.idUser
					}
					this.loadingData = true;
					this.updateUser(data);
				} else {
					let data = {
						names: this.formUser.names.toUpperCase().trim(),
						surnames: this.formUser.surnames.toUpperCase().trim(),
						ci: this.formUser.ci.trim(),
						email: this.formUser.email.trim(),
						phone: this.formUser.phone.trim(),
						idBranchoffice: this.formUser.idBranchoffice,
						idRole: this.formUser.idRole,
						user: this.formUser.user,
						pass: this.formUser.password,
					};
					this.loadingData = true;
					
					this.insertUser(data);
				}
			}
		},

		async selectUsers(){
			try {
				this.loading = true;
				let response = await axios.get(`users/selectUsers/${this.idDepartament}/null`);
				if (response.status == 200) {
					this.users = response.data;
					this.loading = false;
				}
			} catch (error) {
				console.log(error);
				this.loading = false;
			}
		},
        async selectBrancoffices(){
            try {
                let response = await axios.get(`branchoffices/selectBranchoffices/${this.idDepartament}/1`);
                if (response.status == 200) {
                    this.branchoffices = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
		async selectRoles(){
			try {
				let response = await axios.get('roles/selectRoles/1');
				if (response.status == 200) {
					this.roles = response.data;
				}
			} catch (error) {
				console.log(error);
			}
		},

		notification(type,text){
			this.snackbar = true;
			this.colorSnackbar = type;
			this.textSnackbar = text;
		},

		editItem (item) {
			this.editedIndex = 1;
			this.editedItem = Object.assign({}, item);
			this.formUser.idUser = this.editedItem.idUsuario;
			this.formUser.names = this.editedItem.nombres;
			this.formUser.surnames = this.editedItem.apellidos;
			this.formUser.ci = this.editedItem.ci;
			this.formUser.email = this.editedItem.email;
			this.formUser.phone = this.editedItem.telefono;
			this.formUser.idBranchoffice = this.editedItem.idSucursal;
			this.formUser.idRole = this.editedItem.idRol;
			this.formUser.user = this.editedItem.usuario;
			this.formUser.password = this.editedItem.password;
			this.dialog = true;
		},

		decryptPassword(encrypt){
			const passDecrypt = crypto.AES.encrypt('hellomoto25784ytT','w9z$B&E)H@McQfTjWnZr4u7x!A%D*F-JaNdRgUkXp2s5v8y/B?E(H+KbPeSh');
			console.log(passDecrypt);
			console.log(crypto.enc.Base64.parse(encrypt));
		},

		deleteItem (item) {
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm () {
			let data = {
				idUser: this.editedItem.idUsuario,
				state: this.editedItem.estado ? 0 : 1,
			}
			this.deleteUser(data);
		},

		close () {
			this.editedIndex = -1;
			this.$refs.form.resetValidation();
			this.formUser.idUser = '';
			this.formUser.names = '';
			this.formUser.surnames = '';
			this.formUser.ci = '';
			this.formUser.email = '';
			this.formUser.phone = '';
			this.formUser.idBranchoffice = '';
			this.formUser.idRole = '';
			this.formUser.user = '';
			this.formUser.password = '';
			this.dialog = false;
		},

		closeDelete () {
			this.dialogDelete = false;
			this.editedItem = '';
		},
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
		
	}
}
</script>

<style>
	/*.td-click td{
		/*cursor: pointer;
	}*/
</style>
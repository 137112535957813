<template>
    <v-layout align-start>
        <v-container>
            <v-breadcrumbs class="pl-0" :items="items">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        :disabled="item.disabled"
                    >
                        {{ item.text}}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
            <v-card outlined tile class="pb-2">
                <v-card-text class="pb-0" >
                    <v-row class="ml-1">
                        <v-col cols="12" sm="6" md="6">
                            <p class="font-weight-medium text-h6 mb-0" style="color: #1486bd;">Informacion del Profesor</p>
                            <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Nombres: <span class="caption ">{{teacher.nombres}}</span></p>
                            <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Apellidos: <span class="caption">{{teacher.apellidos}}</span></p>
                            <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">CI: <span class="caption">{{teacher.ci}}</span></p>
                            <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Tipo: <span class="caption">{{teacher.nombreRol}}</span></p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-row class="fill-height mt-1">
                <v-col>
                    <v-sheet class="d-none d-sm-flex flex-column">
                        <v-card
                            tile
                            elevation="0"
                        >
                            <v-card-text>
                                <v-row>
                                    <v-col class=" py-2" cols="4" sm="4" md="4">
                                        <v-container class="pa-0">
                                            <v-btn
                                                outlined
                                                class="mr-4"
                                                color="grey darken-2"
                                                small
                                                @click="setToday"
                                            >
                                                Hoy
                                            </v-btn>
                                            <v-btn
                                                fab
                                                text
                                                small
                                                color="grey darken-2"
                                                @click="prev"
                                            >
                                                <v-icon small>
                                                mdi-chevron-left
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                fab
                                                text
                                                small
                                                color="grey darken-2"
                                                @click="next"
                                            >
                                                <v-icon small>
                                                mdi-chevron-right
                                                </v-icon>
                                            </v-btn>
                                        </v-container>
                                    </v-col>
                                    <v-col class="d-flex align-center justify-center py-2" cols="4" sm="4" md="4">
                                        <p class="mb-0 text-body-1 font-weight-bold">{{ titleCalendar }}</p>
                                    </v-col>
                                    <v-col class="d-flex align-center justify-end py-2" cols="4" sm="4" md="4">
                                        <v-btn-toggle
                                            v-model="type"
                                            mandatory
                                            >
                                            <v-btn class="text-capitalize" small value="month">
                                                Mes
                                            </v-btn>
                                            <v-btn class="text-capitalize" small value="week">
                                                Semana
                                            </v-btn>
                                            <v-btn class="text-capitalize" small value="day">
                                                Dia
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-sheet>
                    <v-sheet class="d-flex d-sm-none flex-column">
                        <v-card
                            tile
                            elevation="0"
                        >
                            <v-card-text>
                                <v-row>
                                    <v-container class="d-flex flex-row justify-space-between pb-0">
                                        <div class="d-flex flex-row align-center">
                                            <v-btn
                                                outlined
                                                color="grey darken-2"
                                                small
                                                @click="setToday"
                                            >
                                                Hoy
                                            </v-btn>
                                        </div>   
                                        <div>
                                            <v-btn
                                                fab
                                                text
                                                small
                                                color="grey darken-2"
                                                @click="prev"
                                            >
                                                <v-icon small>
                                                mdi-chevron-left
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                fab
                                                text
                                                small
                                                color="grey darken-2"
                                                @click="next"
                                            >
                                                <v-icon small>
                                                mdi-chevron-right
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-container>
                                </v-row>
                                <v-row>
                                    <v-container class="d-flex flex-row justify-space-between">
                                        <p class="mb-0 text-body-1 font-weight-bold">{{ titleCalendar }}</p>
                                        <v-btn-toggle
                                            v-model="type"
                                            mandatory
                                            >
                                            <v-btn class="text-capitalize" small value="month">
                                                Mes
                                            </v-btn>
                                            <v-btn class="text-capitalize" small value="week">
                                                Semana
                                            </v-btn>
                                            <v-btn class="text-capitalize" small value="day">
                                                Dia
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-container>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-sheet>
                    <!--
                        @mousedown:event="startDrag"
                        @mousedown:time="startTime"
                        @mousemove:time="mouseMove"
                        @mouseup:time="endDrag"
                        @mouseleave.native="cancelDrag"
                    -->
                    <v-sheet height="900">
                        <v-calendar
                            ref="calendar"
                            v-model="focus"
                            color="primary"
                            :type="type"
                            :events="events"
                            :event-color="getEventColor"
                            :event-text-color="getEventTextColor"
                            @change="getEvents"
                            @mousedown:event="startDrag"
                            @mousedown:time="startTime"
                            @mousemove:time="mouseMove"
                            @click:event="showEvent"
                            @mouseup:time="endDrag"
                            
                            first-time="08:00"
                            :short-intervals="false"
                            interval-count="16"
                            :weekdays="weekdays"
                        >
                        </v-calendar>
                        <v-menu
                            v-model="selectedOpen"
                            :close-on-content-click="false"
                            :close-on-click="false"
                            :activator="selectedElement"
                            offset-y
                        >
                            <v-card
                                color="grey lighten-4"
                                min-width="350px"
                                flat
                            >
                                <v-toolbar
                                :color="getEventColor(selectedEvent)"
                                :dark="getTextColor(selectedEvent)"
                                elevation="1"
                                >
                                    <v-toolbar-title>{{selectedEvent.originalName}}<span v-show="selectedEvent.virtual"> (Virtual)</span></v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <!--v-btn icon v-if="selectedEvent.idGroupTeacher != null || selectedEvent.idActivitieTeacher != null" @click="editEvent()">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn-->
                                    <v-btn icon v-if="selectedEvent.idGroupTeacher != null || selectedEvent.idActivitieTeacher != null" @click="removeEvent()">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-card-text>
                                    <div v-if="selectedEvent.idClass != null">
                                        <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Materia: <span class="caption ">{{selectedEvent.subject}}</span></p>
                                        <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Estudiante: <span class="caption ">{{selectedEvent.firstName +' '+selectedEvent.lastName}}</span></p>
                                        <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Telefono: <span class="caption ">{{selectedEvent.phone}}</span></p>
                                        <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Nivel: <span class="caption">{{selectedEvent.nameLevel}}</span></p>
                                        <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Nro Alumnos: <span class="caption">{{selectedEvent.numberStudents}}</span></p>
                                        <p v-show="selectedEvent.description != null || selectedEvent.description != ''" style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Descripcion: <span class="caption">{{selectedEvent.description}}</span></p>
                                    </div>
                                    <span v-else v-html="selectedEvent.name"></span>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn
                                        class="text-capitalize"
                                        text
                                        color="secondary"
                                        @click="selectedOpen = false; clicked = false"
                                    >
                                        Cerrar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-dialog
				v-model="dialogGroup"
				max-width="600px"
			>
				<v-card>
					<v-card-title>
						<span class="text-h5">{{ 'Agregar horario para '+nameActivities }}</span>
                        <v-spacer></v-spacer>
                        <v-switch
                            v-model="activitiesSwitch"
                            label="Actividades"
                        ></v-switch>
					</v-card-title>

					<v-card-text v-show="!activitiesSwitch">
						<v-container>
							<v-form ref="formGroup">
								<v-row>
									<v-col
										cols="12"
										sm="6"
										md="6"
										class="py-0"
									>
										<p class="mb-2">Grupos</p>
										<v-autocomplete
											v-model="formGroup.idGroup"
											:items="groups"
											:filter="groupFilter"
											outlined
											dense
											item-text="nombreGrupo"
											item-value="idGrupo"
                                            label="Grupo"
                                            single-line
											:rules="groupRules"
											no-data-text="No hay grupos disponibles"
										></v-autocomplete>
									</v-col>
                                    <v-col
										cols="12"
										sm="6"
										md="6"
										class="py-0"
									>
										<p class="mb-2">Bloquear</p>
										<v-select
                                            v-model="formGroup.weeks"
                                            :items="weeks"
                                            item-text="nameWeek"
                                            item-value="idWeek"
                                            label="Semana"
                                            persistent-hint
                                            dense
                                            outlined
                                            single-line
                                            :rules="weekRules"
                                        ></v-select>
									</v-col>
									<v-col
										cols="12"
										sm="6"
										md="6"
									>
										<p class="mb-2">Inicio</p>
                                        <v-text-field
                                            v-model="formGroup.timeStart"
                                            outlined
                                            dense
                                            single-line
                                            type="time"
                                            :rules="timeStartRules"
                                        ></v-text-field>
									</v-col>
                                    <v-col
										cols="12"
										sm="6"
										md="6"
									>
										<p class="mb-2">Fin</p>
                                        <v-text-field
                                            v-model="formGroup.timeEnd"    
                                            outlined
                                            dense
                                            single-line
                                            type="time"
                                            :rules="timeEndRules"
                                        ></v-text-field>
									</v-col>
								</v-row>
							</v-form>
						</v-container>
					</v-card-text>
                    <v-card-text v-show="activitiesSwitch">
						<v-container>
							<v-form ref="formGroup">
								<v-row>
                                    <v-col
										cols="12"
										sm="12"
										md="12"
										class="py-0"
									>
										<p class="mb-2">Bloquear</p>
										<v-select
                                            v-model="formGroup.weeks"
                                            :items="weeksActivities"
                                            item-text="nameWeek"
                                            item-value="idWeek"
                                            label="Semana"
                                            persistent-hint
                                            dense
                                            outlined
                                            single-line
                                            :rules="weekRules"
                                        ></v-select>
									</v-col>
									<v-col
										cols="12"
										sm="6"
										md="6"
									>
										<p class="mb-2">Inicio</p>
                                        <v-text-field
                                            v-model="formGroup.timeStart"
                                            outlined
                                            dense
                                            single-line
                                            type="time"
                                            :rules="timeStartRules"
                                        ></v-text-field>
									</v-col>
                                    <v-col
										cols="12"
										sm="6"
										md="6"
									>
										<p class="mb-2">Fin</p>
                                        <v-text-field
                                            v-model="formGroup.timeEnd"    
                                            outlined
                                            dense
                                            single-line
                                            type="time"
                                            :rules="timeEndRules"
                                        ></v-text-field>
									</v-col>
								</v-row>
							</v-form>
						</v-container>
					</v-card-text>


					<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="closeGroupSchedule"
					>
						<span class="text-capitalize">Cancelar</span>
					</v-btn>
                    <v-btn
                        color="blue darken-1"
                        dark
                        :loading="loadingData"
                        @click="saveGroupSchedule"
                    >
                        <span class="text-capitalize">Guardar Cambios</span>						
                    </v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
            <v-snackbar
				v-model="snackbar"
				:color="colorSnackbar"
                text
			>
				<span class="text-center">{{ textSnackbar }}</span>
			</v-snackbar>
            <v-dialog v-model="dialogDelete" max-width="400px">
				<v-card>
					<p style="color: #1874BF" class="text-h6 text-center pt-4">Informe de evento</p>
					<v-card-text class="text-center">
						¿Esta seguro de eliminar el evento?
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
                        <v-btn class="text-capitalize" text dark color="blue darken-1" @click="dialogDelete = false">No</v-btn>
						<v-btn class="text-capitalize" dark color="blue darken-1" @click="removeEventConfirm()">Si</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</v-card>
			</v-dialog>
        </v-container>
    </v-layout>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
export default {
    data(){
        return {
            type: 'week',
            types: ['month', 'week', 'day', '4day'],
            focus: '',
            value: '',
            colors: ['#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575'],
            names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
            dragEvent: null,
            dragStart: null,
            createEvent: null,
            createStart: null,
            extendOriginal: null,
            weekdays: [1,2,3,4,5,6],
            titleCalendar: '',
            toogle: '',
            items: [
				{
					text: 'Bloqueo de Clases',
					disabled: false,
					href: '/subject-schedule',
				},
			],
            teacher: {},
            typeCheck: '1',
            events: [],
            loadingData: false,
            dialogGroup: false,
            groups: [],
            formGroup: {
                idGroup: '',
                weeks: '',
                timeStart: '',
                timeEnd: '',
                date: '',
            },
            groupRules: [
                value => !!value || 'Debe ingresar un Grupo',
            ],
            weekRules: [
                value => !!value || 'Debe ingresar la cantidad de semanas',
            ],
            timeStartRules: [
                value => !!value || 'Debe ingresar una hora',
            ],
            timeEndRules: [
                value => !!value || 'Debe ingresar una hora',
            ],
            weeks: [
                {
                    idWeek: '1',
                    nameWeek: '1 semana',
                },
                {
                    idWeek: '2',
                    nameWeek: '2 semanas',
                },
                {
                    idWeek: '3',
                    nameWeek: '3 semanas',
                },
                {
                    idWeek: '4',
                    nameWeek: '4 semanas',
                },
                {
                    idWeek: '5',
                    nameWeek: '5 semanas',
                },
                {
                    idWeek: '6',
                    nameWeek: '6 semanas',
                },
                {
                    idWeek: '7',
                    nameWeek: '7 semanas',
                },
                {
                    idWeek: '8',
                    nameWeek: '8 semanas',
                },
                {
                    idWeek: '9',
                    nameWeek: '9 semanas',
                },
                {
                    idWeek: '10',
                    nameWeek: '10 semanas',
                },
                {
                    idWeek: '11',
                    nameWeek: '11 semanas',
                },
                {
                    idWeek: '12',
                    nameWeek: '12 semanas',
                },
                {
                    idWeek: '13',
                    nameWeek: '13 semanas',
                },
                {
                    idWeek: '14',
                    nameWeek: '14 semanas',
                },
                {
                    idWeek: '15',
                    nameWeek: '15 semanas',
                },
                {
                    idWeek: '16',
                    nameWeek: '16 semanas',
                },
                {
                    idWeek: '17',
                    nameWeek: '17 semanas',
                },
                {
                    idWeek: '18',
                    nameWeek: '18 semanas',
                },
            ],
            formClass: {
                idSubject: '',
                idStudent: '',
                idLevel: '',
                numberStudents: '',
                type: '',
                timeStart: '',
                timeEnd: '',
                date: '',
                idReservationStudent: 0,
                description: '',
                virtual: false,
            },
            eventItem: '',
            snackbar: false,
			colorSnackbar: '',
			textSnackbar: '',
            startDate: '',
            endDate: '',
            lessonLevels: [],
            subjects: [],
            dialogClass: false,
            numberClass: 0,
            dialogInfo: false,
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            dialogDelete: false,
            menu: false,
            dialogEventUpdate: false,
            selectedItem: '',
            info:{},
            idPayment: '',
            payments: [],
            lessons: [],
            receipt: '',
            clicked: false,
            activitiesSwitch: false,
            weeksActivities: [
                {
                    idWeek: '1',
                    nameWeek: '1 semana',
                },
                {
                    idWeek: '2',
                    nameWeek: '2 semanas',
                },
                {
                    idWeek: '3',
                    nameWeek: '3 semanas',
                },
                {
                    idWeek: '4',
                    nameWeek: '4 semanas',
                },
                {
                    idWeek: '5',
                    nameWeek: '5 semanas',
                },
                {
                    idWeek: '6',
                    nameWeek: '6 semanas',
                },
                {
                    idWeek: '7',
                    nameWeek: '7 semanas',
                },
                {
                    idWeek: '8',
                    nameWeek: '8 semanas',
                },
            ],
        }
    },
    computed: {
        ...mapState(['idDepartament','user']),
        nameActivities(){
            return this.activitiesSwitch ? 'Actividades' : ' Grupos';
        },
    },
    mounted () {
      this.$refs.calendar.checkChange();
    },
    watch:{
        dialogGroup(val){
            val || this.closeGroupSchedule();
        },
        dialogEventUpdate(val){
            val || this.closeEventUpdate();
        }
    },
    created(){
        this.selectTeacher();
        this.selectSubjectTeacher();
        this.selectGroupByYear();
    },
    methods:{
        //Other Module
        updateEvent(){
            if (this.$refs.formClass.validate()) {
                const datetimestart = this.formClass.date +' '+this.formClass.timeStart+':00';
                const datetimeend = this.formClass.date+' '+this.formClass.timeEnd+':00';

                let data = {
                    idSubject: this.formClass.idSubject,
                    idTeacher: this.$route.params.idTeacher,
                    idLevel: this.formClass.idLevel,
                    idSchedule: this.selectedEvent.idSchedule,
                    startDate: datetimestart,
                    endDate: datetimeend,
                    idLesson: this.selectedEvent.idClass,
                    numberStudent: this.student.numberStudents,
                    idReservation: this.selectedEvent.idReservationStudent,
                    description: this.formClass.description.trim().toUpperCase(),
                    virtual: this.formClass.virtual,
                }
                
                this.updateLesson(data);
            }
        },
        editEvent(){
            this.info = {
                nombres: this.teacher.nombres,
                apellidos: this.teacher.apellidos,
                tipo: this.selectedEvent.initials,
                nroEstudiante: this.selectedEvent.numberStudents
            }
            
            this.dialogEventUpdate = true;
            this.selectedOpen = false;

        },
        async deleteEvent(url,data){
            try {
                let response = await axios.put(url,data);
                if (response.status == 200) {
                    this.notification('success',response.data['msg']);
                    this.closeDelete();
                    this.selectGroupTeacher();
                }
            } catch (error) {
                console.log(error);
                this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
            }
        },
        removeEventConfirm(){
            let data;
            let url;
            if (this.selectedEvent.idActivitieTeacher != null) {
                url = 'activities/deleteActivitieTeacher';
                data = {
                    state: 0,
                    idActivitieTeacher: this.selectedEvent.idActivitieTeacher,
                }
            } else {
                url = 'groupTeachers/deleteGroupTeacher';
                data = {
                    state: 0,
                    idGroupTeacher: this.selectedEvent.idGroupTeacher,
                }
            }

            this.deleteEvent(url,data);
        },
        removeEvent(){
            if (this.selectedEvent.type == 3 || this.selectedEvent.type == 2) {
                this.notification('warning','Una vez pagada la clase no puede eliminarla');
                return;
            }
            
            this.dialogDelete = true;
        },
        getTextColor(event){
            if (event.idGroupTeacher != null) {
                return true;
            }else{
                if(event.type == 3 && event.virtual){
                    return true;
                } else if (event.type == 1) {
                    return false;                    
                }else{
                    return true;
                }
            }
        },
        getEventTextColor(event){
            if (event.idGroupTeacher != null) {
                return 'white';
            }else{
                if(event.type == 3 && event.virtual){
                    return 'white';
                } else if (event.type == 1) {
                    return 'grey darken-1';                    
                }else{
                    return 'white';
                }
            }
        },
        getEventColor (event) {
            if (event.idGroupTeacher != null) {
                return 'light-blue';
            }else{
                if(event.idReservationStudent == this.formClass.idReservationStudent && Object.keys(this.student).length != 0){
                    return 'amber';
                }
                if(event.type == 3 && event.virtual){
                    return 'pink';
                } else if (event.type == 1) {
                    return 'yellow accent-1';                    
                }else if(event.type == 2){
                    return 'blue darken-1';
                }else if(event.type == 3){
                    return 'green';
                }else if(event.type == 4){
                    return 'purple';
                }else if(event.type == 5){
                    return 'light-green';
                }else if(event.type == 8){
                    return 'red';
                }else{
                    return 'light-blue';
                }
            }
        },
        showEvent ({ nativeEvent, event }) {
            console.log(event);
            this.dialogClass = false;
            this.dialogGroup = false;
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false;
                requestAnimationFrame(() => requestAnimationFrame(() => open()));
            } else {
                open();
            }

            nativeEvent.stopPropagation();
        },
        async insertGroupTeacher(data){
            try {
                let response = await axios.post('groupTeachers/insertGroupTeacher',data);
                if (response.status == 200) {
                    this.notification('success',response.data['msg']);
                    this.selectGroupByYear();
                    this.selectGroupTeacher();
                    this.dialogGroup = false;
                    this.formGroup.idGroup = '';
                    this.formGroup.weeks = '';
                    this.formGroup.dateEnd = '';
                    this.formGroup.dateStart = '';
                    this.loadingData = false;
                }
            } catch (error) {
                console.log(error);
                this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
                this.loadingData = false;
            }
        },
        async insertActivitieTeacher(data){
            try {
                let response = await axios.post('activities/insertActivitieTeacher',data);
                if (response.status == 200) {
                    this.notification('success',response.data['msg']);
                    this.selectGroupByYear();
                    this.selectGroupTeacher();
                    this.dialogGroup = false;
                    this.formGroup.idGroup = '';
                    this.formGroup.weeks = '';
                    this.formGroup.dateEnd = '';
                    this.formGroup.dateStart = '';
                    this.activitiesSwitch = false;
                    this.loadingData = false;
                }
            } catch (error) {
                console.log(error);
                this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
                this.loadingData = false;
            }
        },
        saveGroupSchedule(){
            if (this.$refs.formGroup.validate()) {
                const datetimestart = this.formGroup.date +' '+this.formGroup.timeStart+':00';
                const datetimeend = this.formGroup.date+' '+this.formGroup.timeEnd+':00';
                this.loadingData = true;

                if (this.activitiesSwitch) {
                    let data = {
                        description: 'BLOQUEADO',
                        idTeacher: this.user.idUsuario,
                        startDate: datetimestart,
                        endDate: datetimeend,
                        weeks: this.formGroup.weeks,
                        idDepartament: this.idDepartament,
                    }
                    
                    this.insertActivitieTeacher(data);
                }else {
                    let data = {
                        idGroup: this.formGroup.idGroup,
                        idTeacher: this.user.idUsuario,
                        startDate: datetimestart,
                        endDate: datetimeend,
                        weeks: this.formGroup.weeks,
                    }
                    
                    this.insertGroupTeacher(data);
                }
                
            }   
        },
        groupFilter (item, queryText) {
			const textOne = item.nombreGrupo.toLowerCase();
			const searchText = queryText.toLowerCase();

			return textOne.indexOf(searchText) > -1;
		},
        setToday () {
            this.focus = '';
        },
        prev () {
            this.$refs.calendar.prev();
        },
        next () {
            this.$refs.calendar.next();
        },
        startDrag ({ event, timed }) {
            if (event && timed) {
                this.dragEvent = event;
                this.dragTime = null;
                this.extendOriginal = null;
            }
        },
        startTime (tms) {
            const mouse = this.toTime(tms);

            if (this.dragEvent && this.dragTime === null) {
                const start = this.dragEvent.start;

                this.dragTime = mouse - start;
            } else {
                const mouseRounded = this.roundTime(mouse, false);
                this.createStart = this.roundTime(mouse)

                let date = new Date(mouse);
                date.setHours(date.getHours()-4);
                
                const min = Math.min(mouseRounded, this.createStart);
                const max = Math.max(mouseRounded, this.createStart);

                const dateStart = new Date(min);
                const dateEnd = new Date(max);

                this.createEvent = {
                    name: `Event #${this.events.length}`,
                    color: '#03A9F4',
                    start: min,
                    end: max,
                    timed: true,
                }

                this.formGroup.date = date.toISOString().substring(0,10);
                this.formGroup.timeStart = ((dateStart.getHours() < 10 ? '0' : '')+dateStart.getHours())+':'+((dateStart.getMinutes() < 10 ? '0' : '')+dateStart.getMinutes());
                this.formGroup.timeEnd = ((dateEnd.getHours() < 10 ? '0' : '')+dateEnd.getHours())+':'+((dateEnd.getMinutes() < 10 ? '0' : '')+dateEnd.getMinutes());

                this.events.push(this.createEvent);
                this.eventItem = this.createEvent;
            //console.log(this.createEvent);
            }
            
            
        },
        //al extender la nota
        extendBottom (event) {
            //console.log(event);
            this.createEvent = event;
            this.createStart = event.start;
            this.extendOriginal = event.end;
        },
        mouseMove (tms) {
            const mouse = this.toTime(tms);

            if (this.createEvent && this.createStart !== null) {
                const mouseRounded = this.roundTime(mouse, false);
                const min = Math.min(mouseRounded, this.createStart);
                const max = Math.max(mouseRounded, this.createStart);

                let date = new Date(mouse);
                date.setHours(date.getHours()-4);

                const dateStart = new Date(min);
                const dateEnd = new Date(max);

                this.createEvent.start = min;
                this.createEvent.end = max;
                
               
                this.formGroup.date = date.toISOString().substring(0,10);
                this.formGroup.timeStart = ((dateStart.getHours() < 10 ? '0' : '')+dateStart.getHours())+':'+((dateStart.getMinutes() < 10 ? '0' : '')+dateStart.getMinutes());
                this.formGroup.timeEnd = ((dateEnd.getHours() < 10 ? '0' : '')+dateEnd.getHours())+':'+((dateEnd.getMinutes() < 10 ? '0' : '')+dateEnd.getMinutes());
                    
            }
        },
        endDrag () {
            this.dragTime = null;
            this.dragEvent = null;
            this.createEvent = null;
            this.createStart = null;
            this.extendOriginal = null;
            
            
            this.dialogGroup = true;
            
        },
        cancelDrag () {
            if (this.createEvent) {
                if (this.extendOriginal) {
                    this.createEvent.end = this.extendOriginal
                } else {
                    const i = this.events.indexOf(this.createEvent)
                    if (i !== -1) {
                        this.events.splice(i, 1)
                    }
                }
            }

            this.createEvent = null
            this.createStart = null
            this.dragTime = null
            this.dragEvent = null
        },
        roundTime (time, down = true) {
            const roundTo = 30 // minutes
            const roundDownTime = roundTo * 60 * 1000

            return down
            ? time - time % roundDownTime
            : time + (roundDownTime - (time % roundDownTime))
        },
        toTime (tms) {
            return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
        },
        getEvents ({ start, end }) {
            const endDate = new Date(end.date);
            endDate.setDate(endDate.getDate()+1);

            this.startDate = start.date;
            this.endDate = endDate.toISOString().substring(0,10);
           
            this.selectGroupTeacher();
            this.titleCalendar = this.$refs.calendar.title.toUpperCase();
        },
        rnd (a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
        rndElement (arr) {
            return arr[this.rnd(0, arr.length - 1)]
        },
        async selectGroupTeacher(){
            try {
                this.events = [];
                let response = await axios.get(`groupTeachers/selectGroupTeacher/${this.idDepartament}/${this.user.idUsuario}/${this.startDate}/${this.endDate}`);
                let response2 = await axios.get(`lessons/selectLessons/${this.idDepartament}/${this.user.idUsuario}/${this.startDate}/${this.endDate}`);
                let response3 = await axios.get(`activities/selectActivitieTeachers/${this.idDepartament}/${this.user.idUsuario}/${this.startDate}/${this.endDate}`);
                if (response.status == 200 && response2.status == 200) {
                    if (response.data.length > 0) {
                        console.log(response.data);
                        response.data.map((value)=>{
                            value.fechaInicial = this.formatDateTime(value.fechaInicial);
                            value.fechaFinal = this.formatDateTime(value.fechaFinal);

                            this.events.push({
                                'idGroupTeacher': value.idGrupoDocente,
                                'idSchedule': value.idCalendario,
                                'type': value.tipo,
                                'originalName': value.nombreGrupo,
                                'initialDate': value.fechaInicio,
                                'name': 'Grupo - '+value.nombreGrupo,
                                'start': value.fechaInicial,
                                'end': value.fechaFinal
                            });
                        });
                    }
                    console.log(response2.data);
                    if (response2.data.length > 0) {
                        response2.data.map((value)=>{
                            value.fechaInicial = this.formatDateTime(value.fechaInicial);
                            value.fechaFinal = this.formatDateTime(value.fechaFinal);

                            this.events.push({
                                'idStudent': value.idEstudiante,
                                'firstName': value.nombres,
                                'lastName':value.apellidos,
                                'ci': value.ci,
                                'idLevel': value.idNivel,
                                'nameLevel': value.nombreNivel,
                                'numberStudents': value.nroAlumnos,
                                'initials': value.sigla,
                                'description': value.descripcion,
                                'idClass': value.idClase,
                                'idSchedule': value.idCalendario,
                                'idSubject': value.idMateria,
                                'subject': value.nombreMateria,
                                'idReservationStudent': value.idReserva,
                                'idTeacher': value.idDocente,
                                'virtual': value.virtual,
                                'type': value.tipo,
                                'originalName': value.codigoMateria,
                                'name': value.sigla+' '+value.nroAlumnos+' - '+value.codigoMateria+'\n'+value.nombres+'\n'+value.apellidos,
                                'start': value.fechaInicial,
                                'end': value.fechaFinal,
                                'phone': value.telefono,
                            });
                        });
                    }
                    if (response3.data.length > 0) {
                        response3.data.map((value) => {
                            value.fechaInicial = this.formatDateTime(value.fechaInicial);
                            value.fechaFinal = this.formatDateTime(value.fechaFinal);

                            this.events.push({
                                'idActivitieTeacher': value.idDocenteActividad,
                                'idSchedule': value.idCalendario,
                                'type': value.tipo,
                                'originalName': value.descripcion,
                                'name': value.descripcion,
                                'start': value.fechaInicial,
                                'end': value.fechaFinal
                            });
                        });
                    }
                    //this.$refs.calendar.updateTimes();
                }
            } catch (error) {
                console.log(error);
            }
        },
        async selectTeacher(){
            try {
                let response = await axios.get(`users/selectUserById/${this.user.idUsuario}`);
                if (response.status == 200) {
                    this.teacher = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async selectSubjectTeacher(){
            try {
                let response = await axios.get(`subjects/selectSubjectTeacher/${this.$route.params.idTeacher}/${this.idDepartament}`);
                if (response.status == 200) {
                    this.subjects = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async selectGroupByYear(){
            try {
                const year = new Date().getFullYear();
                let response = await axios.get(`groups/selectGroupByYear/${year}/${this.idDepartament}/${this.user.idUsuario}`);
                if (response.status == 200) {
                    this.groups = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        notification(type,text){
			this.snackbar = true;
			this.colorSnackbar = type;
			this.textSnackbar = text;
		},
        closeGroupSchedule(){
            this.dialogGroup = false;
            this.$refs.formGroup.resetValidation();
            this.formGroup.idGroup = '';
            this.formGroup.weeks = '';
            this.formGroup.dateEnd = '';
            this.formGroup.dateStart = '';
            this.formGroup.date = '';
            this.formGroup.timeStart = '';
            this.formGroup.timeEnd = '';
            const i = this.events.indexOf(this.eventItem);
            if (i !== -1) {
                this.events.splice(i, 1);
            }
        },
        closeDelete(){
            this.dialogDelete = false;
            this.selectedOpen = false;
            this.selectedEvent = {};
            this.selectedElement = null;
        },
        closeEventUpdate(){
            this.dialogEventUpdate = false;
            this.$refs.formClass.resetValidation();
            this.formClass.idLevel = '';
            this.formClass.idStudent = '';
            this.formClass.idSubjectTeacher = '';
            this.formClass.numberStudents = '';
            this.formClass.type = '';
            this.formClass.timeEnd = '';
            this.formClass.timeStart = '';
            this.formClass.date = '';
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        formatDateTime (datetime) {
            if (!datetime) return null

            const date = datetime.substring(0,10);
            const time = datetime.substring(11,19);
            return date+' '+time;
        },
        formatDateTimeReceipt(date){
			if (!date) return null;
			let dateSub = date.substring(0,10);
			let timeSub = date.substring(11,19);
			const [year,month,day] = dateSub.split('-');
			return `${day}-${month}-${year} ${timeSub}`
		},
        formatDateTimeClass(start,end){
			if (!start || !end) return null;
			let dateSub = start.substring(0,10);
			let timeStart = start.substring(11,16);
            let timeEnd = end.substring(11,16);
			const [year,month,day] = dateSub.split('-');
			return `${day}-${month}-${year} (${timeStart} - ${timeEnd})`;
		}
    }
}
</script>

<style scoped lang="scss">
    .v-event-draggable {
    padding-left: 6px;
    }

    .v-event-timed {
    user-select: none;
    -webkit-user-select: none;
    }

    .v-event-drag-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;
    height: 4px;
    cursor: ns-resize;

    &::after {
        display: none;
        position: absolute;
        left: 50%;
        height: 4px;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        width: 16px;
        margin-left: -8px;
        opacity: 0.8;
        content: '';
    }

    &:hover::after {
        display: block;
    }
    }
</style>
<template>
    <v-dialog
        v-model="dialogDownload"
        hide-overlay
        persistent
        width="300"
    >
        <v-card
            color="#1486bd"
            dark
        >
            <v-card-text>
                <p class="mb-1 pt-2 text-center">Generando Reporte</p>
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialog:{
            type: Boolean,
            default: () => false,
        }
    },
    data(){
        return{
            dialogDownload: false,
        }
    },
    watch:{
        dialog(newValue){
            this.dialogDownload = newValue;
        }
    }
}
</script>

<style>

</style>
<template>
    <v-container class="fill-height" fluid>
        <v-col cols="12" sm="4" md="8" lg="8" offset-sm="4" offset-md="2">
            <v-row align="center" justify="center">
                <v-card class="mx-2 mb-4" rounded="lg" dark color="#1CA5DB" width="220" v-for="item in departaments" :key="item.idDepartamento" @click="selectBranchOffice(item)">
                    <v-card-text>
                        <h4 class="text-center white--text">{{item.nombreDepartamento}}</h4>
                    </v-card-text>
                </v-card>
            </v-row>
            <div class="mt-10 mr-4 float-right">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            dark 
                            fab 
                            color="#1CA5DB"
                            v-bind="attrs"
                            v-on="on"
                            @click="dialogCreateDepartament = true"
                        >
                            <v-icon dark>
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Nuevo departamento</span>
                </v-tooltip>
            </div>
        </v-col>
        <v-dialog
            v-model="dialogCreateDepartament"
            persistent
            max-width="420px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h6">Nuevo departamento</span>
                </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="form">
                        <p class="mb-2">Nombre Departamento</p>
                        <v-text-field
                            v-model="nameDepartament"
                            class="pt-0"
                            label="Nombre"
                            placeholder="Nombre"
                            single-line
                            dense
                            outlined
                            :rules="nameRules"
                        >
                        </v-text-field>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                :disabled="loading"
                @click="closeDialog"
            >
                <span class="text-capitalize">Cancelar</span> 
            </v-btn>
            <v-btn
                color="blue darken-1"
                dark
                :loading="loading"
                @click="saveDepartament"
            >
                <span class="text-capitalize">Guardar</span> 
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackbar"
            :color="colorSnackbar"
        >
            {{ textSnackbar }}
        </v-snackbar>
    </v-container>
</template>

<script>
import axios from 'axios';
import { mapMutations } from 'vuex';
export default {
    data(){
        return {
            departaments: [],
            dialogCreateDepartament: false,
            nameDepartament: '',
            nameRules: [
                value => !!value || 'Ingrese un Departamento',
            ],
            loading: false,
            snackbar: false,
            colorSnackbar: '',
            textSnackbar: '',
        }
    },
    computed:{

    },
    created(){
        this.setIdDepartament(null);
        this.setOptionsGroup([]);
        this.setOptionsSchedule([]);
        this.setOptionsAdmin([]);
        this.setOptionsReport([]);
        this.setOptionsIngress([]);
        this.setAppIcon(false);
        this.setDrawer(false);
        this.selectDepartaments();
    },
    methods:{
        ...mapMutations(['setDrawer','setAppIcon','setIdDepartament','setOptionsGroup','setOptionsSchedule','setOptionsAdmin','setOptionsReport','setOptionsIngress']),
        async insertDepartament(data) {
            try {
                let response = await axios.post('departaments/insertDepartament',data);
                if (response.status == 200) {
                    console.log(response.data);
                    this.loading = false;
                    this.snackbar = true;
                    this.colorSnackbar = 'success';
                    this.textSnackbar = response.data['msg'];
                    this.selectDepartaments();
                    this.closeDialog();
                }
            } catch (error) {
                console.log(error);
                this.loading = false
            }
        },
        saveDepartament(){
            if (this.$refs.form.validate()) {
                this.loading = true;
                let data = {
                    nameDepartament: this.nameDepartament.toUpperCase().trim(),
                }
                this.insertDepartament(data);
            }
        },
        async selectDepartaments(){
            this.departaments = [];
            try {
                let response = await axios.get('departaments/selectDepartaments/1');
                if(response.status == 200){
                    this.departaments = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        selectBranchOffice(item){
            this.setIdDepartament(item.idDepartamento);
            this.setAppIcon(true);
            this.$router.push('/semester');
        },
        closeDialog(){
            this.dialogCreateDepartament = false;
            this.$refs.form.reset();

        }
    }
}
</script>

<style>
</style>
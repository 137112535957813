<template>
	<div id="app">
		<template v-if="logged">
			<v-app
				id="app"
				width="100vw"
				height="100vh"
				dense
				clipped-left
				color="indigo darken-2"
				v-scroll="onScroll"
			>
				<AppBar />
				<NavigationDrawer />
				<div class="grey lighten-4 fluid fill-height">
					<v-main class="fill-height">
						<v-slide-y-transition mode="out-in">
							<router-view :key="$route.path" />
						</v-slide-y-transition>
					</v-main>
				</div>
				<v-fab-transition>
					<v-btn
						color="#5895C4"
						dark
						fab
						fixed
						title="Regresar al Principio"
						small
						bottom
						right
						v-show="showFab"
						@click="$vuetify.goTo('#app', { duration: 500, offset: 0 })"
					>
						<v-icon>mdi-chevron-up</v-icon>
					</v-btn>
				</v-fab-transition>
			</v-app>
		</template>
		<template v-else>
			<v-app>
				<transition>
					<keep-alive>
						<router-view/>
					</keep-alive>
				</transition>
			</v-app>
		</template>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AppBar from './views/layouts/app-bar-view.vue';
import NavigationDrawer from './views/layouts/navigation-view.vue';

export default {
	name: 'App',
	components: {
		AppBar,
		NavigationDrawer,
	},	
	data(){
		return{
			offsetTop: 0,
		}
	},
	computed: {
		...mapState(['user','token']),
		showFab(){
			return this.offsetTop > 180;
		},
		logged(){
			return this.user;
		}
	},
	created(){
		this.autoLogin();
	},
	methods: {
		...mapActions(['autoLogin']),
		onScroll(){
			this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
		}
	}
	/*comando de conexion ssh bitnami@44.205.77.71*/ 
};
</script>


<template>
    <v-dialog
      v-model="dialog"
      max-width="500"
    >
        <v-card>
            <div class="d-flex flex-row">
                <v-card-title class="text-h6 text-center">
                    Cambiar Credenciales de Acceso
                    <span class="text-caption mb-0 text--disabled font-weight-medium text-left">La contraseña debe tener al menos ocho caracteres e incluir una combinación de números, letras mayusculas, minusculas y caracteres especiales (!$@%.)</span>
                </v-card-title>
            </div>
            
            <v-card-text class="pb-0">
                <v-container>
                    <v-form ref="form">
                        <v-row>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="py-0"
                            >
                                <p class="mb-2">Nuevo Usuario</p>
                                <v-text-field
                                    v-model="formUser.user"
                                    class="pt-0"
                                    label="Usuario"
                                    placeholder="Usuario"
                                    single-line
                                    dense
                                    outlined
                                    autocomplete="null"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="py-0"
                            >
                                <p class="mb-2">Nueva Contraseña</p>
                                <v-text-field
                                    v-model="formUser.newPassword"
                                    class="pt-0"
                                    :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="passwordShow ? 'text' : 'password'"
                                    label="Password"
                                    placeholder="Password"
                                    @click:append="passwordShow = !passwordShow"
                                    single-line
                                    dense
                                    outlined
                                    autocomplete="null"
                                    :rules="passwordRules"
                                >
                                </v-text-field>

                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="py-0"
                            >
                                <p class="mb-2">Confirmar Contraseña</p>
                                <v-text-field
                                    v-model="formUser.repeatPassword"
                                    class="pt-0"
                                    :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="passwordShow ? 'text' : 'password'"
                                    label="Password"
                                    placeholder="Password"
                                    @click:append="passwordShow = !passwordShow"
                                    single-line
                                    dense
                                    outlined
                                    autocomplete="null"
                                    :rules="passwordRepeat"
                                >
                                </v-text-field>

                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="py-0"
                            >
                                <p class="mb-2">Email</p>
                                <v-text-field
                                    v-model="formUser.email"
                                    class="pt-0"
                                    label="Email"
                                    placeholder="Email"
                                    single-line
                                    dense
                                    outlined
                                    autocomplete="null"
                                    :rules="emailRules"
                                >
                                </v-text-field>

                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions class="pb-6 px-9">
                <v-btn
                    color="#1486bd"
                    dark
                    block
                    @click="saveCredentials"
                    :loading="loading"
                >
                    <p class="mb-0 text-capitalize">Guardar Cambios</p>
                </v-btn>
                </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios';
export default {
    props:{
        user:{
            type: Object,
            default: () => {}
        }
    },
    data(){
        return{
            dialog: false,
            formUser: {
                idUser: '',
                user: '',
                newPassword: '',
                repeatPassword: '',
                email: '',
            },
            passwordRules: [
                value => !!value || 'Ingrese una contraseña',
                value => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*.]).{8,}$/.test(value) || 'Debe Ingresar una contraseña valida'
            ],
            passwordRepeat: [
                value => !!value || 'Debe confirmar la contraseña',
                value => value == this.formUser.newPassword || 'Las contraseñas deben ser las mismas'
            ],
            emailRules: [
                value => !!value || 'Ingrese un email',
                value => /.+@.+\..+/.test(value) || 'Debe ingresar un email correcto',
            ],
            passwordShow: false,
            loading: false,
        }
    },
    computed:{

    },
    created(){

    },
    watch:{
        dialog(val){
            val || this.close();
        },
        user(newUser){
            this.formUser.email = newUser.email;
            this.formUser.user = newUser.usuario;
            this.formUser.idUser = newUser.idUsuario;
        }
    },
    methods:{
        async updateCredentialsUser(data){
            try {
                let response = await axios.put('users/updateCredentialsUser',data);
				if (response.status == 200) {
					this.close();
					this.$emit('notification',{type: 'success', text: response.data['msg']});
                    this.loading = false;
				}
            } catch (error) {
                console.log(error);
                this.loading = false;
                this.$emit("notification",{type: 'error', text: 'Ocurrio algo inesperado. Por favor intente de nuevo.'});
            }
        },
        saveCredentials(){
            if (this.$refs.form.validate()) {
                this.loading = true;
                let data = {
                    user: this.formUser.user,
                    password: this.formUser.repeatPassword,
                    email: this.formUser.email,
                    idUser: this.formUser.idUser
                }

                this.updateCredentialsUser(data);
            } 
        },
        openDialog(){
            this.dialog = true;

        },
        close(){
            this.dialog = false;
            this.formUser.email = '';
            this.formUser.newPassword = '';
            this.formUser.repeatPassword = '';
            this.formUser.user = '';
            this.formUser.idUser = '';
            this.$refs.form.resetValidation();
            this.passwordShow = false;
            this.$emit("deleteUserSelected");
        }
    }
}
</script>

<style>

</style>
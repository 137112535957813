<template>
    <v-layout>
        <v-container>
            <v-breadcrumbs class="pl-0" :items="items">
				<template v-slot:item="{ item }">
					<v-breadcrumbs-item
						:to="item.href"
						:disabled="item.disabled"
					>
						{{ item.text}}
					</v-breadcrumbs-item>
				</template>
			</v-breadcrumbs>
            <v-card outlined>
                <v-card-text>
                    <p class="text-h6 mb-0">Credenciales</p>
                    <v-container>
                        <v-form ref="formGradeSearch">
                        <v-row class="d-flex flex-row">
                            <v-col
                                cols="12"
                                sm="3"
                                md="3"
                                class="py-0"
                            >
                                <p class="mb-2">Semestre</p>
                                <v-autocomplete
                                    v-model="formGroup.idSemester"
                                    :items="semesters"
                                    :filter="semesterFilter"
                                    outlined
                                    dense
                                    item-text="nombreSemestre"
                                    item-value="idSemestre"
                                    label="Seleccione"
                                    single-line
                                    @change="findGroup"
                                    :rules="semesterRules"
                                    no-data-text="No hay semestres disponibles"
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="3"
                                md="3"
                                class="py-0"
                            >
                                <p class="mb-2">Grupo</p>
                                <v-autocomplete
                                    v-model="formGroup.idGroup"
                                    :items="groups"
                                    :filter="groupFilter"
                                    outlined
                                    dense
                                    item-text="nombreGrupo"
                                    item-value="idGrupo"
                                    label="Seleccione"
                                    single-line
                                    :rules="groupRules"
                                    @change="changeGroup"
                                    no-data-text="No hay grupos disponibles"
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="3"
                                md="3"
                                class="py-0"
                                align-self="center"
                            >
                            <v-btn
                                color="#1486bd"
                                dark
                                elevation="0"
								:loading="loading"
                                @click="findStudents"
                            >
                                <span class="text-capitalize text-body">Buscar Grupo</span> 
                            </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
			<v-container v-if="loading" class="d-flex justify-center">
				<v-progress-circular 
					indeterminate
					color="primary"
				></v-progress-circular>
			</v-container>
			<template v-if="students.length > 0">
				<v-data-table
                    :search="search"
                    :headers="headers"
                    :items="students"
                    :page="pageTable"
                    item-key="idGrupoUsuario"
                    class="elevation-1 font-weight-light caption"
                    @update:page="pageUpdate"
                    fixed-header
                >
                    <template v-slot:[`item.accion`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{on,attrs}">
                                <v-btn
                                    v-if="user.idRol == 1"
                                    icon
                                    color="warning"
                                    class="mr-2"
                                    @click.stop="createCredential(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-badge-account</v-icon>
                                </v-btn>
                            </template>
                            <span>Credencial</span>
                        </v-tooltip>
                    
                    </template>
                </v-data-table>
			</template>
			<v-snackbar
				v-model="snackbar"
				:color="colorSnackbar"
				text
			>
				<span class="text-center">{{ textSnackbar }}</span>
			</v-snackbar>
			<v-dialog v-model="dialogCredential" max-width="700px">
                <v-card>
                    <v-card-text class="text-center pb-0">
                        <v-row class="mt-0">
                            <v-col cols="12" sm="8" md="8">
                                <p style="color: #1874BF" class="text-h6 text-center pt-2 mb-0">Datos Estudiante</p>
                                <v-row class="mt-2">
                                    <v-col cols="6">
                                        <v-img
                                            v-show="qrUser.avatar != null"
                                            :src="qrUser.avatar"
                                            width="160"
                                            contain
                                        >
                                            
                                        </v-img>
                                        <v-row justify="end">
                                            <v-tooltip left>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        v-show="qrUser.avatar != null"
                                                        class="ma-2 "
                                                        text
                                                        icon
                                                        color="red"
                                                        v-on="on"
                                                        v-bind="attrs"
                                                        :loading="loadingData"
                                                        @click.stop="rejectedAvatar"
                                                    >
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Rechazar Fotografia</span>
                                            </v-tooltip>
                                        </v-row>
                                        
                                        <v-icon v-show="qrUser.avatar == null" class="mt-3" size="50">mdi-image-off</v-icon>
                                        <p class="mt-3" v-show="qrUser.avatar == null">El estudiante no subio la fotografia</p>
                                    </v-col>
                                    <v-col cols="6" align-self="center">
                                        <p class="mb-0 font-weight-bold text-left">Nombres: <span class="font-weight-regular">{{ qrUser.nombres }}</span></p>
                                        <p class="mb-0 font-weight-bold text-left">Apellidos: <span class="font-weight-regular">{{ qrUser.apellidos }}</span></p>
                                        <p class="mb-0 font-weight-bold text-left">C.I.: <span class="font-weight-regular">{{ qrUser.ci }}</span></p>
                                        <p class="mb-0 font-weight-bold text-left">Telefono: <span class="font-weight-regular">{{ qrUser.telefono }}</span></p>
                                        <p class="mb-0 font-weight-bold text-left">Grupo: <span class="font-weight-regular">{{ group.nombreGrupo }}</span></p>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <p style="color: #1874BF" class="text-h6 text-center pt-2 mb-0">QR Generado</p>
                                <v-container v-if="loadingQr" class="d-flex justify-center align-center" style="height: 180px;">
                                    <v-progress-circular 
                                        indeterminate
                                        color="primary"
                                    ></v-progress-circular>
                                </v-container>
                                <vue-qr v-show="qrCode != '123'"
                                    :text="qrCode"
                                    :size="200"
                                    :dotScale="1"
                                ></vue-qr>
                                <p class="red--text" v-if="qrCode == '5fKEh8C/jDq0NH4A2sMK1kfV4yUQ8XznCZ3VBQo/KQr8fXfzBYlF/Huv2vSNcM5OeIf30AuT+bFrgeQwxJ8adw=='">QR invalido, vuelva generar</p>
                                <credentialGenerate ref="credentialUp" :user="qrUser" :group="group"/>
                                <v-row justify="center">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="ma-2 "
                                                color="primary"
                                                text
                                                icon
                                                v-on="on"
                                                v-bind="attrs"
                                                :loading="loadingData"
                                                @click.stop="generateQR"
                                            >
                                                <v-icon>mdi-refresh</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Volver a generar QR</span>
                                    </v-tooltip>
                                </v-row>
                            </v-col>
                        </v-row>     
                    </v-card-text>
                    <v-card-actions class="pb-4">
                        <v-spacer></v-spacer>
                        <v-btn class="white--text" :disabled="qrUser.avatar == null ? true : false" elevation="0" color="blue darken-1" @click="generateCredential">Descargar Credencial</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-layout>
</template>

<script>
import axios from 'axios';
import { mapMutations, mapState } from 'vuex';
import credentialGenerate from '@/components/credential/credential-generate-user-component.vue';
import vueQr from 'vue-qr';

export default {
    components: {
        credentialGenerate,
        vueQr,
    },
    data(){
		return {
			dialog: false,
			dialogDelete: false,
            search: '',
            group: {},
			headers: [
				{text: "Ci", value: "ci", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Apellidos", value: "apellidos", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Nombres", value: "nombres", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Telefono", value: "telefono", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Acciones", value: "accion", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			],
			students: [],
            semesters: [],
            groups: [],
            subjects: [],
			editedIndex: -1,
            formGroup:{
                idGroup: '',
                idSemester: '',
                idSubject: '',
            },
            semesterRules: [
                value => !!value || 'Seleccione un semestre'
            ],
            groupRules: [
                value => !!value || 'Seleccione un grupo'
            ],
            subjectRules: [
                value => !!value || 'Seleccione una materia',
            ],
			loading: false,
			loadingData: false,
			snackbar: false,
			colorSnackbar: '',
			textSnackbar: '',
			editedItem: {},
			items: [
				{
					text: 'Credenciales',
					disabled: true,
					href: '/grade',
				},
			],
            turns: [],
			dialogGrade: false,
			formGrade: {
				idGroup: '',
				idStudent: '',
				idTeacher: '',
				idSubject: '',
				dateIngress: '',
				partial: '',
				grade: '',
			},
			dialogCredential: false,
			qrCode: '123',
			qrUser: {},
            selectedUser: {},
            loadingQr: false,
		}
	},
	computed: {
		...mapState(['user','idDepartament','pageTable']),
		formTitle () {
			return this.editedIndex === -1 ? 'Registrar Calificacion' : 'Editar Calificacion';
		},
	},
	watch: {
		dialogGrade (val) {
			val || this.closeGrade()
		},
        dialogCredential (val) {
            val || this.closeCredential()
        },
	},
	created () {
        this.setPageTable(1);
        this.selectSemesterTeacher();
	},
	methods: {
        ...mapMutations(['setPageTable']),
        pageUpdate(index){
            this.setPageTable(index);
        },
        closeCredential(){
            this.dialogCredential = false;
            this.qrCode = '123';
            this.qrUser = {qrCode: '123'};
            this.selectedUser = {};
        },
        generateCredential(){
			this.$refs.credentialUp.generatePdf();
            //this.$refs.credentialDown.generatePdf();
		},
        changeGroup(item){
			this.students = [];
            this.setPageTable(1);

			const obj = this.groups.find(group => group.idGrupo === item);
            this.group.cuotas = obj.cuotas;
            this.group.nombreGrupo = obj.nombreGrupo;
            this.group.fechaFin = obj.fechaFin;
            this.group.nombreTurno = obj.nombreTurno;
        },
        async updateQrCode(user){
			try {
				let response = await axios.put('courses/updateQrCode',user);
				if (response.status == 200) {
					this.notification('success',response.data.msg);
                    this.loadingQr = false;
                    //console.log(response.data);
                    this.findStudents();
					return response.data.data;
				}
			} catch (error) {
				console.log(error);
                console.log(error.response);
                if(error.response != undefined){
                    if (error.response.status == 400) {
                        this.notification('warning',error.response.data.error);
                    }
                }else{
                    this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
                }

                this.loadingQr = false;
                return null;
				
			}
		},
        async generateQR(){
            this.loadingQr = true;
            this.qrCode = '123';
            const data = {
                idUser: this.selectedUser.idUsuario,
                idGroup: this.selectedUser.idGrupo,
                ci: this.selectedUser.ci
            }
            const user = await this.updateQrCode(data);
            if (user != null) {
                this.qrCode = user.qrCode;
                this.qrUser = user;
            }
            
        },
        async createCredential(item){
            this.selectedUser = item;
			if (item.qrCode != null) {
				this.dialogCredential = true;
				this.qrCode = item.qrCode;
				this.qrUser = item;
			}else {
				const data = {
					idUser: item.idUsuario,
					idGroup: item.idGrupo,
                    ci: item.ci
				}
                this.dialogCredential = true;
                this.loadingQr = true;
                this.qrUser = this.selectedUser;
				const user = await this.updateQrCode(data);
                if (user != null) {
                    this.qrCode = user.qrCode;
                    this.qrUser = user;
                }		
				
			}
		},
        async selectStudentsByIdGroup(idGroup){
			this.students = [];
            try {
                let response = await axios.get(`courses/selectStudentsByIdGroup/${idGroup}`,{params:{idTeacher: this.user.idUsuario,idSubject: this.formGroup.idSubject }});
                if (response.status == 200) {
                    response.data.map((value,index) => {
                        this.students.push({
                            nro: index+1,
                            idUsuario: value.idUsuario,
                            estudiante: value.estudiante,
                            ci: value.ci,
                            telefono: value.ci,
                            email: value.email,
							s1: value.s1,
							s2: value.s2,
							s3: value.s3,
							s4: value.s4,
							s5: value.s5,
							s6: value.s6,
							nfin: ((Number(value.s1)+Number(value.s2)+Number(value.s3)+Number(value.s4)+Number(value.s5)+Number(value.s6))/this.drills).toFixed(1),
                        });
                    });
                    this.loading = false;
                }
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },
        async selectCourseStudents(idGroup){
            let url = `courses/selectCourseStudentsByIdGroup/${idGroup}/1`;
            this.loading = true;
            try {
                let response = await axios.get(url);
                if (response.status == 200) {
                    this.students = response.data;
                    this.loading = false;
                }
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },
        findStudents(){
            if (this.$refs.formGradeSearch.validate()) {
                this.loading = true;
                this.students = [];
                this.selectCourseStudents(this.formGroup.idGroup);
            }
        },
        groupFilter (item, queryText) {
			const textOne = item.nombreGrupo.toLowerCase();
			const searchText = queryText.toLowerCase();

			return textOne.indexOf(searchText) > -1;
		},
        async selectGroupBySemester(idSemester){
            try {
                let response = await axios.get(`groups/selectGroupCredentialByIdSemester/${this.idDepartament}/${idSemester}`);
                if (response.status == 200) {
                    this.groups = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        findGroup(item){
            this.groups = [];
            this.subjects = [];
            this.formGroup.idGroup = '';
            this.formGroup.idSubject = '';
			this.students = [];
			this.drills = 0;
			this.partial = [];
            this.setPageTable(1);
            
            this.selectGroupBySemester(item);
        },
        semesterFilter (item, queryText) {
			const textOne = item.nombreSemestre.toLowerCase();
			const searchText = queryText.toLowerCase();

			return textOne.indexOf(searchText) > -1;
		},
        async selectSemesterTeacher(){
            try {
                let response = await axios.get(`semesters/selectSemesterTeacher/${this.idDepartament}`);
                if (response.status == 200) {
                    this.semesters = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },

		notification(type,text){
			this.snackbar = true;
			this.colorSnackbar = type;
			this.textSnackbar = text;
		},

		deleteItem (item) {
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm () {
			let data = {
				idGroup: this.editedItem.idGrupo,
				state: this.editedItem.estado ? 0 : 1,
			}
			this.deleteGroup(data);
		},

		closeGrade () {
			this.dialogGrade = false;
			this.formGrade.dateIngress = '';
			this.formGrade.grade = '';
			this.formGrade.idGroup = '';
			this.formGrade.idStudent = '';
			this.formGrade.idSubject = '';
			this.formGrade.idTeacher = '';
			this.formGrade.partial = '';
			this.definePartial();
			this.editedIndex = -1;
			this.$refs.formGrade.resetValidation();
		},

		closeDelete () {
			this.dialogDelete = false;
			this.editItem = '';
		},
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
    }
}
</script>

<style>

</style>
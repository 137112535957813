<template>
    <v-layout>
        <v-container>
            <v-breadcrumbs class="pl-0" :items="items">
				<template v-slot:item="{ item }">
					<v-breadcrumbs-item
						:to="item.href"
						:disabled="item.disabled"
					>
						{{ item.text}}
					</v-breadcrumbs-item>
				</template>
			</v-breadcrumbs>
			<v-toolbar
				flat
			>
				<v-toolbar-title>Reporte Pago de Horas Individuales</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip top>
                    <template v-slot:activator="{on,attrs}">
                        <v-btn 
                            v-show="searchIndividualReport.length > 0 || searchPracticeReport.length > 0 || searchAfpReport.length > 0"
                            class="ml-3 mr-2"
                            color="primary"
                            fab
                            depressed
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="print"
                        >
                            <v-icon>mdi-printer</v-icon>
                        </v-btn>
                    </template>
                    <span>Imprimir Reporte</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{on,attrs}">
                        <v-btn 
                            v-show="searchIndividualReport.length > 0 || searchPracticeReport.length > 0 || searchAfpReport.length > 0"
                            class="ml-3 mr-2"
                            color="#1D6F42"
                            fab
                            depressed
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="downloadXlsx"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>Descargar Excel</span>
                </v-tooltip>
            </v-toolbar>
            <v-card outlined tile>
                <v-card-text class="mt-1">
                    <p class="font-weight-bold text-body-1" style="color: #1486bd;">Buscar Reporte</p>
                    <v-form ref="formSearchReport">
                        <v-row>
                            <v-col v-if="user.idRol == 1" cols="12" sm="4" md="4" class="py-0">
                                <p class="mb-2">Profesor</p>
                                <v-autocomplete
                                    v-model="formSearchReport.idTeacher"
                                    :items="teachers"
                                    :filter="userFilter"
                                    item-text="nombres"
                                    item-value="idUsuario"
                                    label="Seleccione un profesor"
                                    persistent-hint
                                    outlined
                                    single-line
                                    dense
                                    :rules="userRules"
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="4"
                                md="4"
                                class="py-0"
                            >
                                <p class="mb-2">Semana</p>
                                <v-menu
                                    ref="menuDay"
                                    v-model="menuDay"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="formSearchReport.date"
                                        v-show="formSearchReport.startDate == ''"
                                        placeholder="Semana"
                                        readonly
                                        single-line
                                        dense
                                        outlined
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="dateRules"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="formSearchReport.date"
                                        no-title
                                        first-day-of-week="1"
                                        :active-picker.sync="activePicker"
                                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                                        min="2010-01-01"
                                        @change="saveDay"
                                    ></v-date-picker>
                                </v-menu>
                                <v-text-field
                                v-model="formSearchReport.rangeDate"
                                v-show="formSearchReport.startDate != ''"
                                @click:clear="clearDate"
                                placeholder="Semana"
                                clearable
                                readonly
                                single-line
                                dense
                                outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>

                    </v-form>
                </v-card-text>
                <v-card-actions class="pt-0">
                    <v-btn
                        v-show="formSearchReport.idTeacher > 0"
                        class="ml-2"
                        dark
                        color="#18a6df" 
                        elevation="0"
                        @click="openDialog"
                    >
                        <span class="text-capitalize text-body">Descuento (AFP)</span> 
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="warning"
                        elevation="0"
                        @click="cleanFormSearchReport"
                    >
                        <span class="text-capitalize text-body">Limpiar</span>
                    </v-btn>
                    <v-btn
                        class="ml-2"
                        dark
                        color="#1486bd" 
                        elevation="0"
                        @click="searchReportByParams"
                    >
                        <span class="text-capitalize text-body">Buscar</span> 
                    </v-btn>
                </v-card-actions>
            </v-card>
            <template v-if="loadingSearchIndividualReport">
                <v-container class="d-flex justify-center">
                    <v-progress-circular 
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </v-container>
            </template>
            <div v-show="searchIndividualReport.length > 0 || searchPracticeReport.length > 0 || searchAfpReport.length > 0">
                <v-card class="mt-4" elevation="0" outlined>
                    <v-card-text>
                        <p class="font-weight-bold">Resumen de Reporte del {{ formSearchReport.rangeDate }}</p>
                        <p class="font-weight-bold mb-0">Total Individuales = {{ totalIndividualIngress.toFixed(2) }} Bs.</p>
                        <p class="font-weight-bold mb-0">Total Practicas = {{ totalPracticeIngress.toFixed(2) }} Bs.</p>
                        <p class="font-weight-bold mb-0">Total Descuento (AFP) = {{ totalAfp.toFixed(2) }} Bs.</p>
                        <p class="font-weight-bold mb-0 black--text mt-2">Total General = {{ ((totalIndividualIngress+totalPracticeIngress) - totalAfp).toFixed(2) }} Bs.</p>
                    </v-card-text>
                </v-card>
            </div>
            <div v-show="searchIndividualReport.length > 0">
                <p class="font-weight-bold text-h6 mt-2 mb-2">Individuales</p>
                <v-data-table
                    :headers="headersIndividualIngress"
                    :items="searchIndividualReport"
                    :items-per-page="-1"
                    class="elevation-1 font-weight-light caption"
                    hide-default-footer
                    fixed-header
                >
                </v-data-table>
                <p class="my-2 font-weight-bold text-right">Total Bs. {{ totalIndividualIngress.toFixed(2) }}</p>
            </div>
            <div v-show="searchPracticeReport.length > 0">
                <p class="font-weight-bold text-h6 mt-0 mb-2">Practicas</p>
                <v-data-table
                    :headers="headersPractice"
                    :items="searchPracticeReport"
                    :items-per-page="-1"
                    class="elevation-1 font-weight-light caption"
                    hide-default-footer
                    fixed-header
                >
                </v-data-table>
                <p class="my-2 font-weight-bold text-right">Total Bs. {{ totalPracticeIngress.toFixed(2) }}</p>
            </div>
            <div v-show="searchAfpReport.length > 0">
                <p class="font-weight-bold text-h6 mt-0 mb-2">Descuento AFP</p>
                <v-data-table
                    :headers="headersAfp"
                    :items="searchAfpReport"
                    :items-per-page="-1"
                    class="elevation-1 font-weight-light caption"
                    hide-default-footer
                    fixed-header
                >
                </v-data-table>
                <p class="my-2 font-weight-bold text-right">Total Bs. {{ totalAfp.toFixed(2) }}</p>
            </div>
            <v-snackbar
                v-model="snackbar"
                :color="colorSnackbar"
                text
            >
                <span class="text-center">{{ textSnackbar }}</span>
            </v-snackbar>
            <v-dialog
            v-model="dialog"
            max-width="550px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ 'Descuento AFP' }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-form ref="formAfp">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="4"
                                        md="4"
                                        class="py-0"
                                    >
                                        <p class="mb-2">Descuento</p>
                                        <v-text-field
                                            v-model="formAfp.discount"
                                            class="pt-0"
                                            placeholder="Descuento"
                                            single-line
                                            dense
                                            outlined
                                            :rules="discountRules"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="8"
                                        md="8"
                                        class="py-0"
                                    >
                                        <p class="mb-2">Detalle</p>
                                        <v-text-field
                                            v-model="formAfp.detail"
                                            class="pt-0"
                                            placeholder="Detalle"
                                            single-line
                                            dense
                                            outlined
                                            :rules="detailRules"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        :disabled="loadingData"
                        @click="close"
                    >
                        <span class="text-capitalize">Cancelar</span>
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        dark
                        :loading="loadingData"
                        @click="saveAfp"
                    >
                        <span class="text-capitalize">Guardar Afp</span>						
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
            <ReportIndividualPayment ref="reportIndividualPayment" :individualIngress="searchIndividualReport" :practiceIngress="searchPracticeReport" :afpDiscount="searchAfpReport" :resume="totalResume" :user="user" :departament="nameDepartament"/>

        </v-container>
    </v-layout>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import ReportIndividualPayment from '@/components/report/report-individual-payment-component.vue';
import * as XLSX from 'xlsx';
export default {
    components: {
        ReportIndividualPayment,
    },  
    data(){
        return{
            //Individual Report
            headersIndividualIngress: [
				{text: "Nro", value: "nro", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Fecha pago", value: "fechaPago", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Pago", value: "pago", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Tipo", value: "tipo", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Fecha Clase", value: "fechaClase", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Inicio", value: "inicio", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Fin", value: "fin", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Horas", value: "horas", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Nombre", value: "nombre", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Materia", value: "materia", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Nivel", value: "nivel", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Nro Est.", value: "nroAlumnos", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Pagos Bs.", value: "total", align: 'right', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			],
            formSearchReport: {
                date:  null,
                startDate: '',
                endDate: '',
                rangeDate: '',
                idTeacher: '',
            },
            userRules: [
                value => !!value || 'Seleccione un Profesor'
            ],
            dateRules: [
                value => !!value || 'Seleccione una Fecha'
            ],
            activePicker: null,
            menuStart: null,
            menuEnd: null,
            menuDay: null,
            totalIndividualIngress: 0,
            teachers: [],
            loadingSearchIndividualReport: false,
            searchIndividualReport: [],
            snackbar: false,
            colorSnackbar: '',
            textSnackbar: '',
            items: [
				{
					text: 'Reporte Pago de Horas Individuales',
					disabled: false,
					href: '/report-individual-payment',
				},
			],
            //Group Report
            loadingSearchPracticeReport: false,
            searchPracticeReport: [],
            totalPracticeIngress: 0,
            //Ingress Report
            headersPractice: [
				{text: "Nro", value: "nro", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Fecha", value: "fechaPago", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Nombre", value: "nombre", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Detalle", value: "detalleIngreso", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Nivel", value: "sigla", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Pagos Bs.", value: "pago", align: 'right', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			],
            totalIngress: 0,
            //Egress Report
            headersAfp: [
				{text: "Nro", value: "nro", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Fecha Descuento", value: "fechaDescuento", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Detalle", value: "detalle", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Descuento", value: "descuento", align: 'right', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			],
            searchAfpReport: [],
            totalAfp: 0,
            totalResume: {},
            nameDepartament: '',
            //Afp
            dialog: false,
            formAfp: {
                discount: '',
                detail: '',
                idTeacher: '',
                idUser: '',
                idDepartament: '',
            },
            loadingData: false,
            discountRules: [
                value => !!value || 'Ingrese un descuento',
                value => /^\d+(\.\d{1,2})?$/.test(value) || 'Ingrese un descuento valido'
            ],
            detailRules: [
                value => !!value || 'Ingrese un detalle'
            ],
        }
    },
    computed: {
        ...mapState(['idDepartament','user']),
    },
    watch:{
        menuStart (val) {
			val && setTimeout(() => (this.activePicker = 'YEAR'))
		},
		menuEnd (val) {
			val && setTimeout(() => (this.activePicker = 'YEAR'))
		},
        dialog (val) {
            val || this.close();
        }
    },
    created(){
        this.user.idRol == 1 ? this.selectDepartamentById(): this.nameDepartament = this.user.departamento;
        if (this.user.idRol == 1) {
            this.selectSearchUserByIdRole();
        }
    },
    methods: {
        //export excel
        downloadXlsx(){
            let wb = XLSX.utils.book_new();
			if (this.searchIndividualReport.length > 0) {
                const data = XLSX.utils.json_to_sheet(this.searchIndividualReport,{
						header: [
                        'nro',
                        'fechaPago',
                        'pago',
                        'tipo',
                        'fechaClase',
                        'inicio',
                        'fin',
                        'horas',
                        'nombre',
                        'materia',
                        'nivel',
                        'nroAlumnos',
                        'total',  
                        ]
					});
                data['A1'].v = 'Nro';
                data['B1'].v = 'Fecha pago';
                data['C1'].v = 'Pago';
                data['D1'].v = 'Tipo';
                data['E1'].v = 'Fecha Clase';
                data['F1'].v = 'Inicio';
                data['G1'].v = 'Fin';
                data['H1'].v = 'Horas';
                data['I1'].v = 'Nombre';
                data['J1'].v = 'Materia';
                data['K1'].v = 'Nivel';
                data['L1'].v = 'Nro Est.';
                data['M1'].v = 'Pagos Bs.';
                XLSX.utils.book_append_sheet(wb, data, 'Ingresos Individuales');	
            }

            if  (this.searchPracticeReport.length > 0) {
                const data = XLSX.utils.json_to_sheet(this.searchPracticeReport,{
						header: [
                        'nro',
                        'fechaPago',
                        'nombre',
                        'detalleIngreso',
                        'sigla',
                        'pago',
                        ]
					});
                data['A1'].v = 'Nro';
                data['B1'].v = 'Fecha';
                data['C1'].v = 'Nombre';
                data['D1'].v = 'Detalle';
                data['E1'].v = 'Nivel';
                data['F1'].v = 'Pagos Bs.';
                XLSX.utils.book_append_sheet(wb, data, 'Ingresos por Practica');
            }

            if  (this.searchAfpReport.length > 0)   {
                const data = XLSX.utils.json_to_sheet(this.searchAfpReport,{
						header: [
                        'nro',
                        'fechaDescuento',
                        'detalle',
                        'descuento',
                        ]
					});
                data['A1'].v = 'Nro';
                data['B1'].v = 'Fecha Descuento';
                data['C1'].v = 'Detalle';
                data['D1'].v = 'Descuento';
                XLSX.utils.book_append_sheet(wb, data, 'Descuentos AFP');
            }
            
			XLSX.writeFile(wb,'ReportePagoHoraIndividual.xlsx');	
		},
        //module afp
        async insertAfp(data){
            try {
				let response = await axios.post('afps/insertAfp',data);
				if (response.status == 200) {
					this.close();
					this.loadingData = false;
					this.notification('success',response.data['msg']);
				}
			} catch (error) {
				console.log(error);
				this.loadingData = false;
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
        },
        saveAfp(){
            if (this.$refs.formAfp.validate()) {
                let data = {
                    discount: this.formAfp.discount,
                    detail: this.formAfp.detail.trim().toUpperCase(),
                    idTeacher: this.formAfp.idTeacher,
                    idUser: this.formAfp.idUser,
                    idDepartament: this.formAfp.idDepartament
                }
                this.loadingData = true;

                this.insertAfp(data);
            }
        },
        openDialog(){
            this.dialog = true; 
            this.formAfp.idTeacher = this.formSearchReport.idTeacher;
            this.formAfp.idDepartament = this.idDepartament;
            this.formAfp.idUser = this.user.idUsuario;
        },
        close(){
            this.dialog = false;
            this.formAfp.detail = '';
            this.formAfp.discount = '';
            this.formAfp.idDepartament = '';
            this.formAfp.idTeacher = '';
            this.formAfp.idUser = '';
            this.$refs.formAfp.resetValidation();
        },
        //other module
        clearDate(){
            this.formSearchReport.rangeDate = '';
            this.formSearchReport.date = '';
            this.formSearchReport.startDate = '';
            this.formSearchReport.endDate = '';
            this.searchAfpReport = [];
            this.searchIndividualReport = [];
            this.searchPracticeReport = [];
            this.totalAfp = 0;
            this.totalIndividualIngress = 0;
            this.totalPracticeIngress = 0;
            this.$refs.formSearchReport.resetValidation();
        },
        permittedDate(date) {
            const dateConst = new Date(date);
            return dateConst.getDay() !== 6;
        },
        async selectDepartamentById(){
            try {
                let response = await axios.get(`departaments/selectDepartamentById/${this.idDepartament}`);
                if (response.status == 200) {
                    this.nameDepartament = response.data.nombreDepartamento;
                }
            } catch (error) {
                console.log(error);
            }
        },
        print(){
            let nameTeacher = this.teachers.find((value) => value.idUsuario === this.formSearchReport.idTeacher);
            this.totalResume = {
                totalIndividualIngress: this.totalIndividualIngress,
                totalPracticeIngress: this.totalPracticeIngress,
                totalAfp: this.totalAfp,
                total: this.totalIndividualIngress+this.totalPracticeIngress,
                nameTeacher: nameTeacher.nombres,
                rangeDate: this.formSearchReport.rangeDate,
            }
            this.$refs.reportIndividualPayment.generatePdf();
        },
        //Afp Report
        async selectSearchAfpReport(url){
            try {
                let response = await axios.get(`reports/selectReportAfpByIdTeacher/${url}`);
                if (response.status == 200) {
                    if (response.data.length <= 0) {
                        this.notification('primary','No se encontro descuentos');
                        return;
                    }
                    response.data.map((value,index) => {
                        this.totalAfp = this.totalAfp + Number(value.descuento);
                        value.fechaDescuento = this.formatDate(value.fechaDescuento.substring(0,10));
                        this.searchAfpReport.push({
                            nro: index+1,
                            fechaDescuento: value.fechaDescuento,
                            detalle: value.detalle,
                            descuento: value.descuento,
                        });
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        //Practice Report
        async selectSearchPracticeReport(url){
            try {
                let response = await axios.get(`reports/selectPaymentPracticeByIdTeacher/${url}`);
                if (response.status == 200) {
                    if (response.data.length <= 0) {
                        this.notification('primary','No se encontro ingresos grupales');
                        this.loadingSearchPracticeReport = false;
                        return;
                    }
                    response.data.map((value,index) => {
                        this.totalPracticeIngress = this.totalPracticeIngress + Number(value.pago);
                        value.fechaPago = this.formatDate(value.fechaPago.substring(0,10));
                        this.searchPracticeReport.push({
                            nro: index+1,
                            fechaPago: value.fechaPago,
                            nombre: value.nombre,
                            detalleIngreso: value.detalleIngreso,
                            sigla: value.sigla,
                            pago: value.pago
                        });
                    });
                    this.loadingSearchPracticeReport = false;
                    
                }
            } catch (error) {
                console.log(error);
                this.loadingSearchPracticeReport = false;
            }
        },
        //Individual Report
        async selectSearchIndividualReport(url){
            try {
                let response = await axios.get(`reports/selectPaymentClassByIdTeacher/${url}`);
                let response2 = await axios.get(`reports/selectPaymentIngressByIdTeacher/${url}`);
                if (response.status == 200 && response2.status == 200) {
                    let data = [];
                    data = response.data;
                    data.push(...response2.data);

                    data.map((value,index) => {
                        this.totalIndividualIngress = this.totalIndividualIngress + Number(value.total);
                        value.fechaPago = this.formatDate(value.fechaPago.substring(0,10));
                        value.fechaClase = this.formatDate(value.fechaClase.substring(0,10));
                        this.searchIndividualReport.push({
                            nro: index+1,
                            fechaPago: value.fechaPago,
                            pago: value.pago,
                            tipo: value.tipo,
                            fechaClase: value.fechaClase,
                            inicio: value.inicio,
                            fin: value.fin,
                            horas: value.horas,
                            nombre: value.nombres +' '+ value.apellidos,
                            materia: value.materia,
                            nivel: value.nivel,
                            nroAlumnos: value.nroAlumnos,
                            total: value.total
                        });
                    });
                    this.loadingSearchIndividualReport = false;  
                }
            } catch (error) {
                console.log(error);
                this.loadingSearchIndividualReport = false;
            }
        },
        searchReportByParams(){
            if (this.$refs.formSearchReport.validate()) {

                let url = `${this.idDepartament}/${this.formSearchReport.idTeacher}/${this.formSearchReport.startDate}/${this.formSearchReport.endDate}`

                this.loadingSearchIndividualReport = true;

                this.searchIndividualReport = [];
                this.totalIndividualIngress = 0;
                this.searchPracticeReport = [];
                this.totalPracticeIngress = 0;
                this.searchAfpReport = [];
                this.totalAfp = 0;

                this.selectSearchIndividualReport(url);
                this.selectSearchPracticeReport(url);
                this.selectSearchAfpReport(url);
            }
        },
        saveStart (date) {
			this.$refs.menuStart.save(date);
            this.formSearchReport.date = '';
		},
		saveEnd (date) {
			this.$refs.menuEnd.save(date);
            this.formSearchReport.date = '';
		},
        saveDay(date){
            this.$refs.menuDay.save(date);
            let today = new Date(date);
            let weekDay = today.getDay();

            if (weekDay === 0) {
                this.formSearchReport.startDate = date;
                const sat = new Date(today.getTime() + (5 * 24 * 60 * 60 * 1000));
                this.formSearchReport.endDate = sat.toISOString().substring(0,10);
            } else if (weekDay === 5) {
                this.formSearchReport.endDate = date;
                const mon = new Date(today.getTime() - (5 * 24 * 60 * 60 * 1000));
                this.formSearchReport.startDate = mon.toISOString().substring(0,10);
            } else {
                const mon = new Date(today.getTime() - (weekDay * 24 * 60 * 60 * 1000));
                this.formSearchReport.startDate = mon.toISOString().substring(0,10);
                const sat = new Date(today.getTime() + ((5-weekDay)*24*60*60*1000));
                this.formSearchReport.endDate = sat.toISOString().substring(0,10);
            }

            this.formSearchReport.rangeDate = this.formatDateReport(this.formSearchReport.startDate)+' - '+this.formatDateReport(this.formSearchReport.endDate);
        },
        userFilter (item, queryText) {
			const textOne = item.nombres.toLowerCase();
			const searchText = queryText.toLowerCase();

			return textOne.indexOf(searchText) > -1;
		},
        async selectSearchUserByIdRole(){
            let idRole1 = 3;
            let idRole2 = 5;
            try {
                let response = await axios.get(`users/selectSearchUserByIdRole/${this.idDepartament}/${idRole1}/${idRole2}`);
                if (response.status == 200) {
                    this.teachers = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        cleanFormSearchReport(){
            this.formSearchReport.date = '';
            this.formSearchReport.endDate = '';
            this.formSearchReport.idTeacher = '';
            this.formSearchReport.startDate = '';
            this.formSearchReport.rangeDate = '';
            this.totalIndividualIngress = 0;
            this.totalPracticeIngress = 0;
            this.totalAfp = 0;
            this.searchAfpReport = [];
            this.searchIndividualReport = [];
            this.searchPracticeReport = [];
            this.$refs.formSearchReport.resetValidation();
        },

        notification(type,text){
			this.snackbar = true;
			this.colorSnackbar = type;
			this.textSnackbar = text;
		},
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        formatDateReport(date){
            const nameMonth = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
            
            const [year,month,day] = date.split('-');
            return `${day} ${nameMonth[Number(month)-1]} ${year}`;
        }
    }
}
</script>

<style>
    .td-click td{
		cursor: pointer;
	}
</style>
<template>
    <v-layout>
        <v-container>
            <v-breadcrumbs class="pl-0" :items="items">
				<template v-slot:item="{ item }">
					<v-breadcrumbs-item
						:to="item.href"
						:disabled="item.disabled"
					>
						{{ item.text}}
					</v-breadcrumbs-item>
				</template>
			</v-breadcrumbs>
			<v-toolbar
				flat
			>
				<v-toolbar-title>Pago de Mensualidades</v-toolbar-title>
            </v-toolbar>
            <v-card outlined tile>
                <v-card-text class="mt-1">
                    <p class="font-weight-bold text-body-1" style="color: #1486bd;">Buscar Estudiante</p>
                    <v-form ref="formSearchStudent">
                        <v-row>
                            <v-col cols="12" sm="2" md="2" class="py-0">
                                <p class="mb-2">Opciones</p>
                                <v-select
                                    v-model="formSearchStudent.option"
                                    :items="optionItems"
                                    item-text="option"
                                    item-value="idOption"
                                    label="Seleccione una opcion"
                                    persistent-hint
                                    return-object
                                    outlined
                                    single-line
                                    dense
                                    @change="optionChange"
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="4"
                                md="4"
                                class="py-0"
                            >
                                <p class="mb-2">{{ optionName }}</p>
                                <v-text-field
                                    v-model="formSearchStudent.value"
                                    class="pt-0"
                                    :placeholder="optionName"
                                    single-line
                                    dense
                                    outlined
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="4"
                                md="4"
                                class="py-0"
                            >
                                <v-btn
                                    class="mt-sm-8 mt-md-8"
                                    dark
                                    color="#1486bd" 
                                    elevation="0"
                                    @click="searchStudentByParams"
                                >
                                    <span class="text-capitalize text-body">Buscar</span> 
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
            <template v-if="loadingSearch">
                <v-container class="d-flex justify-center">
                    <v-progress-circular 
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </v-container>
            </template>
            <div v-show="searchStudents.length > 0">
                <v-data-table
                    :headers="headersStudent"
                    :items="searchStudents"
                    class="elevation-1 font-weight-light caption td-click"
                    @click:row="clickRow"
                    fixed-header
                >
                    <template v-slot:[`item.fechaInscripcion`]="{item}">
                        {{formatDate(item.fechaInscripcion.substring(0, 10))}}
                    </template>
                </v-data-table>
            </div>
            <v-snackbar
                v-model="snackbar"
                :color="colorSnackbar"
                text
            >
                <span class="text-center">{{ textSnackbar }}</span>
            </v-snackbar>
        </v-container>
    </v-layout>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
export default {
    data(){
        return{
            headersStudent: [
				{text: "Ci", value: "ci", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Apellidos", value: "apellidos", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Nombres", value: "nombres", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Semestre", value: "nombreSemestre", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Grupo", value: "nombreGrupo", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Fecha Inscripcion", value: "fechaInscripcion", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                //{text: "Acciones", value: "accion", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			],
            formSearchStudent: {
                names: '',
				surnames: '',
				ci: '',
                value: '',
                option: {
                    idOption: 1,
                    option: 'CI'
                },
            },
            loadingSearch: false,
            searchStudents: [],
            snackbar: false,
            colorSnackbar: '',
            textSnackbar: '',
            items: [
				{
					text: 'Mensualidad',
					disabled: false,
					href: '/monthly-pay-student',
				},
			],
            optionItems: [
                {
                    idOption: 1,
                    option: 'CI'
                },
                {
                    idOption: 2,
                    option: 'Apellidos'
                },
                {
                    idOption: 3,
                    option: 'Nombres'
                }
            ],
            optionName: 'CI',
        }
    },
    computed: {
        ...mapState(['idDepartament','user']),
    },
    created(){

    },
    methods: {
        clickRow(item){
            this.$router.push(`/monthly-pay-student/group/${item.idGrupo}/student/${item.idUsuario}`);
		},
        optionChange(evt){
            this.optionName = evt.option;
            this.formSearchStudent.value = '';
        },
        searchStudentByParams(){
            let names = '';
            let surnames = '';
            let ci = '';
            if (this.formSearchStudent.option.idOption == 1) {
                names = null;
                surnames = null;
                ci = this.formSearchStudent.value == '' ? null : this.formSearchStudent.value;
            } else if (this.formSearchStudent.option.idOption == 2) {
                names = null;
                surnames = this.formSearchStudent.value == '' ? null : this.formSearchStudent.value;
                ci = null;
            } else if (this.formSearchStudent.option.idOption == 3) {
                names = this.formSearchStudent.value == '' ? null : this.formSearchStudent.value;
                surnames = null;
                ci = null;
            }
            let url = `${this.idDepartament}/${names}/${surnames}/${ci}`;
            this.loadingSearch = true;
            this.selectSearchStudent(url);
        },
        async selectSearchStudent(url) {
            try {
                let response = await axios.get(`users/selectSearchStudentMonthlyPay/${url}`);
                if (response.status == 200) {
                    this.searchStudents = response.data;
                    this.loadingSearch = false;
                    if (response.data.length <= 0) {
                        this.notification('primary','No se encontro registros');
                    }
                }
            } catch (error) {
                console.log(error);
                this.loadingSearch = false;
            }
        },
        notification(type,text){
			this.snackbar = true;
			this.colorSnackbar = type;
			this.textSnackbar = text;
		},
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
    }
}
</script>

<style>
    .td-click td{
		cursor: pointer;
	}
</style>
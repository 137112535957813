<template>
	<v-layout align-start>
		<v-container>
			<v-breadcrumbs class="pl-0" :items="items">
				<template v-slot:item="{ item }">
					<v-breadcrumbs-item
						:to="item.href"
						:disabled="item.disabled"
					>
						{{ item.text}}
					</v-breadcrumbs-item>
				</template>
			</v-breadcrumbs>
			<v-toolbar
				flat
			>
				<v-toolbar-title>Categoria de pago</v-toolbar-title>
				
				<v-spacer></v-spacer>
				<v-btn
					color="#1486bd"
					dark
					@click="dialog = true"
				>
					<span class="text-capitalize text-body">nueva</span> 
				</v-btn>
				<v-dialog
				v-model="dialog"
				max-width="550px"
				>
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ formTitle }}</span>
						</v-card-title>

						<v-card-text>
							<v-container>
								<v-form ref="form">
									<v-row>
                                        <v-col
											cols="12"
											sm="5"
											md="5"
											class="py-0"
										>
											<p class="mb-2">Nro Alumnos</p>
											<v-select
												v-model="formPaymentCategory.numberStudent"
												:items="numberStudents"
												label="Nro Alumnos"
												persistent-hint
												dense
												outlined
												single-line
												:disabled="stateNumberStudent"
												:rules="studentRules"
											></v-select>
										</v-col>
										<v-col
											cols="12"
											sm="7"
											md="7"
											class="py-0"
										>
											<p class="mb-2">Nivel</p>
											<v-select
												v-model="formPaymentCategory.idLevel"
												:items="lessonLevels"
												item-text="nombreNivel"
												item-value="idNivel"
												label="Nivel"
												persistent-hint
												dense
												outlined
												single-line
												:rules="levelRules"
											></v-select>
										</v-col>
										<v-col
											cols="12"
											sm="6"
											md="6"
											class="py-0"
										>
											<p class="mb-2">Tipo</p>
											<v-select
												v-model="formPaymentCategory.idType"
												:items="types"
												item-text="nameType"
												item-value="idType"
												label="Tipo"
												persistent-hint
												dense
												outlined
												single-line
												@change="changeAmount"
												:rules="typeRules"
											></v-select>
										</v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            class="py-0"
                                        >
                                            <p class="mb-2">{{ titleAmount }}</p>
                                            <v-text-field
                                                v-model="formPaymentCategory.amount"
                                                class="pt-0"
                                                :placeholder="titleAmount"
                                                single-line
                                                dense
                                                outlined
                                                :rules="amountRules"
                                            >
                                            </v-text-field>
                                        </v-col>
									</v-row>
								</v-form>
							</v-container>
						</v-card-text>

						<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							:disabled="loadingData"
							@click="close"
						>
							<span class="text-capitalize">Cancelar</span>
						</v-btn>
						<v-btn
							color="blue darken-1"
							dark
							:loading="loadingData"
							@click="savePaymentCategory"
						>
							<span class="text-capitalize">Guardar</span>						
						</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="400px">
					<v-card>
						<p style="color: #1874BF" class="text-h6 text-center pt-4">Informe de Categoria de pago</p>
						<v-card-text v-if="(user.idRol == 1)" class="text-center">
							{{`¿Esta seguro de ${editedItem.estado ? 'desactivar' : 'activar'} la categoria de pago ${editedItem.nombreCategoriaPago}?`}}
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
							<v-btn dark color="blue darken-1" @click="deleteItemConfirm">Si</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
			<v-container v-if="loading" class="d-flex justify-center">
				<v-progress-circular 
					indeterminate
					color="primary"
				></v-progress-circular>
			</v-container>
			<template v-if="paymentCategorys.length > 0">
				<v-data-table
					:headers="headers"
					:items="paymentCategorys"
					class="elevation-1 font-weight-light caption"
					
					@click:row="clickRow"
					fixed-header
				>
					<template v-slot:[`item.tipo`]="{item}">
						{{ item.tipo == 1 ? 'Individual' : 'Practica' }}
					</template>
					<template v-slot:[`item.estado`]="{item}">
						{{item.estado ? 'Activo' : 'Inactivo'}}
					</template>
					<template v-slot:[`item.accion`]="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{on,attrs}">
								<v-btn
									icon
									color="primary"
									class="mr-2"
									@click.stop="editItem(item)"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</template>
							<span>Editar</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{on,attrs}">
								<v-btn
									icon
									:color="item.estado ? 'error' : 'success'"
									class="mr-2"
									@click.stop="deleteItem(item)"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>{{item.estado ? 'mdi-cancel' : 'mdi-check'}}</v-icon>
								</v-btn>
							</template>
							<span>{{item.estado ? 'Desactivar' : 'Activar'}}</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</template>
			<template v-else>
				<v-card elevation="0" outlined v-if="loading == false">
					<v-card-text class="text-center pb-0">No existen Categorias de pago registrados.</v-card-text>
					<v-card-text class="text-center pt-0"><v-btn color="blue darken-1" dark small class="my-2 px-2" tile elevation="0" @click="selectPaymentCategorys">Volver a Intentar</v-btn></v-card-text>
				</v-card>
			</template>
			<v-snackbar
				v-model="snackbar"
				:color="colorSnackbar"
				text
			>
				<span class="text-center">{{ textSnackbar }}</span>
			</v-snackbar>
		</v-container>
	</v-layout>  
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
export default {
	data(){
		return {
			dialog: false,
			dialogDelete: false,
			headers: [
				{text: "Nro Estudiantes", value: "nroEstudiante", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Monto", value: "monto", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Nivel", value: "nivel", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Tipo", value: "tipo", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Estado", value: "estado", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Acciones", value: "accion", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			],
            formPaymentCategory: {
                idPaymentCategory: '',
                amount: '',
				numberStudent: '',
                idLevel: '',
                idDepartament: '',
				idType: '',
            },
			numberStudents: [1,2,3,4,5,6,7,8,9,10],
			paymentCategorys: [],
			editedIndex: -1,
			creationDate: new Date().toISOString().substring(0, 10),
            amountRules: [
                value => !!value || 'Ingrese un monto',
			],
			levelRules: [
                value => !!value || 'Debe seleccionar un nivel',
            ],
			studentRules: [
                value => !!value || 'Debe seleccionar un nro Estudiante',
            ],
			typeRules: [
                value => !!value || 'Debe seleccionar un tipo',
            ],
			lessonLevels: [],
			loading: false,
			loadingData: false,
			snackbar: false,
			colorSnackbar: '',
			textSnackbar: '',
			editedItem: {},
			defaultItem: {},
			items: [
				{
					text: 'Categoria de pago',
					disabled: true,
					href: 'paymentCategory',
				}
			],
			types:[
				{
					idType: 1,
					nameType: 'Individual'
				},
				{
					idType: 2,
					nameType: 'Practica'
				}
			],
			titleAmount: 'Monto',
			stateNumberStudent: false,
		}
	},
	computed: {
		...mapState(['user','idDepartament']),
		formTitle () {
			return this.editedIndex === -1 ? 'Nueva Categoria de pago' : 'Editar Categoria de pago';
		},
	},
	watch: {
		dialog (val) {
			val || this.close()
		},
		dialogDelete (val) {
			val || this.closeDelete()
		},
	},
	created () {
		this.selectPaymentCategorys();
		this.selectLessonLevels();
	},
	methods: {
		changeAmount(){
			if (this.formPaymentCategory.idType == 1) {
				this.titleAmount = 'Monto';
				this.stateNumberStudent = false;
			} else if (this.formPaymentCategory.idType == 2){
				this.titleAmount = 'Porcentaje';
				this.formPaymentCategory.numberStudent = 1;
				this.stateNumberStudent = true;
			}
		},
		clickRow(item){
            console.log(item);
			//this.$router.push('/group/'+item.idSemestre);
		},
		async deletePaymentCategory(data){
			try {
				let response = await axios.put('paymentCategorys/deletePaymentCategory',data);
				if (response.status == 200) {
					this.closeDelete();
					this.notification('warning',response.data['msg']);
					this.selectPaymentCategorys();
				}
			} catch (error) {
				console.log(error);
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		async insertPaymentCategory(data){
			try {
				let response = await axios.post('paymentCategorys/insertPaymentCategory',data);
				if (response.status == 200) {
					this.close();
					this.loadingData = false;
					this.notification('success',response.data['msg']);
					this.selectPaymentCategorys();
				}
			} catch (error) {
				console.log(error);
				this.loadingData = false;
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		async updatePaymentCategory(data){
			try {
				let response = await axios.put('paymentCategorys/updatePaymentCategory',data);
				if (response.status == 200) {
					this.close();
					this.loadingData = false;
					this.notification('success',response.data['msg']);
					this.selectPaymentCategorys();
				}
			} catch (error) {
				console.log(error);
				this.loadingData = false;
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		savePaymentCategory () {
			if (this.$refs.form.validate()) {
				if (this.editedIndex > -1) {
					//modificar
					let amount = this.formPaymentCategory.amount; 
					if (this.formPaymentCategory.idType == 2) {
						amount = this.formPaymentCategory.amount / 100;
					}

					let data = {
						idPaymentCategory: this.formPaymentCategory.idPaymentCategory,
						amount: amount,
                        numberStudent: this.formPaymentCategory.numberStudent,
						idDepartament: this.idDepartament,
						idLevel: this.formPaymentCategory.idLevel,
						type: this.formPaymentCategory.idType,
					}
					this.loadingData = true;
					this.updatePaymentCategory(data);
				} else {
					let amount = this.formPaymentCategory.amount; 
					if (this.formPaymentCategory.idType == 2) {
						amount = this.formPaymentCategory.amount / 100;
					}

					let data = {
                        amount: amount,
                        numberStudent: this.formPaymentCategory.numberStudent,
						idDepartament: this.idDepartament,
						idLevel: this.formPaymentCategory.idLevel,
						type: this.formPaymentCategory.idType,
					};
					this.loadingData = true;
					this.insertPaymentCategory(data);
				}
			}
		},

		async selectPaymentCategorys(){
			try {
				this.loading = true;
				let response = await axios.get(`paymentCategorys/selectPaymentCategorys/null/${this.idDepartament}`);
				if (response.status == 200) {
					this.paymentCategorys = response.data;
					this.loading = false;
				}
			} catch (error) {
				console.log(error);
				this.loading = false;
			}
		},
		async selectLessonLevels(){
            try {
                let response = await axios.get(`lessonLevels/selectLessonLevels/1/${this.idDepartament}`);
                if (response.status == 200) {
                    this.lessonLevels = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },

		notification(type,text){
			this.snackbar = true;
			this.colorSnackbar = type;
			this.textSnackbar = text;
		},

		editItem (item) {
			this.editedIndex = 1;
			this.editedItem = Object.assign({}, item);
            this.formPaymentCategory.idPaymentCategory = this.editedItem.idCategoriaPago;
			this.formPaymentCategory.numberStudent = this.editedItem.nroEstudiante;
            this.formPaymentCategory.amount = this.editedItem.monto;
            this.formPaymentCategory.idDepartament = this.editedItem.idDepartamento;
			this.formPaymentCategory.idLevel = this.editedItem.idNivel;
			this.formPaymentCategory.idType = this.editedItem.tipo;
			this.dialog = true;
		},

		deleteItem (item) {
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm () {
			let data = {
				idPaymentCategory: this.editedItem.idCategoriaPago,
				state: this.editedItem.estado ? 0 : 1,
			}
			this.deletePaymentCategory(data);
		},

		close () {
			this.editedIndex = -1;
			this.dialog = false;
			this.formPaymentCategory.idPaymentCategory = '';
			this.formPaymentCategory.numberStudent = '';
            this.formPaymentCategory.amount = '';
            this.formPaymentCategory.idDepartament = '';
			this.formPaymentCategory.idLevel = '';
			this.formPaymentCategory.idType = '';
			this.titleAmount = 'Monto';
			this.stateNumberStudent = false;
			this.$refs.form.resetValidation();
		},

		closeDelete () {
			this.dialogDelete = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},

		
	}
}
</script>

<style>
	.td-click td{
		cursor: pointer;
	}
</style>
<template>
	<v-layout align-start>
		<v-container>
			<v-breadcrumbs class="pl-0" :items="items">
				<template v-slot:item="{ item }">
					<v-breadcrumbs-item
						:to="item.href"
						:disabled="item.disabled"
					>
						{{ item.text}}
					</v-breadcrumbs-item>
				</template>
			</v-breadcrumbs>
			<v-toolbar
				flat
			>
				<v-toolbar-title>Materias</v-toolbar-title>
				
				<v-spacer></v-spacer>
				<v-btn
					color="#1486bd"
					dark
					@click="dialog = true"
				>
					<span class="text-capitalize text-body">nueva</span> 
				</v-btn>
				<v-dialog
				v-model="dialog"
				max-width="500px"
				>
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ formTitle }}</span>
						</v-card-title>

						<v-card-text>
							<v-container>
								<v-form ref="form">
									<v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            class="py-0"
                                        >
                                            <p class="mb-2">Nombre</p>
                                            <v-text-field
                                                v-model="nameSubject"
                                                class="pt-0"
                                                placeholder="Nombre"
                                                single-line
                                                dense
                                                outlined
                                                :rules="nameRules"
												hide-details="auto"
                                            >
                                            </v-text-field>
                                        </v-col>
										<v-col
                                            cols="12"
                                            sm="6"
                                            md="6"
                                            class="py-0"
                                        >
                                            <p class="mb-2">Codigo</p>
                                            <v-text-field
                                                v-model="codeSubject"
                                                class="pt-0"
                                                placeholder="Codigo"
                                                single-line
                                                dense
                                                outlined
												:rules="codeRules"
												hide-details="auto"
                                            >
                                            </v-text-field>
                                        </v-col>
										<v-col
											cols="12"
											sm="6"
											md="6"
											class="py-0 mt-1"
										>
											<p class="mb-2">Tipo</p>
											<v-select
												v-model="typeSubject"
												:items="type"
												item-text="text"
												item-value="value"
												placeholder="Tipo"
												dense
												outlined
												single-line
												:rules="typeRules"
												hide-details="auto"
											></v-select>
										</v-col>
									</v-row>
								</v-form>
							</v-container>
						</v-card-text>

						<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							:disabled="loadingData"
							@click="close"
						>
							<span class="text-capitalize">Cancelar</span>
						</v-btn>
						<v-btn
							color="blue darken-1"
							dark
							:loading="loadingData"
							@click="saveSubject"
						>
							<span class="text-capitalize">Guardar</span>						
						</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="400px">
					<v-card>
						<p style="color: #1874BF" class="text-h6 text-center pt-4">Informe de Materia</p>
						<v-card-text v-if="(user.idRol == 1)" class="text-center">
							{{`¿Esta seguro de ${editedItem.estado ? 'desactivar' : 'activar'} la materia ${editedItem.nombreMateria}?`}}
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
							<v-btn dark color="blue darken-1" @click="deleteItemConfirm">Si</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
			<v-container v-if="loading" class="d-flex justify-center">
				<v-progress-circular 
					indeterminate
					color="primary"
				></v-progress-circular>
			</v-container>
			<template v-if="subjects.length > 0">
				<v-data-table
					:headers="headers"
					:items="subjects"
					item-key="idTurno"
					class="elevation-1 font-weight-light caption"
					
					@click:row="clickRow"
					fixed-header
				>
					<template v-slot:[`item.tipo`]="{item}">
						{{ item.tipo == 1 ? 'Individual' : 'Grupal' }}
					</template>
					<template v-slot:[`item.estado`]="{item}">
						{{item.estado ? 'Activo' : 'Inactivo'}}
					</template>
					<template v-slot:[`item.accion`]="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{on,attrs}">
								<v-btn
									icon
									color="primary"
									class="mr-2"
									@click.stop="editItem(item)"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</template>
							<span>Editar</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{on,attrs}">
								<v-btn
									icon
									:color="item.estado ? 'error' : 'success'"
									class="mr-2"
									@click.stop="deleteItem(item)"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>{{item.estado ? 'mdi-cancel' : 'mdi-check'}}</v-icon>
								</v-btn>
							</template>
							<span>{{item.estado ? 'Desactivar' : 'Activar'}}</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</template>
			<template v-else>
				<v-card elevation="0" outlined v-if="loading == false">
					<v-card-text class="text-center pb-0">No existen Materias Registradas.</v-card-text>
					<v-card-text class="text-center pt-0"><v-btn color="blue darken-1" dark small class="my-2 px-2" tile elevation="0" @click="selectSubjects">Volver a Intentar</v-btn></v-card-text>
				</v-card>
			</template>
			<v-snackbar
				v-model="snackbar"
				:color="colorSnackbar"
			>
				<span class="text-center">{{ textSnackbar }}</span>
			</v-snackbar>
		</v-container>
	</v-layout>  
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
export default {
	data(){
		return {
			dialog: false,
			dialogDelete: false,
			headers: [
				{text: "Nombre", value: "nombreMateria", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Codigo", value: "codigoMateria", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Tipo", value: "tipo", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Estado", value: "estado", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Acciones", value: "accion", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			],
			subjects: [],
			editedIndex: -1,
			nameSubject: '',
			codeSubject: '',
			idSubject: '',
			creationDate: new Date().toISOString().substring(0, 10),
			nameRules: [
				value => !!value || 'Ingrese una materia',
			],
			codeRules: [
				value => !!value || 'Ingrese un codigo',
			],
			typeRules: [
				value => !!value || 'Seleccione un tipo',
			],
			loading: false,
			loadingData: false,
			snackbar: false,
			colorSnackbar: '',
			textSnackbar: '',
			editedItem: {},
			defaultItem: {},
			items: [
				{
					text: 'Materias',
					disabled: true,
					href: 'subject',
				}
			],
			type: [
				{value: 1, text: 'Individual' },
				{value: 2, text: 'Grupal'}
			],
			typeSubject: '',
		}
	},
	computed: {
		...mapState(['user','idDepartament']),
		formTitle () {
			return this.editedIndex === -1 ? 'Nueva materia' : 'Editar materia';
		},
	},
	watch: {
		dialog (val) {
			val || this.close()
		},
		dialogDelete (val) {
			val || this.closeDelete()
		},
	},
	created () {
		this.selectSubjects();
	},
	methods: {
		clickRow(item){
            console.log(item);
			//this.$router.push('/group/'+item.idSemestre);
		},
		async deleteSubject(data){
			try {
				let response = await axios.put('subjects/deleteSubject',data);
				if (response.status == 200) {
					this.closeDelete();
					this.notification('warning',response.data['msg']);
					this.selectSubjects();
				}
			} catch (error) {
				console.log(error);
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		async insertSubject(data){
			try {
				let response = await axios.post('subjects/insertSubject',data);
				if (response.status == 200) {
					this.close();
					this.loadingData = false;
					this.notification('success',response.data['msg']);
					this.selectSubjects();
				}
			} catch (error) {
				console.log(error);
				this.loadingData = false;
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		async updateSubject(data){
			try {
				let response = await axios.put('subjects/updateSubject',data);
				if (response.status == 200) {
					this.close();
					this.loadingData = false;
					this.notification('success',response.data['msg']);
					this.selectSubjects();
				}
			} catch (error) {
				console.log(error);
				this.loadingData = false;
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		saveSubject () {
			if (this.$refs.form.validate()) {
				if (this.editedIndex > -1) {
					//modificar
					let data = {
						idSubject: this.idSubject,
						nameSubject: this.nameSubject.trim().toUpperCase(),
						codeSubject: this.codeSubject.trim().toUpperCase(),
						type: this.typeSubject,
					}
					this.loadingData = true;
					this.updateSubject(data);
				} else {
					let data = {
						nameSubject: this.nameSubject.trim().toUpperCase(),
						codeSubject: this.codeSubject.trim().toUpperCase(),
						type: this.typeSubject,
						idDepartament: this.idDepartament,
					};
					this.loadingData = true;
					
					this.insertSubject(data);
				}
			}
		},

		async selectSubjects(){
			try {
				this.loading = true;
				let response = await axios.get(`subjects/selectSubjects/null/${this.idDepartament}`);
				if (response.status == 200) {
					this.subjects = response.data;
					this.loading = false;
				}
			} catch (error) {
				console.log(error);
				this.loading = false;
			}
		},

		notification(type,text){
			this.snackbar = true;
			this.colorSnackbar = type;
			this.textSnackbar = text;
		},

		editItem (item) {
			this.editedIndex = 1;
			this.editedItem = Object.assign({}, item);
			this.idSubject = this.editedItem.idMateria;
			this.nameSubject = this.editedItem.nombreMateria;
			this.codeSubject = this.editedItem.codigoMateria;
			this.typeSubject = this.editedItem.tipo;
			this.dialog = true;
		},

		deleteItem (item) {
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm () {
			let data = {
				idSubject: this.editedItem.idMateria,
				state: this.editedItem.estado ? 0 : 1,
			}
			this.deleteSubject(data);
		},

		close () {
			this.editedIndex = -1;
			this.$refs.form.resetValidation();
			this.nameSubject = '';
			this.codeSubject = '';
			this.idSubject = '';
			this.typeSubject = '';
			this.dialog = false;
		},

		closeDelete () {
			this.dialogDelete = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},

		
	}
}
</script>

<style>
	.td-click td{
		cursor: pointer;
	}
</style>
<template>
  <v-navigation-drawer v-model="Drawer" clipped app>
    <v-list dense nav v-if="navigation" style="color: #1486bd">
      <template v-if="user.idRol == 2 || user.idRol == 1">
        <v-subheader
          ><span style="color: #1486bd" class="text-caption font-weight-medium"
            >PRINCIPAL</span
          ></v-subheader
        >
        <v-list-item :to="{ name: selectHome }">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-title
            ><span class="text-caption font-weight-medium"
              >Inicio</span
            ></v-list-item-title
          >
        </v-list-item>
        <v-divider style="color: #4c4c4c" />
      </template>

      <template v-if="user.idRol == 2 || user.idRol == 1">
        <v-subheader
          ><span style="color: #1486bd" class="text-caption font-weight-medium"
            >GRUPOS Y MENSUALIDADES</span
          ></v-subheader
        >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="{ name: item.name }"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider style="color: #4c4c4c" />
      </template>

      <template v-if="user.idRol == 2 || user.idRol == 1">
        <v-subheader
          ><span style="color: #1486bd" class="text-caption font-weight-medium"
            >HORAS INDIVIDUALES</span
          ></v-subheader
        >
        <v-list-item
          v-for="item in itemsTeacher"
          :key="item.title"
          :to="{ name: item.name }"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-if="user.idRol == 2 || user.idRol == 1">
        <v-subheader
          ><span style="color: #1486bd" class="text-caption font-weight-medium"
            >INGRESOS Y EGRESOS</span
          ></v-subheader
        >
        <v-list-item
          v-for="item in itemsIngress"
          :key="item.title"
          :to="{ name: item.name }"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-if="user.idRol == 2 || user.idRol == 1">
        <v-subheader
          ><span style="color: #1486bd" class="text-caption font-weight-medium"
            >CALIFICACIONES</span
          ></v-subheader
        >
        <v-list-item
          v-for="item in itemsGrade"
          :key="item.title"
          :to="{ name: item.name }"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-if="user.idRol == 2 || user.idRol == 1">
        <v-subheader
          ><span style="color: #1486bd" class="text-caption font-weight-medium"
            >CREDENCIALES</span
          ></v-subheader
        >
        <v-list-item
          v-for="item in itemsCredential"
          :key="item.title"
          :to="{ name: item.name }"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-if="user.idRol > 2 && user.idRol < 6">
        <v-subheader
          ><span style="color: #1486bd" class="text-caption font-weight-medium"
            >HORAS INDIVIDUALES</span
          ></v-subheader
        >
        <v-list-item
          v-for="item in itemsTeacher"
          :key="item.title"
          :to="{ name: item.name }"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider style="color: #4c4c4c" />
      <template v-if="user.idRol == 1 || user.idRol == 2">
        <v-subheader
          ><span style="color: #1486bd" class="text-caption font-weight-medium"
            >REPORTES</span
          ></v-subheader
        >
        <v-list-item
          v-for="item in itemsReport"
          :key="item.title"
          :to="{ name: item.name }"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider style="color: #4c4c4c" />
      </template>
      <template v-if="user.idRol == 1">
        <v-subheader
          ><span style="color: #1486bd" class="text-caption font-weight-medium"
            >ADMINISTRACION</span
          ></v-subheader
        >
        <v-list-item
          v-for="item in itemsAdmin"
          :key="item.title"
          :to="{ path: item.name }"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      items: [],
      itemsAdmin: [],
      itemsTeacher: [],
      itemsReport: [],
      itemsIngress: [],
      right: null,
    };
  },
  computed: {
    ...mapState(["drawer", "user", "appIcon", "idDepartament"]),
    navigation() {
      if (this.appIcon) {
        this.loadNavigation();
        return true;
      } else {
        return false;
      }
    },
    Drawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.setDrawer(value);
      },
    },
    selectHome() {
      let res = "";
      if (this.user.idRol == 1) {
        res = "home";
      } else if (this.user.idRol == 2) {
        res = "home-administrative";
      }
      return res;
    },
  },
  created() {},
  methods: {
    ...mapMutations([
      "setDrawer",
      "setOptionsGroup",
      "setOptionsSchedule",
      "setOptionsAdmin",
      "setOptionsReport",
      "setOptionsIngress",
      "setOptionsStudentGrade",
      "setOptionsStudentCredential",
    ]),
    loadNavigation() {
      this.items = [];
      this.itemsAdmin = [];
      this.itemsTeacher = [];
      this.itemsIngress = [];
      this.itemsReport = [];
      this.itemsGrade = [];
      this.itemsCredential = [];
      this.setOptionsGroup([]);
      this.setOptionsSchedule([]);
      this.setOptionsAdmin([]);
      this.setOptionsIngress([]);
      this.setOptionsReport([]);
      this.setOptionsStudentGrade([]);
      this.setOptionsStudentCredential([]);

      if (this.user.idRol == 1) {
        this.items.push(
          {
            title: "Semestre",
            icon: "mdi-calendar-text",
            name: "semester",
          },
          {
            title: "Registrar Estudiante",
            icon: "mdi-account-box",
            name: "student",
          },
          {
            title: "Mensualidad",
            icon: "mdi-cash",
            name: "monthly-pay-student",
          }
        );
        this.setOptionsGroup(this.items);
        this.itemsTeacher.push(
          {
            title: "Profesores",
            icon: "mdi-account-tie",
            name: "teacher",
          },
          {
            title: "Horas Individuales",
            icon: "mdi-cash-clock",
            name: "individual-hours-student",
          }
        );
        this.setOptionsSchedule(this.itemsTeacher);

        this.itemsIngress.push(
          {
            title: "Ingresos",
            icon: "mdi-currency-usd",
            name: "ingress",
          },
          {
            title: "Egresos",
            icon: "mdi-cash-register",
            name: "egress",
          }
        );
        this.setOptionsIngress(this.itemsIngress);

        this.itemsAdmin.push(
          {
            title: "Departamentos",
            icon: "mdi-view-dashboard",
            name: "/departament",
          },
          {
            title: "Sucursales",
            icon: "mdi-store",
            name: "/branch-office/" + this.idDepartament,
          },
          {
            title: "Usuarios",
            icon: "mdi-account",
            name: "/user",
          },
          {
            title: "Roles",
            icon: "mdi-account-details",
            name: "/role",
          },
          {
            title: "Turnos",
            icon: "mdi-format-list-bulleted-square",
            name: "/turn",
          },
          {
            title: "Tipo de Pago",
            icon: "mdi-format-list-numbered",
            name: "/payment-type",
          },
          {
            title: "Materias",
            icon: "mdi-book-open",
            name: "/subject",
          },
          {
            title: "Nivel de Clase",
            icon: "mdi-format-list-checks",
            name: "/lesson-level",
          },
          {
            title: "Categoria de Pago",
            icon: "mdi-cash-multiple",
            name: "/payment-category",
          },
          {
            title: "Tipo de Ingreso",
            icon: "mdi-cash-multiple",
            name: "/ingress-type",
          }
        );
        this.setOptionsAdmin(this.itemsAdmin);

        this.itemsReport.push(
          {
            title: "Reporte de Ingreso Individual",
            icon: "mdi-file-account",
            name: "report-cashier",
          },
          {
            title: "Reporte de Ingreso General",
            icon: "mdi-text-box",
            name: "report-general",
          },
          {
            title: "Reporte de Pago de Mensualidades",
            icon: "mdi-receipt-text-check",
            name: "report-monthly-payment",
          },
          /*{
                        title: 'Reporte Lista de Estudiantes',
                        icon: 'mdi-clipboard-list',
                        name: 'report-student-list',
                    },*/
          {
            title: "Reporte Pago de Horas Individuales",
            icon: "mdi-human-male-board",
            name: "report-individual-payment",
          }
        );
        this.setOptionsReport(this.itemsReport);

        this.itemsGrade.push(
          {
            title: "Calificaciones",
            icon: "mdi-school",
            name: "student-grade",
          },
          {
            title: "Asistencia",
            icon: "mdi-account-school",
            name: "student-attendance",
          }
        );
        this.setOptionsStudentGrade(this.itemsGrade);

        this.itemsCredential.push({
          title: "Credenciales",
          icon: "mdi-card-account-details",
          name: "student-credential",
        });
        this.setOptionsStudentCredential(this.itemsCredential);
      } else if (this.user.idRol == 2) {
        this.items.push(
          {
            title: "Semestre",
            icon: "mdi-calendar-text",
            name: "semester",
          },
          {
            title: "Registrar Estudiante",
            icon: "mdi-account-box",
            name: "student",
          },
          {
            title: "Mensualidad",
            icon: "mdi-cash",
            name: "monthly-pay-student",
          }
        );
        this.setOptionsGroup(this.items);
        this.itemsTeacher.push(
          {
            title: "Profesores",
            icon: "mdi-account-tie",
            name: "teacher",
          },
          {
            title: "Horas Individuales",
            icon: "mdi-cash-clock",
            name: "individual-hours-student",
          }
        );
        this.setOptionsSchedule(this.itemsTeacher);

        this.itemsIngress.push(
          {
            title: "Ingresos",
            icon: "mdi-currency-usd",
            name: "ingress",
          },
          {
            title: "Egresos",
            icon: "mdi-cash-register",
            name: "egress",
          }
        );
        this.setOptionsIngress(this.itemsIngress);

        this.itemsReport.push(
          {
            title: "Reporte de Ingreso Individual",
            icon: "mdi-file-account",
            name: "report-cashier",
          },
          {
            title: "Reporte de Pago de Mensualidades",
            icon: "mdi-receipt-text-check",
            name: "report-monthly-payment",
          }
          /*{
                        title: 'Reporte Lista de Estudiantes',
                        icon: 'mdi-clipboard-list',
                        name: 'report-student-list',
                    }*/
        );
        this.setOptionsReport(this.itemsReport);

        this.itemsGrade.push({
          title: "Calificaciones",
          icon: "mdi-school",
          name: "student-grade",
        });
        this.setOptionsStudentGrade(this.itemsGrade);
      } else if (this.user.idRol > 2 && this.user.idRol < 6) {
        this.itemsTeacher.push(
          {
            title: "Bloqueo de clases",
            icon: "mdi-calendar-lock",
            name: "subject-schedule",
          },
          {
            title: "Calificaciones",
            icon: "mdi-checkbox-marked-circle-outline",
            name: "grade",
          },
          {
            title: "Asistencia",
            icon: "mdi-account-check",
            name: "attendance",
          }
        );
        this.setOptionsSchedule(this.itemsTeacher);
      }
    },
  },
};
</script>

<style>
</style>
<template>
  <v-layout>
    <v-container>
      <v-breadcrumbs class="pl-0" :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-toolbar flat>
        <v-toolbar-title>Reporte de Ingreso General</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="
                searchIndividualReport.length > 0 ||
                searchGroupReport.length > 0 ||
                searchEgressReport.length > 0 ||
                searchIngressReport.length > 0
              "
              class="ml-3 mr-2"
              color="primary"
              fab
              depressed
              small
              dark
              v-bind="attrs"
              v-on="on"
              @click="print"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </template>
          <span>Imprimir Reporte</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="
                searchIndividualReport.length > 0 ||
                searchGroupReport.length > 0 ||
                searchEgressReport.length > 0 ||
                searchIngressReport.length > 0
              "
              class="ml-3 mr-2"
              color="#1D6F42"
              fab
              depressed
              small
              dark
              v-bind="attrs"
              v-on="on"
              @click="downloadXlsx"
            >
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </template>
          <span>Descargar Excel</span>
        </v-tooltip>
      </v-toolbar>
      <v-card outlined tile>
        <v-card-text class="mt-1">
          <p class="font-weight-bold text-body-1" style="color: #1486bd">
            Buscar Reporte
          </p>
          <v-form ref="formSearchReport">
            <v-row>
              <v-col cols="12" sm="2" md="2" class="py-0">
                <p class="mb-2">Dia</p>
                <v-menu
                  ref="menuDay"
                  v-model="menuDay"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formSearchReport.date"
                      placeholder="Dia"
                      readonly
                      single-line
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formSearchReport.date"
                    no-title
                    first-day-of-week="1"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substring(0, 10)
                    "
                    min="2010-01-01"
                    @change="saveDay"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-0">
                <p class="mb-2">Fecha Inicial</p>
                <v-menu
                  ref="menuStart"
                  v-model="menuStart"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formSearchReport.startDate"
                      placeholder="Fecha Inicial"
                      readonly
                      single-line
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formSearchReport.startDate"
                    no-title
                    first-day-of-week="1"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substring(0, 10)
                    "
                    min="2010-01-01"
                    @change="saveStart"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-0">
                <p class="mb-2">Fecha Final</p>
                <v-menu
                  ref="menuEnd"
                  v-model="menuEnd"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formSearchReport.endDate"
                      placeholder="Fecha Final"
                      readonly
                      single-line
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formSearchReport.endDate"
                    no-title
                    first-day-of-week="1"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substring(0, 10)
                    "
                    min="2010-01-01"
                    @change="saveEnd"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-0">
                <p class="mb-2">Tipo de Reporte</p>
                <v-select
                  v-model="formSearchReport.idReportType"
                  :items="reportTypes"
                  item-text="type"
                  item-value="idReportType"
                  label="Seleccione"
                  persistent-hint
                  dense
                  outlined
                  hide-details="true"
                  single-line
                  @change="clearResult"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="warning" elevation="0" @click="cleanFormSearchReport">
            <span class="text-capitalize text-body">Limpiar</span>
          </v-btn>
          <v-btn
            class="ml-2"
            dark
            color="#1486bd"
            elevation="0"
            @click="searchReportByParams"
          >
            <span class="text-capitalize text-body">Buscar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
      <template v-if="loadingSearchIndividualReport">
        <v-container class="d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-container>
      </template>
      <div v-show="searchIndividualReport.length > 0">
        <p class="font-weight-bold text-h6 mt-2 mb-2">Ingresos Individuales</p>
        <v-data-table
          :headers="headersIndividualIngress"
          :items="searchIndividualReport"
          :items-per-page="-1"
          class="elevation-1 font-weight-light caption"
          hide-default-footer
          fixed-header
        >
        </v-data-table>
        <p class="my-2 font-weight-bold text-right">
          Total Bs. {{ totalIndividualIngress.toFixed(2) }}
        </p>
      </div>
      <div v-show="searchGroupReport.length > 0">
        <p class="font-weight-bold text-h6 mt-0 mb-2">Ingresos Grupales</p>
        <v-data-table
          :headers="headersIndividualIngress"
          :items="searchGroupReport"
          :items-per-page="-1"
          class="elevation-1 font-weight-light caption"
          hide-default-footer
          fixed-header
        >
        </v-data-table>
        <p class="my-2 font-weight-bold text-right">
          Total Bs. {{ totalGroupIngress.toFixed(2) }}
        </p>
      </div>
      <div v-show="searchIngressReport.length > 0">
        <p class="font-weight-bold text-h6 mt-0 mb-2">
          Ingresos por Venta de Libros, Practicas y Otros
        </p>
        <v-data-table
          :headers="headersIngress"
          :items="searchIngressReport"
          :items-per-page="-1"
          class="elevation-1 font-weight-light caption"
          hide-default-footer
          fixed-header
        >
        </v-data-table>
        <p class="my-2 font-weight-bold text-right">
          Total Bs. {{ totalIngress.toFixed(2) }}
        </p>
      </div>
      <div v-show="searchEgressReport.length > 0">
        <p class="font-weight-bold text-h6 mt-0 mb-2">Egresos</p>
        <v-data-table
          :headers="headersEgress"
          :items="searchEgressReport"
          :items-per-page="-1"
          class="elevation-1 font-weight-light caption"
          hide-default-footer
          fixed-header
        >
        </v-data-table>
        <p class="my-2 font-weight-bold text-right">
          Total Bs. {{ totalEgress.toFixed(2) }}
        </p>
      </div>
      <v-snackbar v-model="snackbar" :color="colorSnackbar" text>
        <span class="text-center">{{ textSnackbar }}</span>
      </v-snackbar>
      <ReportIngress
        v-show="false"
        ref="reportIngress"
        @downloadStop="dialogDownloadStop"
        :user="user"
        :individualIngress="searchIndividualReport"
        :groupIngress="searchGroupReport"
        :ingress="searchIngressReport"
        :egress="searchEgressReport"
        :resume="totalResume"
        :departament="nameDepartament"
        :type="1"
        :details="detailsComponent"
      />
      <DialogDownload :dialog="dialogDownload" />
    </v-container>
  </v-layout>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ReportIngress from "@/components/report/report-ingress-component.vue";
import DialogDownload from "@/components/dialog/dialog-download-component.vue";
import * as XLSX from "xlsx";
export default {
  components: {
    ReportIngress,
    DialogDownload,
  },
  data() {
    return {
      //Individual Report
      headersIndividualIngress: [
        {
          text: "Nro",
          value: "nro",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Fecha",
          value: "fechaPago",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Recibo",
          value: "codigo",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Tipo",
          value: "tipo",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Detalle",
          value: "detalle",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Total",
          value: "total",
          align: "right",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Descuento",
          value: "descuento",
          align: "right",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Por cobrar",
          value: "saldo",
          align: "right",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Ingreso",
          value: "subTotal",
          align: "right",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
      ],
      formSearchReport: {
        date: "",
        startDate: "",
        endDate: "",
        idUser: "",
        idReportType: 0,
      },
      userRules: [(value) => !!value || "Seleccione un Cajero"],
      reportTypes: [
        {
          idReportType: 0,
          type: "Reporte General",
        },
        {
          idReportType: 1,
          type: "Ingresos Individuales",
        },
        {
          idReportType: 2,
          type: "Ingresos Grupales",
        },
        {
          idReportType: 3,
          type: "Ingresos Varios",
        },
        {
          idReportType: 4,
          type: "Egresos",
        },
      ],
      activePicker: null,
      menuStart: null,
      menuEnd: null,
      menuDay: null,
      totalIndividualIngress: 0,
      cashiers: [],
      loadingSearchIndividualReport: false,
      searchIndividualReport: [],
      snackbar: false,
      colorSnackbar: "",
      textSnackbar: "",
      items: [
        {
          text: "Reporte General",
          disabled: false,
          href: "/report-general",
        },
      ],
      //Group Report
      loadingSearchGroupReport: false,
      searchGroupReport: [],
      totalGroupIngress: 0,
      //Ingress Report
      headersIngress: [
        {
          text: "Nro",
          value: "nro",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Fecha",
          value: "fechaPago",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Recibo",
          value: "codigo",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Tipo",
          value: "tipo",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Nombre",
          value: "nombre",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Detalle",
          value: "detalle",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Ingreso",
          value: "total",
          align: "right",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
      ],
      searchIngressReport: [],
      totalIngress: 0,
      //Egress Report
      headersEgress: [
        {
          text: "Nro",
          value: "nro",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Fecha",
          value: "fechaRegistro",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Recibo",
          value: "codigo",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Nombre",
          value: "nombre",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Detalle",
          value: "detalle",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Egreso",
          value: "total",
          align: "right",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
      ],
      searchEgressReport: [],
      totalEgress: 0,
      totalResume: {},
      nameDepartament: "",
      detailsComponent: {},
      dialogDownload: false,
    };
  },
  computed: {
    ...mapState(["idDepartament", "user"]),
  },
  watch: {
    menuStart(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuEnd(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  created() {
    this.user.idRol == 1
      ? this.selectDepartamentById()
      : (this.nameDepartament = this.user.departamento);
  },
  methods: {
    permittedDate(date) {
      const dateConst = new Date(date);
      return dateConst.getDay() !== 6;
    },
    dialogDownloadStop() {
      this.dialogDownload = false;
    },
    //export excel
    downloadXlsx() {
      this.dialogDownload = true;
      let wb = XLSX.utils.book_new();
      if (this.searchIndividualReport.length > 0) {
        const data = XLSX.utils.json_to_sheet(this.searchIndividualReport, {
          header: [
            "nro",
            "fechaPago",
            "codigo",
            "tipo",
            "detalle",
            "total",
            "descuento",
            "saldo",
            "subTotal",
          ],
        });
        data["A1"].v = "Nro";
        data["B1"].v = "Fecha";
        data["C1"].v = "Recibo";
        data["D1"].v = "Tipo";
        data["E1"].v = "Detalle";
        data["F1"].v = "Total";
        data["G1"].v = "Descuento";
        data["H1"].v = "Por Cobrar";
        data["I1"].v = "Ingreso";
        XLSX.utils.book_append_sheet(wb, data, "Ingresos Individuales");
      }

      if (this.searchGroupReport.length > 0) {
        const data = XLSX.utils.json_to_sheet(this.searchGroupReport, {
          header: [
            "nro",
            "fechaPago",
            "codigo",
            "tipo",
            "detalle",
            "total",
            "descuento",
            "saldo",
            "subTotal",
          ],
        });
        data["A1"].v = "Nro";
        data["B1"].v = "Fecha";
        data["C1"].v = "Recibo";
        data["D1"].v = "Tipo";
        data["E1"].v = "Detalle";
        data["F1"].v = "Total";
        data["G1"].v = "Descuento";
        data["H1"].v = "Por Cobrar";
        data["I1"].v = "Ingreso";
        XLSX.utils.book_append_sheet(wb, data, "Ingresos Grupales");
      }

      if (this.searchIngressReport.length > 0) {
        const data = XLSX.utils.json_to_sheet(this.searchIngressReport, {
          header: [
            "nro",
            "fechaPago",
            "codigo",
            "tipo",
            "nombre",
            "detalle",
            "total",
          ],
        });
        data["A1"].v = "Nro";
        data["B1"].v = "Fecha";
        data["C1"].v = "Recibo";
        data["D1"].v = "Tipo";
        data["E1"].v = "Nombre";
        data["F1"].v = "Detalle";
        data["G1"].v = "Ingreso";
        XLSX.utils.book_append_sheet(wb, data, "Ingresos Otros");
      }

      if (this.searchEgressReport.length > 0) {
        const data = XLSX.utils.json_to_sheet(this.searchEgressReport, {
          header: [
            "nro",
            "fechaRegistro",
            "codigo",
            "nombre",
            "detalle",
            "total",
          ],
        });
        data["A1"].v = "Nro";
        data["B1"].v = "Fecha";
        data["C1"].v = "Recibo";
        data["D1"].v = "Nombre";
        data["E1"].v = "Detalle";
        data["F1"].v = "Egreso";
        XLSX.utils.book_append_sheet(wb, data, "Egresos");
      }

      XLSX.writeFile(wb, "ReporteIngresoIndividual.xlsx");

      this.dialogDownload = false;
    },
    //other module
    clearResult() {
      this.searchEgressReport = [];
      this.searchGroupReport = [];
      this.searchIndividualReport = [];
      this.searchIngressReport = [];
      this.totalEgress = 0;
      this.totalGroupIngress = 0;
      this.totalIndividualIngress = 0;
      this.totalIngress = 0;
    },
    async selectDepartamentById() {
      try {
        let response = await axios.get(
          `departaments/selectDepartamentById/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.nameDepartament = response.data.nombreDepartamento;
        }
      } catch (error) {
        console.log(error);
      }
    },
    print() {
      this.dialogDownload = true;
      this.totalResume = {
        totalIndividualIngress: this.totalIndividualIngress,
        totalGroupIngress: this.totalGroupIngress,
        totalIngress: this.totalIngress,
        totalEgress: this.totalEgress,
        total:
          this.totalIndividualIngress +
          this.totalGroupIngress +
          this.totalIngress,
      };
      this.$refs.reportIngress.generatePdf();
    },
    //Egress Report
    async selectSearchEgressReport(url) {
      try {
        let response = await axios.get(
          `reports/selectSearchEgressReport/${url}`
        );
        if (response.status == 200) {
          if (response.data.length <= 0) {
            this.notification("primary", "No se encontro egresos");
            return;
          }
          response.data.map((value, index) => {
            this.totalEgress = this.totalEgress + Number(value.total);
            value.fechaRegistro = this.formatDate(
              value.fechaRegistro.substring(0, 10)
            );
            this.searchEgressReport.push({
              nro: index + 1,
              fechaRegistro: value.fechaRegistro,
              codigo: value.codigo,
              nombre: value.nombre,
              detalle: value.detalle,
              total: value.total,
            });
          });
          this.totalResume = {
            ...this.totalResume,
            totalEgress: this.totalEgress,
          };
        }
      } catch (error) {
        console.log(error);
      }
    },
    //Ingress Report
    async selectSearchIngressReport(url) {
      try {
        let response = await axios.get(
          `reports/selectSearchIngressReport/${url}`
        );
        if (response.status == 200) {
          if (response.data.length <= 0) {
            this.notification("primary", "No se encontro ingresos");
            return;
          }
          response.data.map((value, index) => {
            this.totalIngress = this.totalIngress + Number(value.total);
            value.fechaPago = this.formatDate(value.fechaPago.substring(0, 10));
            this.searchIngressReport.push({
              nro: index + 1,
              fechaPago: value.fechaPago,
              codigo: "O-" + value.codigo,
              tipo: value.tipo,
              nombre: value.nombre,
              detalle: value.detalle,
              total: value.total,
            });
          });

          this.totalResume = {
            ...this.totalResume,
            totalIngress: this.totalIngress,
          };
        }
      } catch (error) {
        console.log(error);
      }
    },
    //Group Report
    async selectSearchGroupReport(url) {
      try {
        let response = await axios.get(
          `reports/selectSearchGroupReport/${url}`
        );
        if (response.status == 200) {
          if (response.data.length <= 0) {
            this.notification("primary", "No se encontro ingresos grupales");
            this.loadingSearchGroupReport = false;
            return;
          }
          response.data.map((value, index) => {
            this.totalGroupIngress =
              this.totalGroupIngress + Number(value.subTotal);
            value.fechaPago = this.formatDate(value.fechaPago.substring(0, 10));
            this.searchGroupReport.push({
              nro: index + 1,
              fechaPago: value.fechaPago,
              codigo: value.codigo,
              tipo: value.tipo,
              detalle: value.detalle,
              total: value.total,
              descuento: value.descuento,
              saldo: value.saldo,
              subTotal: value.subTotal,
            });
          });
          this.totalResume = {
            ...this.totalResume,
            totalGroupIngress: this.totalGroupIngress,
          };
          this.loadingSearchGroupReport = false;
        }
      } catch (error) {
        console.log(error);
        this.loadingSearchGroupReport = false;
      }
    },
    //Individual Report
    async selectSearchIndividualReport(url) {
      try {
        let response = await axios.get(
          `reports/selectSearchIndividualReport/${url}`
        );
        if (response.status == 200) {
          if (response.data.length <= 0) {
            this.notification(
              "primary",
              "No se encontro ingresos individuales"
            );
            this.loadingSearchIndividualReport = false;
            return;
          }
          response.data.map((value, index) => {
            this.totalIndividualIngress =
              this.totalIndividualIngress + Number(value.subTotal);
            value.fechaPago = this.formatDate(value.fechaPago.substring(0, 10));
            this.searchIndividualReport.push({
              nro: index + 1,
              fechaPago: value.fechaPago,
              codigo: value.codigo,
              tipo: value.tipo,
              detalle: value.detalle,
              total: value.total,
              descuento: value.descuento,
              saldo: value.saldo,
              subTotal: value.subTotal,
            });
          });

          this.totalResume = {
            ...this.totalResume,
            totalIndividualIngress: this.totalIndividualIngress,
          };
          this.loadingSearchIndividualReport = false;
        }
      } catch (error) {
        console.log(error);
        this.loadingSearchIndividualReport = false;
      }
    },
    searchReportByParams() {
      if (this.$refs.formSearchReport.validate()) {
        let date =
          this.formSearchReport.date == "" ? null : this.formSearchReport.date;
        let startDate =
          this.formSearchReport.startDate == ""
            ? null
            : this.formSearchReport.startDate;
        let endDate =
          this.formSearchReport.endDate == ""
            ? null
            : this.formSearchReport.endDate;

        let url = `${this.idDepartament}/null/${date}/${startDate}/${endDate}`;

        this.searchIngressReport = [];
        this.totalIngress = 0;
        this.searchIndividualReport = [];
        this.totalIndividualIngress = 0;
        this.searchGroupReport = [];
        this.totalGroupIngress = 0;
        this.searchEgressReport = [];
        this.totalEgress = 0;
        this.totalResume = {};

        if (this.formSearchReport.idReportType == 0) {
          this.loadingSearchIndividualReport = true;
          this.selectSearchIndividualReport(url);
          this.selectSearchGroupReport(url);
          this.selectSearchIngressReport(url);
          this.selectSearchEgressReport(url);
        } else if (this.formSearchReport.idReportType == 1) {
          this.loadingSearchIndividualReport = true;
          this.selectSearchIndividualReport(url);
        } else if (this.formSearchReport.idReportType == 2) {
          this.loadingSearchGroupReport = true;
          this.selectSearchGroupReport(url);
        } else if (this.formSearchReport.idReportType == 3) {
          this.selectSearchIngressReport(url);
        } else if (this.formSearchReport.idReportType == 4) {
          this.lo;
          this.selectSearchEgressReport(url);
        }

        this.totalResume = {
          totalIndividualIngress: this.totalIndividualIngress,
          totalGroupIngress: this.totalGroupIngress,
          totalIngress: this.totalIngress,
          totalEgress: this.totalEgress,
          total:
            this.totalIndividualIngress +
            this.totalGroupIngress +
            this.totalIngress,
        };
      }
    },
    saveStart(date) {
      this.$refs.menuStart.save(date);
      this.formSearchReport.date = "";
      delete this.detailsComponent.endDate;
      delete this.detailsComponent.startDate;
      this.detailsComponent = {
        ...this.detailsComponent,
        startDate: date,
      };
    },
    saveEnd(date) {
      this.$refs.menuEnd.save(date);
      this.formSearchReport.date = "";
      delete this.detailsComponent.endDate;
      this.detailsComponent = {
        ...this.detailsComponent,
        endDate: date,
      };
    },
    saveDay(date) {
      this.$refs.menuDay.save(date);
      this.formSearchReport.startDate = "";
      this.formSearchReport.endDate = "";
      delete this.detailsComponent.endDate;
      delete this.detailsComponent.startDate;
      this.detailsComponent = {
        ...this.detailsComponent,
        startDate: date,
      };
    },
    userFilter(item, queryText) {
      const textOne = item.nombres.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    cleanFormSearchReport() {
      this.formSearchReport.date = "";
      this.formSearchReport.endDate = "";
      this.formSearchReport.idUser = "";
      this.formSearchReport.startDate = "";
      this.formSearchReport.idReportType = 0;
      this.totalIndividualIngress = 0;
      this.totalGroupIngress = 0;
      this.totalIngress = 0;
      this.totalEgress = 0;
      this.searchEgressReport = [];
      this.searchGroupReport = [];
      this.searchIndividualReport = [];
      this.searchIngressReport = [];
    },

    notification(type, text) {
      this.snackbar = true;
      this.colorSnackbar = type;
      this.textSnackbar = text;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style>
.td-click td {
  cursor: pointer;
}
</style>
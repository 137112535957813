<template>
  <v-layout>
    <v-container>
      <v-breadcrumbs class="pl-0" :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-card outlined>
        <v-card-text>
          <p class="text-h6 mb-0">Asistencia</p>
          <v-container>
            <v-form ref="formGradeSearch">
              <v-row class="d-flex flex-row">
                <v-col cols="12" sm="3" md="3" class="py-0">
                  <p class="mb-2">Semestre</p>
                  <v-autocomplete
                    v-model="formGroup.idSemester"
                    :items="semesters"
                    :filter="semesterFilter"
                    outlined
                    dense
                    item-text="nombreSemestre"
                    item-value="idSemestre"
                    label="Seleccione"
                    single-line
                    @change="findGroup"
                    :rules="semesterRules"
                    no-data-text="No hay semestres disponibles"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-0">
                  <p class="mb-2">Grupo</p>
                  <v-autocomplete
                    v-model="formGroup.idGroup"
                    :items="groups"
                    :filter="groupFilter"
                    outlined
                    dense
                    item-text="nombreGrupo"
                    item-value="idGrupo"
                    label="Seleccione"
                    single-line
                    :rules="groupRules"
                    @change="changeGroup"
                    no-data-text="No hay grupos disponibles"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-0" align-self="center">
                  <v-btn
                    color="#1486bd"
                    dark
                    elevation="0"
                    :loading="loading"
                    @click="findStudents"
                  >
                    <span class="text-capitalize text-body">Buscar Grupo</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
      <v-container v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-container>
      <template v-if="students.length > 0">
        <v-data-table
          :search="search"
          :headers="headers"
          :items="students"
          item-key="idGrupoUsuario"
          class="elevation-1 font-weight-light caption"
          fixed-header
        >
          <template v-slot:[`item.estado`]="{ item }">
            {{ item.estado ? "Activo" : "Inactivo" }}
          </template>
          <template v-slot:[`item.accion`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="grey"
                  class="mr-2"
                  @click.stop="viewGrades(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>
              </template>
              <span>Ver asistencia</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
      <v-snackbar v-model="snackbar" :color="colorSnackbar" text>
        <span class="text-center">{{ textSnackbar }}</span>
      </v-snackbar>
      <v-dialog v-model="dialogGrades" max-width="750">
        <v-card>
          <v-card-title style="color: #1874bf"
            >Asistencia del estudiante
            <p class="black--text mb-0 ml-2">
              {{ `${studentData.nombres} ${studentData.apellidos}` }}
            </p>
            <v-spacer />
            <!--v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="studentGrade.length > 0"
                  class="ml-3 mr-2"
                  color="primary"
                  fab
                  depressed
                  small
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="printGrade"
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Imprimir Boletin</span>
            </v-tooltip-->
          </v-card-title>
          <v-card-text>
            <v-container v-if="loadingGrades" class="d-flex justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-container>
            <div v-show="!loadingGrades">
              <v-col class="pa-0">
                <v-sheet height="64">
                  <v-toolbar flat>
                    <v-btn
                      outlined
                      class="mr-4"
                      color="grey darken-2"
                      @click="setToday"
                    >
                      Hoy
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="prev">
                      <v-icon small> mdi-chevron-left </v-icon>
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="next">
                      <v-icon small> mdi-chevron-right </v-icon>
                    </v-btn>
                    <v-toolbar-title> {{ titleCalendar }} </v-toolbar-title>
                  </v-toolbar>
                </v-sheet>
                <v-sheet height="600">
                  <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :events="events"
                    type="month"
                    :weekdays="[1, 2, 3, 4, 5, 6]"
                    @change="changeCalendar"
                  ></v-calendar>
                </v-sheet>
              </v-col>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <DialogDownload :dialog="dialogDownload" />
      <!--ReportStudentGrade
        ref="reportGrade"
        @downloadStop="dialogDownloadStop"
        :group="group"
        :student="studentData"
        :grades="studentGrade"
        :assistance="attendanceStudent"
      /-->
    </v-container>
  </v-layout>
</template>

<script>
import axios from "axios";
//import ReportStudentGrade from "@/components/report/report-student-grade-component.vue";
import DialogDownload from "@/components/dialog/dialog-download-component.vue";
import { onMounted, ref, watch } from "vue";
import { useStore } from "@/store";
export default {
  components: {
    //ReportStudentGrade,
    DialogDownload,
  },
  setup() {
    const store = useStore();

    const dialog = ref(false);
    const dialogDelete = ref(false);
    const search = ref("");
    const group = ref({});
    const dialogGrades = ref(false);
    const studentGrade = ref([]);
    const headersGrade = ref([]);
    const loadingGrades = ref(false);
    const dialogDownload = ref(false);
    const studentData = ref({});
    const headers = ref([
      {
        text: "Ci",
        value: "ci",
        align: "left",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
      {
        text: "Apellidos",
        value: "apellidos",
        align: "left",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
      {
        text: "Nombres",
        value: "nombres",
        align: "left",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
      {
        text: "Telefono",
        value: "telefono",
        align: "center",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
      {
        text: "Acciones",
        value: "accion",
        align: "center",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      },
    ]);
    const students = ref([]);
    const semesters = ref([]);
    const groups = ref([]);
    const formGroup = ref({
      idGroup: "",
      idSemester: "",
      idSubject: "",
      startDate: null,
      endDate: null,
    });
    const formGradeSearch = ref(null);
    const menuStart = ref(false);
    const menuEnd = ref(false);
    const activePicker = ref(null);
    const semesterRules = ref([(value) => !!value || "Seleccione un semestre"]);
    const groupRules = ref([(value) => !!value || "Seleccione un grupo"]);
    const loading = ref(false);
    const loadingData = ref(false);
    const snackbar = ref(false);
    const colorSnackbar = ref("");
    const textSnackbar = ref("");
    const editedItem = ref({});
    const items = ref([
      {
        text: "Asistencia",
        disabled: true,
        href: "/student-attendance",
      },
    ]);
    const turns = ref([]);
    const formGrade = ref({
      idGroup: "",
      idStudent: "",
      idTeacher: "",
      idSubject: "",
      dateIngress: "",
      partial: "",
      grade: "",
    });

    const drills = ref(0);
    const attendanceStudent = ref({});
    //calendar
    const focus = ref("");
    const events = ref([]);
    const titleCalendar = ref("");
    const calendar = ref(null);
    const startDate = ref(null);
    const endDate = ref(null);
    const isOpened = ref(false);
    const now = ref(new Date());

    //API methods
    const selectAttendanceStudent = async (idGroup, idUser) => {
      try {
        let response = await axios.get(
          `attendances/selectAttendanceStudent/${idGroup}/${idUser}`
        );
        if (response.status == 200) {
          attendanceStudent.value = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const selectCourseStudents = async (idGroup) => {
      let url = `courses/selectCourseStudentsByIdGroup/${idGroup}/1`;
      loading.value = true;
      try {
        let response = await axios.get(url);
        if (response.status == 200) {
          students.value = response.data;
          loading.value = false;
        }
      } catch (error) {
        console.log(error);
        loading.value = false;
      }
    };

    const selectAssistancesByDate = async (year, month) => {
      try {
        let response = await axios.get("students/selectAssistancesByDate", {
          params: {
            idGroup: formGroup.value.idGroup,
            idStudent: studentData.value.idUsuario,
            year: year,
            month: month,
          },
        });
        if (response.status == 200) {
          loadingGrades.value = false;
          isOpened.value = true;
          events.value = response.data.assistances;
          events.value.map((value) => {
            value.start = value.start.substring(0, 10);
            value.end = value.end.substring(0, 10);
          });
        }
      } catch (error) {
        console.log(error);
        loadingGrades.value = false;
      }
    };

    const selectGroupBySemester = async (idSemester) => {
      try {
        let response = await axios.get(
          `groupTeachers/selectGroupAttendanceByIdSemester/${store.state.idDepartament}/${idSemester}`
        );
        if (response.status == 200) {
          groups.value = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const selectSemesterTeacher = async () => {
      try {
        let response = await axios.get(
          `semesters/selectSemesterTeacher/${store.state.idDepartament}`
        );
        if (response.status == 200) {
          semesters.value = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
      selectSemesterTeacher();
    });

    //methods
    //calendar methods
    const changeCalendar = ({ start, end }) => {
      end;
      titleCalendar.value = calendar.value.title.toUpperCase();
      selectAssistancesByDate(start.year, start.month);
      //console.log($refs.calendar);
    };

    const setToday = () => {
      focus.value = "";
    };

    const prev = () => {
      calendar.value.prev();
    };

    const next = () => {
      calendar.value.next();
    };

    //
    const saveStart = (date) => {
      startDate.value.save(date);
      /*formSearchReport.startDate = '';
            formSearchReport.endDate = '';
            delete detailsComponent.endDate;
            delete detailsComponent.startDate;
            detailsComponent = {
                ...detailsComponent,
                startDate: date    
            }*/
    };

    const saveEnd = (date) => {
      endDate.value.save(date);
      /*      formSearchReport.startDate = '';
            formSearchReport.endDate = '';
            delete detailsComponent.endDate;
            delete detailsComponent.startDate;
            detailsComponent = {
                ...detailsComponent,
                startDate: date    
            }*/
    };

    const changeGroup = (item) => {
      students.value = [];

      const obj = groups.value.find((group) => group.idGrupo === item);
      drills.value = obj.cuotas;
      group.value.cuotas = obj.cuotas;
      group.value.nombreGrupo = obj.nombreGrupo;
    };

    const dialogDownloadStop = () => {
      dialogDownload.value = false;
    };

    /*const printGrade = () => {
      dialogDownload.value = true;
      $refs.reportGrade.generatePdf();
    };*/

    const closeGrade = () => {
      dialogGrades.value = false;
      events.value = [];
      attendanceStudent.value = {};
      focus.value = "";
    };

    const viewGrades = (item) => {
      dialogGrades.value = true;

      studentData.value = {};
      studentData.value = Object.assign({}, item);
      loadingGrades.value = true;
      if (isOpened.value) {
        selectAssistancesByDate(now.value.getFullYear(), now.value.getMonth());
      }
      selectAttendanceStudent(formGroup.value.idGroup, item.idUsuario);
    };

    const findStudents = async () => {
      const valid = await formGradeSearch.value.validate();
      if (valid) {
        students.value = [];
        selectCourseStudents(formGroup.value.idGroup);
      }
    };

    const groupFilter = (item, queryText) => {
      const textOne = item.nombreGrupo.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    };

    const findGroup = (item) => {
      groups.value = [];
      formGroup.value.idGroup = "";
      formGroup.value.idSubject = "";
      students.value = [];
      drills.value = 0;

      selectGroupBySemester(item);
    };

    const semesterFilter = (item, queryText) => {
      const textOne = item.nombreSemestre.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    };

    /*const notification = (type, text) => {
      snackbar.value = true;
      colorSnackbar.value = type;
      textSnackbar.value = text;
    };*/

    const formatDate = (date) => {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    };

    //co
    watch(dialogGrades, (val) => {
      val || closeGrade();
    });

    watch(calendar, (val) => {
      titleCalendar.value = val.title.toUpperCase();
    });

    return {
      //properties
      dialog,
      dialogDelete,
      search,
      group,
      dialogGrades,
      studentGrade,
      headersGrade,
      loadingGrades,
      dialogDownload,
      studentData,
      headers,
      students,
      semesters,
      groups,
      formGroup,
      menuStart,
      menuEnd,
      activePicker,
      semesterRules,
      groupRules,
      loading,
      loadingData,
      snackbar,
      colorSnackbar,
      textSnackbar,
      editedItem,
      items,
      turns,
      formGrade,
      drills,
      attendanceStudent,
      focus,
      events,
      titleCalendar,
      startDate,
      endDate,
      formGradeSearch,
      calendar,
      now,
      //methods
      changeCalendar,
      setToday,
      prev,
      next,
      saveStart,
      saveEnd,
      changeGroup,
      dialogDownloadStop,
      viewGrades,
      findStudents,
      groupFilter,
      findGroup,
      semesterFilter,
      formatDate,
    };
  },
};
</script>

<style scoped>
</style>
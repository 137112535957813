<template>
  <v-layout align-start>
    <v-container>
      <v-breadcrumbs class="pl-0" :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-card outlined tile class="pb-2">
        <v-card-text class="pb-0">
          <v-row class="ml-1">
            <v-col cols="12" sm="6" md="6">
              <p class="font-weight-medium text-h6 mb-0" style="color: #1e88e5">
                Informacion del Profesor
              </p>
              <p
                style="color: #4c4c4c"
                class="mb-1 subtitle-2 font-weight-medium"
              >
                Nombres: <span class="caption">{{ teacher.nombres }}</span>
              </p>
              <p
                style="color: #4c4c4c"
                class="mb-1 subtitle-2 font-weight-medium"
              >
                Apellidos: <span class="caption">{{ teacher.apellidos }}</span>
              </p>
              <p
                style="color: #4c4c4c"
                class="mb-1 subtitle-2 font-weight-medium"
              >
                CI: <span class="caption">{{ teacher.ci }}</span>
              </p>
              <p
                style="color: #4c4c4c"
                class="mb-1 subtitle-2 font-weight-medium"
              >
                Tipo: <span class="caption">{{ teacher.nombreRol }}</span>
              </p>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <div v-show="Object.keys(student).length != 0">
                <p
                  class="font-weight-medium text-h6 mb-0"
                  style="color: #1e88e5"
                >
                  Informacion del Estudiante
                </p>
                <p
                  style="color: #4c4c4c"
                  class="mb-1 subtitle-2 font-weight-medium"
                >
                  Nombres y Apellidos:
                  <span class="caption">{{
                    student.nombres + " " + student.apellidos
                  }}</span>
                </p>
                <p
                  style="color: #4c4c4c"
                  class="mb-1 subtitle-2 font-weight-medium"
                >
                  CI: <span class="caption">{{ student.ci }}</span>
                </p>
                <p
                  style="color: #4c4c4c"
                  class="mb-1 subtitle-2 font-weight-medium"
                >
                  Nivel: <span class="caption">{{ student.sigla }}</span>
                </p>
                <p
                  style="color: #4c4c4c"
                  class="mb-1 subtitle-2 font-weight-medium"
                >
                  Nro Alumnos:
                  <span class="caption">{{ student.numberStudents }}</span>
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row class="fill-height mt-1">
        <v-col>
          <v-sheet class="d-none d-sm-flex flex-column" height="116">
            <v-card tile elevation="0">
              <v-card-text>
                <v-row align="center">
                  <v-col class="py-2" cols="12" sm="4" md="4">
                    <v-container class="pa-0 d-flex flex-row align-center">
                      <v-btn
                        outlined
                        class="text-capitalize ml-1"
                        color="grey darken-2"
                        small
                        @click="dialogStudent = true"
                      >
                        Seleccionar Estudiante
                      </v-btn>
                      <div v-if="Object.keys(student).length != 0">
                        <v-btn
                          class="text-capitalize ml-1"
                          color="red"
                          dark
                          small
                          @click="removeStudent()"
                        >
                          Quitar Estudiante
                        </v-btn>
                      </div>
                    </v-container>
                  </v-col>
                  <!--v-col class="d-flex align-center justify-center py-2" cols="12" sm="6" md="6">
                                        
                                    </v-col-->
                  <v-col
                    v-if="
                      Object.keys(student).length != 0 && payments.length > 0
                    "
                    class="py-2"
                    offset-sm="2"
                    offset-md="2"
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-container class="pa-0 d-flex flex-row justify-end">
                      <v-col align-self="center" class="pa-0" cols="2">
                        <p class="mb-0">Recibo Nro.</p>
                      </v-col>
                      <v-col class="pa-0" cols="3">
                        <v-select
                          v-model="receipt"
                          :items="payments"
                          item-text="codigo"
                          item-value="idPago"
                          label="Seleccione"
                          persistent-hint
                          return-object
                          dense
                          outlined
                          hide-details="true"
                          single-line
                          @blur="changeItemReceipt"
                          :rules="weekRules"
                        ></v-select>
                      </v-col>
                      <v-col class="pa-0" cols="1">
                        <v-btn
                          class="ml-3 mr-2"
                          color="primary"
                          fab
                          depressed
                          small
                          dark
                          @click="generateReceipt"
                        >
                          <v-icon>mdi-printer</v-icon>
                        </v-btn>
                      </v-col>
                    </v-container>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card tile outlined elevation="0">
              <v-card-text>
                <v-row>
                  <v-col class="py-2" cols="12" sm="4" md="4">
                    <v-container class="pa-0">
                      <v-btn
                        outlined
                        class="mr-4"
                        color="grey darken-2"
                        small
                        @click="setToday"
                      >
                        Hoy
                      </v-btn>
                      <v-btn fab text small color="grey darken-2" @click="prev">
                        <v-icon small> mdi-chevron-left </v-icon>
                      </v-btn>
                      <v-btn fab text small color="grey darken-2" @click="next">
                        <v-icon small> mdi-chevron-right </v-icon>
                      </v-btn>
                    </v-container>
                  </v-col>
                  <v-col
                    class="d-flex align-center justify-center py-2"
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <p class="mb-0 text-body-1 font-weight-bold">
                      {{ titleCalendar }}
                    </p>
                  </v-col>
                  <v-col
                    class="d-flex align-center justify-end py-2"
                    cols="4"
                    sm="4"
                    md="4"
                  >
                    <v-btn-toggle v-model="type" mandatory>
                      <v-btn class="text-capitalize" small value="month">
                        Mes
                      </v-btn>
                      <v-btn class="text-capitalize" small value="week">
                        Semana
                      </v-btn>
                      <v-btn class="text-capitalize" small value="day">
                        Dia
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-sheet>
          <v-sheet
            class="d-flex d-sm-none flex-column"
            :height="
              Object.keys(student).length != 0 && payments.length > 0
                ? 220
                : 165
            "
          >
            <v-card tile elevation="0">
              <v-card-text>
                <v-row align="center">
                  <v-col class="py-2" cols="12" sm="4" md="4">
                    <v-container class="pa-0 d-flex flex-row align-center">
                      <v-btn
                        outlined
                        class="text-capitalize ml-1"
                        color="grey darken-2"
                        small
                        @click="dialogStudent = true"
                      >
                        Seleccionar Estudiante
                      </v-btn>
                      <div v-if="Object.keys(student).length != 0">
                        <v-btn
                          class="text-capitalize ml-1"
                          color="red"
                          dark
                          small
                          @click="removeStudent()"
                        >
                          Quitar Estudiante
                        </v-btn>
                      </div>
                    </v-container>
                  </v-col>
                  <!--v-col class="d-flex align-center justify-center py-2" cols="12" sm="6" md="6">
                                        
                                    </v-col-->
                  <v-col
                    v-if="
                      Object.keys(student).length != 0 && payments.length > 0
                    "
                    class="py-2"
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-container class="pa-0 d-flex flex-row">
                      <v-col align-self="center" class="pa-0" cols="4">
                        <p class="mb-0">Recibo Nro.</p>
                      </v-col>
                      <v-col class="pa-0" cols="6">
                        <v-select
                          v-model="receipt"
                          :items="payments"
                          item-text="codigo"
                          item-value="idPago"
                          label="Seleccione"
                          persistent-hint
                          return-object
                          dense
                          outlined
                          hide-details="true"
                          single-line
                          @blur="changeItemReceipt"
                          :rules="weekRules"
                        ></v-select>
                      </v-col>
                      <v-col class="pa-0" cols="2">
                        <v-btn
                          class="ml-4 mr-2"
                          color="primary"
                          fab
                          depressed
                          small
                          dark
                          @click="generateReceipt"
                        >
                          <v-icon>mdi-printer</v-icon>
                        </v-btn>
                      </v-col>
                    </v-container>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card tile outlined elevation="0">
              <v-card-text>
                <v-row>
                  <v-container
                    class="d-flex flex-row justify-space-between pb-0"
                  >
                    <div class="d-flex flex-row align-center">
                      <v-btn
                        outlined
                        color="grey darken-2"
                        small
                        @click="setToday"
                      >
                        Hoy
                      </v-btn>
                    </div>
                    <div>
                      <v-btn fab text small color="grey darken-2" @click="prev">
                        <v-icon small> mdi-chevron-left </v-icon>
                      </v-btn>
                      <v-btn fab text small color="grey darken-2" @click="next">
                        <v-icon small> mdi-chevron-right </v-icon>
                      </v-btn>
                    </div>
                  </v-container>
                </v-row>
                <v-row>
                  <v-container class="d-flex flex-row justify-space-between">
                    <p class="mb-0 text-body-1 font-weight-bold">
                      {{ titleCalendar }}
                    </p>
                    <v-btn-toggle v-model="type" mandatory>
                      <v-btn class="text-capitalize" small value="month">
                        Mes
                      </v-btn>
                      <v-btn class="text-capitalize" small value="week">
                        Semana
                      </v-btn>
                      <v-btn class="text-capitalize" small value="day">
                        Dia
                      </v-btn>
                    </v-btn-toggle>
                  </v-container>
                </v-row>
              </v-card-text>
            </v-card>
          </v-sheet>
          <!--
                        @mousedown:event="startDrag"
                        @mousedown:time="startTime"
                        @mousemove:time="mouseMove"
                        @mouseup:time="endDrag"
                        @mouseleave.native="cancelDrag"
                    -->
          <v-sheet height="900">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :start="today"
              :type="type"
              :events="events"
              :event-color="getEventColor"
              :event-text-color="getEventTextColor"
              @change="getEvents"
              @mousedown:event="startDrag"
              @mousedown:time="startTime"
              @mousemove:time="mouseMove"
              @click:event="showEvent"
              @mouseup:time="endDrag"
              first-time="08:00"
              :short-intervals="false"
              interval-count="16"
              :weekdays="weekdays"
            >
            </v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :close-on-click="false"
              :activator="selectedElement"
              offset-y
            >
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar
                  :color="getEventColor(selectedEvent)"
                  :dark="getTextColor(selectedEvent)"
                  elevation="1"
                >
                  <v-toolbar-title
                    >{{ selectedEvent.originalName
                    }}<span v-show="selectedEvent.virtual">
                      (Virtual)</span
                    ></v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    v-if="selectedEvent.idClass != null"
                    @click="editEvent()"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="removeEvent()">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <div v-if="selectedEvent.idClass != null">
                    <v-menu bottom left offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list dense>
                        <v-list-item
                          class="px-0"
                          v-show="selectedEvent.type < 3 ? true : false"
                        >
                          <v-list-item-title>
                            <v-btn
                              class="px-4 block"
                              text
                              tile
                              @click="eventPayment"
                            >
                              <p
                                class="mb-0 text-medium text-capitalize text-left"
                              >
                                Pagar
                              </p>
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          class="px-0"
                          v-show="
                            selectedEvent.type > 1 && selectedEvent.type != 5
                          "
                        >
                          <v-list-item-title>
                            <v-btn
                              class="px-4 block"
                              text
                              tile
                              @click="eventViewReceipt"
                            >
                              <p
                                class="mb-0 text-medium text-capitalize text-left"
                              >
                                Ver Recibo
                              </p>
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          class="px-0"
                          v-show="selectedEvent.type > 2"
                        >
                          <v-list-item-title>
                            <v-btn
                              class="px-4 block"
                              text
                              tile
                              @click="eventChangeLesson"
                            >
                              <p
                                class="mb-0 text-medium text-capitalize text-left"
                              >
                                Cambiar Uno
                              </p>
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          class="px-0"
                          v-show="selectedEvent.type > 2"
                        >
                          <v-list-item-title>
                            <v-btn
                              class="px-4 block"
                              text
                              tile
                              @click="eventChangeAllLesson"
                            >
                              <p
                                class="mb-0 text-medium text-capitalize text-left"
                              >
                                Cambiar Todo
                              </p>
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                        <!--v-list-item-group
                                                    v-model="eventSelected"
                                                    color="primary"
                                                    @change="eventMenu"
                                                >
                                                    <v-list-item v-show="selectedEvent.type < 3 ? true : false">
                                                        <v-list-item-title>Pagar</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item v-show="selectedEvent.type > 1 && selectedEvent.type != 5">
                                                        <v-list-item-title>Ver Recibo</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item v-show="selectedEvent.type > 2">
                                                        <v-list-item-title>Cambiar Uno</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item v-show="selectedEvent.type > 2">
                                                        <v-list-item-title>Cambiar Todo</v-list-item-title>
                                                    </v-list-item>
                                                </v-list-item-group-->
                      </v-list>
                    </v-menu>
                  </div>
                </v-toolbar>
                <v-card-text>
                  <div v-if="selectedEvent.idClass != null">
                    <p
                      style="color: #4c4c4c"
                      class="mb-1 subtitle-2 font-weight-medium"
                    >
                      Materia:
                      <span class="caption">{{ selectedEvent.subject }}</span>
                    </p>
                    <p
                      style="color: #4c4c4c"
                      class="mb-1 subtitle-2 font-weight-medium"
                    >
                      Estudiante:
                      <span class="caption">{{
                        selectedEvent.firstName + " " + selectedEvent.lastName
                      }}</span>
                    </p>
                    <p
                      style="color: #4c4c4c"
                      class="mb-1 subtitle-2 font-weight-medium"
                    >
                      CI: <span class="caption">{{ selectedEvent.ci }}</span>
                    </p>
                    <p
                      style="color: #4c4c4c"
                      class="mb-1 subtitle-2 font-weight-medium"
                    >
                      Telefono:
                      <span class="caption">{{ selectedEvent.phone }}</span>
                    </p>
                    <p
                      style="color: #4c4c4c"
                      class="mb-1 subtitle-2 font-weight-medium"
                    >
                      Nivel:
                      <span class="caption">{{ selectedEvent.nameLevel }}</span>
                    </p>
                    <p
                      style="color: #4c4c4c"
                      class="mb-1 subtitle-2 font-weight-medium"
                    >
                      Nro Alumnos:
                      <span class="caption">{{
                        selectedEvent.numberStudents
                      }}</span>
                    </p>
                    <p
                      v-show="
                        selectedEvent.description != null ||
                        selectedEvent.description != ''
                      "
                      style="color: #4c4c4c"
                      class="mb-1 subtitle-2 font-weight-medium"
                    >
                      Descripcion:
                      <span class="caption">{{
                        selectedEvent.description
                      }}</span>
                    </p>
                  </div>

                  <span v-else v-html="selectedEvent.name"></span>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    class="text-capitalize"
                    text
                    color="secondary"
                    @click="
                      selectedOpen = false;
                      clicked = false;
                      selectedItem = '';
                    "
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogGroup" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{
              "Agregar horario para " + nameActivities
            }}</span>
            <v-spacer></v-spacer>
            <v-switch v-model="activitiesSwitch" label="Actividades"></v-switch>
          </v-card-title>

          <v-card-text v-show="!activitiesSwitch">
            <v-container>
              <v-form ref="formGroup">
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Grupos</p>
                    <v-autocomplete
                      v-model="formGroup.idGroup"
                      :items="groups"
                      :filter="groupFilter"
                      outlined
                      dense
                      item-text="nombreGrupo"
                      item-value="idGrupo"
                      label="Grupo"
                      single-line
                      :rules="groupRules"
                      no-data-text="No hay grupos disponibles"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Bloquear</p>
                    <v-select
                      v-model="formGroup.weeks"
                      :items="weeks"
                      item-text="nameWeek"
                      item-value="idWeek"
                      label="Semana"
                      persistent-hint
                      dense
                      outlined
                      single-line
                      :rules="weekRules"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <p class="mb-2">Inicio</p>
                    <v-text-field
                      v-model="formGroup.timeStart"
                      outlined
                      dense
                      single-line
                      type="time"
                      :rules="timeStartRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <p class="mb-2">Fin</p>
                    <v-text-field
                      v-model="formGroup.timeEnd"
                      outlined
                      dense
                      single-line
                      type="time"
                      :rules="timeEndRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-text v-show="activitiesSwitch">
            <v-container>
              <v-form ref="formGroup">
                <v-row>
                  <v-col cols="12" sm="12" md="12" class="py-0">
                    <p class="mb-2">Bloquear</p>
                    <v-select
                      v-model="formGroup.weeks"
                      :items="weeksActivities"
                      item-text="nameWeek"
                      item-value="idWeek"
                      label="Semana"
                      persistent-hint
                      dense
                      outlined
                      single-line
                      :rules="weekRules"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <p class="mb-2">Inicio</p>
                    <v-text-field
                      v-model="formGroup.timeStart"
                      outlined
                      dense
                      single-line
                      type="time"
                      :rules="timeStartRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <p class="mb-2">Fin</p>
                    <v-text-field
                      v-model="formGroup.timeEnd"
                      outlined
                      dense
                      single-line
                      type="time"
                      :rules="timeEndRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeGroupSchedule"
              :disabled="loadingData"
            >
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
            <v-btn
              color="blue darken-1"
              dark
              :loading="loadingData"
              @click="saveGroupSchedule"
            >
              <span class="text-capitalize">Guardar Cambios</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogClass" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ "Agregar Horario" }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="formClass">
                <v-row>
                  <v-col cols="12" sm="8" md="8" class="py-0">
                    <p class="mb-2">Materia</p>
                    <v-select
                      v-model="formClass.idSubject"
                      :items="subjects"
                      item-text="nombreMateria"
                      item-value="idMateria"
                      label="Seleccione"
                      persistent-hint
                      dense
                      outlined
                      single-line
                      :rules="lessonRules"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    class="py-0 d-flex align-center"
                  >
                    <v-checkbox
                      v-model="formClass.virtual"
                      label="Clase virtual"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="py-0">
                    <p class="mb-2">Descripcion</p>
                    <v-text-field
                      v-model="formClass.description"
                      label="Descripcion"
                      persistent-hint
                      dense
                      outlined
                      single-line
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <p class="mb-2">Inicio</p>
                    <v-text-field
                      v-model="formClass.timeStart"
                      outlined
                      dense
                      single-line
                      type="time"
                      :rules="timeStartRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <p class="mb-2">Fin</p>
                    <v-text-field
                      v-model="formClass.timeEnd"
                      outlined
                      dense
                      single-line
                      type="time"
                      :rules="timeEndRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              :disabled="loadingData"
              @click="closeClass"
            >
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
            <v-btn
              color="blue darken-1"
              dark
              :loading="loadingData"
              @click.stop="saveClass"
            >
              <span class="text-capitalize">Guardar Cambios</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" :color="colorSnackbar" text>
        <span class="text-center">{{ textSnackbar }}</span>
      </v-snackbar>
      <v-dialog v-model="dialogStudent" max-width="700px" scrollable>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ "Agregar Estudiante" }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="formStudent">
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Nivel</p>
                    <v-select
                      v-model="formClass.idLevel"
                      :items="lessonLevels"
                      item-text="nombreNivel"
                      item-value="idNivel"
                      label="Nivel"
                      persistent-hint
                      dense
                      outlined
                      single-line
                      :rules="levelRules"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Nro Alumnos</p>
                    <v-select
                      v-model="formClass.numberStudents"
                      :items="numberStudents"
                      label="Nro Alumnos"
                      persistent-hint
                      dense
                      outlined
                      single-line
                      :rules="studentRules"
                    ></v-select>
                  </v-col>
                  <p class="font-weight-bold text-body-1">Buscar Estudiante</p>
                  <v-row>
                    <v-col cols="12" sm="3" md="3" class="py-0">
                      <p class="mb-2">Opciones</p>
                      <v-select
                        v-model="formSearchStudent.option"
                        :items="optionItems"
                        item-text="option"
                        item-value="idOption"
                        label="Seleccione una opcion"
                        persistent-hint
                        return-object
                        outlined
                        single-line
                        dense
                        @change="optionChange"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="5" md="5" class="py-0">
                      <p class="mb-2">{{ optionName }}</p>
                      <v-text-field
                        v-model="formSearchStudent.value"
                        class="pt-0"
                        :placeholder="optionName"
                        single-line
                        dense
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                      class="py-0"
                      align-self="center"
                    >
                      <div class="d-flex flex-row align-center">
                        <v-btn
                          class=""
                          dark
                          color="#1CA5DB"
                          elevation="0"
                          @click="searchStudentByParams"
                        >
                          <span class="text-capitalize text-body">Buscar</span>
                        </v-btn>
                        <v-btn
                          class="ml-2"
                          dark
                          color="primary"
                          elevation="0"
                          @click="dialogNewStudent = true"
                        >
                          <span class="text-capitalize text-body">Nuevo</span>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-col cols="12" sm="12" md="12" class="py-0">
                    <template v-if="loadingSearch">
                      <v-container class="d-flex justify-center">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-container>
                    </template>
                    <div v-show="searchStudents.length > 0">
                      <v-data-table
                        :headers="headersStudent"
                        :items="searchStudents"
                        :items-per-page="-1"
                        class="elevation-1 font-weight-light caption td-click"
                        @click:row="clickRowSearchStudent"
                        fixed-header
                        hide-default-footer
                      >
                      </v-data-table>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeStudentClass">
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogNewStudent" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ "Nuevo Estudiante" }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="formNewStudent">
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Nombres</p>
                    <v-text-field
                      v-model="formNewStudent.names"
                      class="pt-0"
                      label="Nombres"
                      placeholder="Nombres"
                      single-line
                      dense
                      outlined
                      :rules="nameRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Apellidos</p>
                    <v-text-field
                      v-model="formNewStudent.surnames"
                      class="pt-0"
                      label="Apellidos"
                      placeholder="Apellidos"
                      single-line
                      dense
                      outlined
                      :rules="surnameRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Ci</p>
                    <v-text-field
                      v-model="formNewStudent.ci"
                      class="pt-0"
                      label="Ci"
                      placeholder="Ci"
                      single-line
                      dense
                      outlined
                      :rules="ciRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Telefono</p>
                    <v-text-field
                      v-model="formNewStudent.phone"
                      class="pt-0"
                      label="Telefono"
                      placeholder="Telefono"
                      single-line
                      dense
                      outlined
                      :rules="phoneRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="py-0">
                    <p class="mb-2">Email</p>
                    <v-text-field
                      v-model="formNewStudent.email"
                      class="pt-0"
                      label="Email"
                      placeholder="Email"
                      single-line
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    class="py-0"
                    v-if="user.idRol == 1"
                  >
                    <p class="mb-2">Sucursal</p>
                    <v-select
                      v-model="formNewStudent.idBranchoffice"
                      :items="branchoffices"
                      item-text="nombreSucursal"
                      item-value="idSucursal"
                      label="Sucursal"
                      persistent-hint
                      dense
                      outlined
                      single-line
                      :rules="branchofficeRules"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              :disabled="loadingData"
              @click="closeStudent"
            >
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
            <v-btn
              color="blue darken-1"
              dark
              :loading="loadingData"
              @click="saveStudent"
            >
              <span class="text-capitalize">Guardar</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogInfo" max-width="400px">
        <v-card>
          <p style="color: #1874bf" class="text-h6 text-center pt-4">
            Informe de calendario
          </p>
          <v-card-text class="text-center">
            ¿Esta seguro de quitar al estudiante?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize"
              text
              dark
              color="blue darken-1"
              @click="dialogInfo = false"
              >No</v-btn
            >
            <v-btn
              class="text-capitalize"
              dark
              color="blue darken-1"
              @click="removeStudentConfirm()"
              >Si</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="400px">
        <v-card>
          <p style="color: #1874bf" class="text-h6 text-center pt-4">
            Informe de evento
          </p>
          <v-card-text class="text-center">
            ¿Esta seguro de eliminar el evento?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize"
              text
              dark
              color="blue darken-1"
              @click="dialogDelete = false"
              >No</v-btn
            >
            <v-btn
              class="text-capitalize"
              dark
              color="blue darken-1"
              :loading="loadingData"
              @click="removeEventConfirm()"
              >Si</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogEventUpdate" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ "Editar Horario" }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="formClass">
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Nivel</p>
                    <v-select
                      v-model="formClass.idLevel"
                      :items="lessonLevels"
                      item-text="nombreNivel"
                      item-value="idNivel"
                      label="Nivel"
                      persistent-hint
                      dense
                      outlined
                      single-line
                      :rules="levelRules"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-0">
                    <p class="mb-2">Nro Alumnos</p>
                    <v-select
                      v-model="formClass.numberStudents"
                      :items="numberStudents"
                      label="Nro Alumnos"
                      persistent-hint
                      dense
                      outlined
                      single-line
                      :rules="studentRules"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    class="py-0 d-flex align-center"
                  >
                    <v-checkbox
                      v-model="formClass.virtual"
                      label="Clase virtual"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Fecha</p>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formClass.date"
                          label="Picker in menu"
                          outlined
                          dense
                          single-line
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formClass.date"
                        no-title
                        @input="menu = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Materia</p>
                    <v-select
                      v-model="formClass.idSubject"
                      :items="subjects"
                      item-text="nombreMateria"
                      item-value="idMateria"
                      label="Seleccione"
                      persistent-hint
                      dense
                      outlined
                      single-line
                      :rules="lessonRules"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <p class="mb-2">Descripcion</p>
                    <v-text-field
                      v-model="formClass.description"
                      label="Descripcion"
                      outlined
                      dense
                      single-line
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <p class="mb-2">Inicio</p>
                    <v-text-field
                      v-model="formClass.timeStart"
                      outlined
                      dense
                      single-line
                      type="time"
                      :rules="timeStartRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <p class="mb-2">Fin</p>
                    <v-text-field
                      v-model="formClass.timeEnd"
                      outlined
                      dense
                      single-line
                      type="time"
                      :rules="timeEndRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEventUpdate">
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
            <v-btn
              color="blue darken-1"
              dark
              :loading="loadingData"
              @click="updateEvent"
            >
              <span class="text-capitalize">Guardar Cambios</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogPayment" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ "Registrar Pago" }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    class="py-0"
                    v-if="selectedEvent.type == 2"
                  >
                    <p class="mb-2">Saldo</p>
                    <v-text-field
                      v-model="formPayment.movingBalance"
                      class="pt-0"
                      placeholder="Saldo"
                      single-line
                      dense
                      outlined
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    class="py-0"
                    v-if="selectedEvent.type == 1"
                  >
                    <p class="mb-2">Precio</p>
                    <v-text-field
                      v-model="formPayment.price"
                      class="pt-0"
                      placeholder="Precio"
                      single-line
                      dense
                      outlined
                      :rules="priceRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Total a pagar</p>
                    <v-text-field
                      v-model="formPayment.subTotal"
                      class="pt-0"
                      placeholder="Total"
                      single-line
                      dense
                      outlined
                      :rules="subTotalRules"
                      @blur="calculateBalance"
                      v-if="selectedEvent.type == 2"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="formPayment.subTotal"
                      class="pt-0"
                      placeholder="Total"
                      single-line
                      dense
                      outlined
                      :rules="subTotalRules"
                      v-else
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Descuento</p>
                    <v-text-field
                      v-model="formPayment.discount"
                      class="pt-0"
                      placeholder="Descuento"
                      single-line
                      dense
                      outlined
                      @blur="calculateBalance"
                      :rules="discountRules"
                      v-if="selectedEvent.type == 2"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="formPayment.discount"
                      class="pt-0"
                      placeholder="Descuento"
                      single-line
                      dense
                      outlined
                      :rules="discountRules"
                      v-else
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <p class="mb-2">Tipo Pago</p>
                    <v-select
                      v-model="formPayment.idPaymentType"
                      :items="paymentTypes"
                      item-text="nombreTipoPago"
                      item-value="idTipoPago"
                      label="Seleccione"
                      persistent-hint
                      dense
                      outlined
                      single-line
                      :rules="paymentTypeRules"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="py-0">
                    <p class="mb-2">Descripcion</p>
                    <v-text-field
                      v-model="formPayment.description"
                      class="pt-0"
                      placeholder="Descripcion"
                      single-line
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              :disabled="loadingData"
              @click="closePayment"
            >
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
            <v-btn
              color="blue darken-1"
              dark
              :loading="loadingData"
              @click="savePayment"
            >
              <span class="text-capitalize">Guardar</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ReceiptClass
        ref="receipt"
        :item="itemReceipt"
        :student="student"
        :user="user"
        :info="info"
        :lesson="lessons"
        :departament="nameDepartament"
      />
      <!--Change Lesson-->
      <v-dialog v-model="dialogChangeLesson" max-width="700px" scrollable>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ "Cambiar Horario" }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form>
                <v-row>
                  <p class="font-weight-bold text-body-1">Buscar Profesor</p>
                  <v-row>
                    <v-col cols="12" sm="3" md="3" class="py-0">
                      <p class="mb-2">Opciones</p>
                      <v-select
                        v-model="formSearchTeacher.option"
                        :items="optionItems"
                        item-text="option"
                        item-value="idOption"
                        label="Seleccione una opcion"
                        persistent-hint
                        return-object
                        outlined
                        single-line
                        dense
                        @change="optionTeacherChange"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="5" md="5" class="py-0">
                      <p class="mb-2">{{ optionName }}</p>
                      <v-text-field
                        v-model="formSearchTeacher.value"
                        class="pt-0"
                        :placeholder="optionName"
                        single-line
                        dense
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" class="py-0">
                      <v-btn
                        class="mt-sm-8 mt-md-8"
                        dark
                        color="#1CA5DB"
                        elevation="0"
                        @click="searchTeacherByParams"
                      >
                        <span class="text-capitalize text-body">Buscar</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-col cols="12" sm="12" md="12" class="py-0">
                    <template v-if="loadingSearch">
                      <v-container class="d-flex justify-center">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-container>
                    </template>
                    <div v-show="searchTeachers.length > 0">
                      <v-data-table
                        :headers="headersTeachers"
                        :items="searchTeachers"
                        :items-per-page="-1"
                        class="elevation-1 font-weight-light caption td-click"
                        @click:row="clickRowSearchTeacher"
                        fixed-header
                        hide-default-footer
                      >
                      </v-data-table>
                    </div>
                  </v-col>
                  <div v-show="Object.keys(selectedTeacher).length != 0">
                    <p class="font-weight-bold text-body-1 mb-0 mt-2">
                      Informacion del Profesor de cambio
                    </p>
                    <p
                      style="color: #4c4c4c"
                      class="mb-1 subtitle-2 font-weight-medium"
                    >
                      CI: <span class="caption">{{ selectedTeacher.ci }}</span>
                    </p>
                    <p
                      style="color: #4c4c4c"
                      class="mb-1 subtitle-2 font-weight-medium"
                    >
                      Nombres y Apellidos:
                      <span class="caption">{{
                        selectedTeacher.nombres +
                        " " +
                        selectedTeacher.apellidos
                      }}</span>
                    </p>
                  </div>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeTeacherClass">
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
            <v-btn
              color="blue darken-1 white--text"
              :disabled="Object.keys(selectedTeacher).length == 0"
              :loading="loadingData"
              @click="changeTeacher"
            >
              <span class="text-capitalize">Cambiar</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogTransfer" max-width="400px">
        <v-card>
          <p style="color: #1874bf" class="text-h6 text-center pt-4">
            Informe de Transferencia
          </p>
          <v-card-text class="text-center">
            {{ message }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeTransfer">No</v-btn>
            <v-btn dark color="blue darken-1" @click="transferConfirm"
              >Si</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-layout>
</template>

<script>
import ReceiptClass from "@/components/receipt/receipt-class-component.vue";
import axios from "axios";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    ReceiptClass,
  },
  data() {
    return {
      type: "week",
      types: ["month", "week", "day", "4day"],
      focus: "",
      value: "",
      colors: [
        "#2196F3",
        "#3F51B5",
        "#673AB7",
        "#00BCD4",
        "#4CAF50",
        "#FF9800",
        "#757575",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      weekdays: [1, 2, 3, 4, 5, 6],
      titleCalendar: "",
      toogle: "",
      items: [
        {
          text: "Profesores",
          disabled: false,
          href: "/teacher",
        },
        {
          text: "Calendario",
          disabled: false,
          href: `/teacher/${this.$route.params.idTeacher}/schedule`,
        },
      ],
      teacher: {},
      events: [],
      loadingData: false,
      dialogGroup: false,
      groups: [],
      formGroup: {
        idGroup: "",
        weeks: "",
        timeStart: "",
        timeEnd: "",
        date: "",
      },
      groupRules: [(value) => !!value || "Debe ingresar un Grupo"],
      weekRules: [(value) => !!value || "Debe ingresar la cantidad de semanas"],
      timeStartRules: [(value) => !!value || "Debe ingresar una hora"],
      timeEndRules: [(value) => !!value || "Debe ingresar una hora"],
      weeks: [
        {
          idWeek: "1",
          nameWeek: "1 semana",
        },
        {
          idWeek: "2",
          nameWeek: "2 semanas",
        },
        {
          idWeek: "3",
          nameWeek: "3 semanas",
        },
        {
          idWeek: "4",
          nameWeek: "4 semanas",
        },
        {
          idWeek: "5",
          nameWeek: "5 semanas",
        },
        {
          idWeek: "6",
          nameWeek: "6 semanas",
        },
        {
          idWeek: "7",
          nameWeek: "7 semanas",
        },
        {
          idWeek: "8",
          nameWeek: "8 semanas",
        },
        {
          idWeek: "9",
          nameWeek: "9 semanas",
        },
        {
          idWeek: "10",
          nameWeek: "10 semanas",
        },
        {
          idWeek: "11",
          nameWeek: "11 semanas",
        },
        {
          idWeek: "12",
          nameWeek: "12 semanas",
        },
        {
          idWeek: "13",
          nameWeek: "13 semanas",
        },
        {
          idWeek: "14",
          nameWeek: "14 semanas",
        },
        {
          idWeek: "15",
          nameWeek: "15 semanas",
        },
        {
          idWeek: "16",
          nameWeek: "16 semanas",
        },
        {
          idWeek: "17",
          nameWeek: "17 semanas",
        },
        {
          idWeek: "18",
          nameWeek: "18 semanas",
        },
      ],
      eventItem: "",
      snackbar: false,
      colorSnackbar: "",
      textSnackbar: "",
      startDate: "",
      endDate: "",
      lessonLevels: [],
      numberStudents: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      dialogStudent: false,
      formClass: {
        idSubject: "",
        idStudent: "",
        idLevel: "",
        numberStudents: "",
        type: "",
        timeStart: "",
        timeEnd: "",
        updateTimeStart: "",
        updateTimeEnd: "",
        date: "",
        idReservationStudent: 0,
        description: "",
        virtual: false,
      },
      lessonRules: [(value) => !!value || "Debe seleccionar una materia"],
      levelRules: [(value) => !!value || "Debe seleccionar un nivel"],
      studentRules: [
        (value) => !!value || "Debe seleccionar la cantidad de estudiantes",
      ],
      optionItems: [
        {
          idOption: 1,
          option: "CI",
        },
        {
          idOption: 2,
          option: "Apellidos",
        },
        {
          idOption: 3,
          option: "Nombres",
        },
      ],
      formSearchStudent: {
        names: "",
        surnames: "",
        ci: "",
        value: "",
        option: {
          idOption: 1,
          option: "CI",
        },
      },
      optionName: "CI",
      searchStudents: [],
      loadingSearch: false,
      headersStudent: [
        {
          text: "Ci",
          value: "ci",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Apellidos",
          value: "apellidos",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Nombres",
          value: "nombres",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        //{text: "Acciones", value: "accion", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
      ],
      student: {},
      subjects: [],
      dialogClass: false,
      numberClass: 0,
      dialogInfo: false,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      dialogDelete: false,
      menu: false,
      dialogEventUpdate: false,
      selectedItem: "",
      dialogPayment: false,
      formPayment: {
        idPayment: "",
        paymentDate: new Date().toISOString().substring(0, 10),
        discount: "0",
        oldDiscount: "",
        balance: "",
        subTotal: "",
        oldSubTotal: "",
        description: "",
        monthlyPayment: "",
        monthlyAmount: "",
        idMonthlyPayment: "",
        idUser: "",
        idGruopUser: "",
        idPaymentType: 1,
        movingBalance: "",
        price: "",
      },
      balanceRules: [
        (value) => !!value || "Ingrese un saldo",
        (value) => !isNaN(value) || "Debe ingresar un número",
      ],
      discountRules: [
        (value) => !!value || "Ingrese un descuento",
        (value) => !isNaN(value) || "Debe ingresar un número",
      ],
      subTotalRules: [
        (value) => !!value || "Ingrese un total",
        (value) => !isNaN(value) || "Debe ingresar un número",
      ],
      paymentTypeRules: [(value) => !!value || "Seleccione un Tipo de Pago"],
      priceRules: [
        (value) => !!value || "Ingrese un precio",
        (value) => !isNaN(value) || "Debe ingresar un número",
      ],
      paymentTypes: [],
      itemReceipt: {
        idPago: "",
        fechaPago: "",
        nroMensualidad: "",
        montoMensualidad: "",
        descuento: "",
        acuenta: "",
        cancelado: "",
        tipoPago: "",
        saldo: "",
        descripcion: "",
      },
      info: {},
      idPayment: "",
      payments: [],
      lessons: [],
      receipt: "",
      clicked: false,
      activitiesSwitch: false,
      weeksActivities: [
        {
          idWeek: "1",
          nameWeek: "1 semana",
        },
        {
          idWeek: "2",
          nameWeek: "2 semanas",
        },
        {
          idWeek: "3",
          nameWeek: "3 semanas",
        },
        {
          idWeek: "4",
          nameWeek: "4 semanas",
        },
        {
          idWeek: "5",
          nameWeek: "5 semanas",
        },
        {
          idWeek: "6",
          nameWeek: "6 semanas",
        },
        {
          idWeek: "7",
          nameWeek: "7 semanas",
        },
        {
          idWeek: "8",
          nameWeek: "8 semanas",
        },
      ],
      nameDepartament: "",
      //Variables Module Change Lesson
      dialogChangeLesson: false,
      formSearchTeacher: {
        names: "",
        surnames: "",
        ci: "",
        value: "",
        option: {
          idOption: 1,
          option: "CI",
        },
      },
      searchTeachers: [],
      headersTeachers: [
        {
          text: "Ci",
          value: "ci",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Apellidos",
          value: "apellidos",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Nombres",
          value: "nombres",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        //{text: "Acciones", value: "accion", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
      ],
      selectedTeacher: {},
      eventSelected: "",
      today: new Date().toISOString().substring(0, 10),
      //new student
      formNewStudent: {
        idStudent: "",
        names: "",
        surnames: "",
        ci: "",
        email: "",
        phone: "",
        idBranchoffice: "",
        creationDate: new Date().toISOString().substring(0, 10),
      },
      nameRules: [(value) => !!value || "Ingrese nombres"],
      surnameRules: [(value) => !!value || "Ingrese apellidos"],
      ciRules: [(value) => !!value || "Ingrese un ci"],
      emailRules: [
        (value) => !!value || "Ingrese un email",
        (value) => /.+@.+\..+/.test(value) || "Debe ingresar un email correcto",
      ],
      phoneRules: [
        (value) => !!value || "Ingrese un numero de telefono",
        (value) =>
          /^(\d{8}-\d{8}|\d{8})$/.test(value) ||
          "Debe ingresar un formato correcto",
      ],
      branchofficeRules: [(value) => !!value || "Seleccione una sucursal"],
      branchoffices: [],
      dialogNewStudent: false,
      dialogTransfer: false,
      userData: {},
      message: "",
      idBranchofficeTransfer: "",
    };
  },
  computed: {
    ...mapState(["idDepartament", "user", "studentData"]),
    movingBalance() {
      return (
        Number(this.formPayment.balance) -
        (Number(this.formPayment.subTotal) + Number(this.formPayment.discount))
      );
    },
    nameActivities() {
      return this.activitiesSwitch ? "Actividades" : " Grupos";
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  watch: {
    dialogGroup(val) {
      val || this.closeGroupSchedule();
    },
    dialogClass(val) {
      val || this.closeClass();
    },
    dialogEventUpdate(val) {
      val || this.closeEventUpdate();
    },
    dialogNewStudent(val) {
      val || this.closeStudent();
    },
  },
  created() {
    if (this.studentData != null) {
      if (this.studentData.dateStart != undefined) {
        this.today = this.studentData.dateStart;
      }
      this.student = this.studentData;
      this.formClass.idReservationStudent = this.studentData.idReserva;
    }
    this.user.idRol == 1
      ? this.selectDepartamentById()
      : (this.nameDepartament = this.user.departamento);
    this.selectTeacher();
    this.selectSubjectTeacher();
    this.selectGroupByYear();
    this.selectLessonLevels();
    this.selectPaymentTypes();
    this.selectBranchoffices();
  },
  methods: {
    ...mapMutations(["setStudentData"]),
    //transferStudent
    async updateTransferUser(data) {
      try {
        let response = await axios.put("users/updateTransferUser", data);
        if (response.status == 200) {
          this.searchStudents.push(this.userData);
          this.closeTransfer();
          this.notification("success", response.data["msg"]);
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    transferConfirm() {
      let data = {
        idBranchoffice: this.idBranchofficeTransfer,
        idUser: this.userData.idUsuario,
      };

      this.updateTransferUser(data);
    },
    closeTransfer() {
      this.dialogTransfer = false;
      this.userData = {};
      this.message = "";
      this.idBranchofficeTransfer = "";
    },
    //Module insert student
    async insertStudent(data) {
      this.searchStudents = [];
      try {
        let response = await axios.post("users/insertStudent", data);
        if (response.status == 200) {
          if (
            response.data.type == 0 ||
            response.data.type == 1 ||
            response.data.type == 2
          ) {
            this.closeStudent();
            this.loadingData = false;
            this.notification("success", response.data["msg"]);
            this.formSearchStudent.value = "";
            if (response.data.type != 1) {
              this.searchStudents.push(response.data["data"]);
            }
          } else {
            this.idBranchofficeTransfer = this.formNewStudent.idBranchoffice;
            this.closeStudent();
            this.loadingData = false;
            this.userData = response.data.data;
            this.message = response.data.msg;
            this.dialogTransfer = true;
            this.formSearchStudent.value = "";
          }
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    saveStudent() {
      if (this.$refs.formNewStudent.validate()) {
        let data = {
          names: this.formNewStudent.names.toUpperCase().trim(),
          surnames: this.formNewStudent.surnames.toUpperCase().trim(),
          ci: this.formNewStudent.ci.trim(),
          email: this.formNewStudent.email.trim(),
          phone: this.formNewStudent.phone.trim(),
          idBranchoffice:
            this.user.idRol == 1
              ? this.formNewStudent.idBranchoffice
              : this.user.idSucursal,
          user: null,
          pass: null,
          idDepartament: this.idDepartament,
        };
        this.loadingData = true;

        this.insertStudent(data);
      }
    },
    closeStudent() {
      this.dialogNewStudent = false;
      this.formNewStudent.ci = "";
      this.formNewStudent.email = "";
      this.formNewStudent.idBranchoffice = "";
      this.formNewStudent.idStudent = "";
      this.formNewStudent.names = "";
      this.formNewStudent.phone = "";
      this.formNewStudent.surnames = "";
      this.$refs.formNewStudent.resetValidation();
    },
    async selectBranchoffices() {
      let url = `branchoffices/selectBranchoffices/${this.idDepartament}/1`;
      try {
        let response = await axios.get(url);
        if (response.status == 200) {
          this.branchoffices = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectDepartamentById() {
      try {
        let response = await axios.get(
          `departaments/selectDepartamentById/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.nameDepartament = response.data.nombreDepartamento;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //Module Change Lesson
    async updateTeacherAllSubjects(data) {
      try {
        let response = await axios.put(
          "lessons/updateTeacherAllSubjects",
          data
        );
        if (response.status == 200) {
          this.notification("success", response.data["msg"]);
          this.$router.push(`/teacher/${response.data["idTeacher"]}/schedule`);
          this.loadingData = false;
        }
      } catch (error) {
        this.loadingData = false;
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async updateTeacherSubject(data) {
      try {
        let response = await axios.put("lessons/updateTeacherSubject", data);
        if (response.status == 200) {
          this.notification("success", response.data["msg"]);
          this.$router.push(`/teacher/${response.data["idTeacher"]}/schedule`);
          this.loadingData = false;
        }
      } catch (error) {
        this.loadingData = false;
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    changeTeacher() {
      if (Object.keys(this.selectedTeacher).length == 0) {
        this.notification("warning", "Debe seleccionar un Profesor");
        return;
      }
      let data = {};
      this.loadingData = true;
      if (this.selectedItem == 2) {
        data = {
          idSubject: this.selectedEvent.idSubject,
          idTeacher: this.selectedTeacher.idUsuario,
          idLesson: this.selectedEvent.idClass,
        };
        this.updateTeacherSubject(data);
      } else if (this.selectedItem == 3) {
        data = {
          idOldTeacher: this.$route.params.idTeacher,
          idTeacher: this.selectedTeacher.idUsuario,
          idReservation: this.selectedEvent.idReservationStudent,
        };
        this.updateTeacherAllSubjects(data);
      }
    },
    clickRowSearchTeacher(item) {
      this.selectedTeacher = Object.assign({}, item, {});
      this.searchTeachers = [];
      //this.closeTeacherClass();
    },
    optionTeacherChange(evt) {
      this.optionName = evt.option;
      this.formSearchTeacher.value = "";
    },
    searchTeacherByParams() {
      let names = "";
      let surnames = "";
      let ci = "";
      this.selectedTeacher = {};
      if (this.formSearchTeacher.option.idOption == 1) {
        names = null;
        surnames = null;
        ci =
          this.formSearchTeacher.value == ""
            ? null
            : this.formSearchTeacher.value;
      } else if (this.formSearchTeacher.option.idOption == 2) {
        names = null;
        surnames =
          this.formSearchTeacher.value == ""
            ? null
            : this.formSearchTeacher.value;
        ci = null;
      } else if (this.formSearchTeacher.option.idOption == 3) {
        names =
          this.formSearchTeacher.value == ""
            ? null
            : this.formSearchTeacher.value;
        surnames = null;
        ci = null;
      }
      let url = `${this.idDepartament}/${this.teacher.idUsuario}/${names}/${surnames}/${ci}`;
      this.loadingSearch = true;
      this.selectSearchTeacher(url);
    },
    async selectSearchTeacher(url) {
      try {
        let response = await axios.get(`users/selectSearchTeacher/${url}`);
        if (response.status == 200) {
          this.searchTeachers = response.data;
          this.loadingSearch = false;
          if (response.data.length <= 0) {
            this.notification("primary", "No se encontro registros");
          }
        }
      } catch (error) {
        console.log(error);
        this.loadingSearch = false;
      }
    },
    closeTeacherClass() {
      this.dialogChangeLesson = false;
      this.formSearchTeacher.ci = "";
      this.formSearchTeacher.names = "";
      this.formSearchTeacher.surnames = "";
      this.formSearchTeacher.value = "";
      this.searchTeachers = [];
    },

    //Other Module
    async generateReceipt() {
      if (this.receipt == "") {
        this.notification(
          "warning",
          "Debe seleccionar un recibo para la impresion"
        );
        return;
      }
      await this.selectLessonsByIdReservation();
      this.$refs.receipt.generatePdf();
    },
    changeItemReceipt() {
      this.itemReceipt = this.receipt;
    },
    calculateBalance() {
      this.formPayment.movingBalance =
        Number(this.formPayment.balance) -
        (Number(this.formPayment.subTotal) + Number(this.formPayment.discount));
    },
    async selectLessonsByIdReservation() {
      try {
        this.lessons = [];
        let response = await axios.get(
          `lessons/selectLessonsByIdReservation/${this.formClass.idReservationStudent}/${this.teacher.idUsuario}`
        );
        if (response.status == 200) {
          response.data.map((value) => {
            this.lessons.push({
              idClase: value.idClase,
              fecha: this.formatDateTimeClass(
                value.fechaInicial,
                value.fechaFinal
              ),
              materia: value.nombreMateria,
              virtual: value.virtual ? "Virtual" : "Presencial",
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectPaymentByIdReservation() {
      try {
        let response = await axios.get(
          `payments/selectPaymentsByIdReservation/${this.formClass.idReservationStudent}`
        );
        if (response.status == 200) {
          response.data.map((value) => {
            value.fechaPago = this.formatDateTimeReceipt(value.fechaPago);
          });

          this.payments = response.data;
          this.receipt = response.data[0];
          this.itemReceipt = this.receipt;

          if (this.idPayment > 0) {
            this.itemReceipt = this.payments.find(
              (value) => value.idPago === this.idPayment
            );
            this.$refs.receipt.generatePdf();
          }

          if (this.selectedEvent.type == 2) {
            let i = this.payments.length;
            this.formPayment.balance = this.payments[i - 1].saldo;
            this.formPayment.movingBalance = this.payments[i - 1].saldo;
            this.formPayment.price = this.payments[i - 1].saldo;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async insertPayment(data) {
      try {
        this.loadingData = true;
        let response = await axios.post("payments/insertPaymentLesson", data);
        if (response.status == 200) {
          this.closePayment();
          this.selectedOpen = false;
          this.notification("success", response.data["msg"]);
          this.selectGroupTeacher();
          await this.selectLessonsByIdReservation();
          this.idPayment = response.data["idPago"];
          await this.selectPaymentByIdReservation();
          this.loadingData = false;
          this.removeStudentConfirm();
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
        this.loadingData = false;
      }
    },
    savePayment() {
      if (this.$refs.form.validate()) {
        if (this.formPayment.price < this.formPayment.discount) {
          this.notification(
            "warning",
            "El descuento no puede ser mayor al precio"
          );
          return;
        }

        if (this.formPayment.price < this.formPayment.subTotal) {
          this.notification(
            "warning",
            "El total a pagar no debe ser mayor al precio"
          );
          return;
        }

        if (
          this.formPayment.price <
          Number(this.formPayment.subTotal) + Number(this.formPayment.discount)
        ) {
          this.notification(
            "warning",
            "La suma del total mas el descuento no debe ser mayor al precio"
          );
          return;
        }
        if (
          Number(this.formPayment.subTotal) == 0 &&
          Number(this.formPayment.discount) == 0
        ) {
          this.notification(
            "warning",
            "El total a pagar o el descuento deben tener un valor mayor a 0"
          );
          return;
        }

        let data = {
          discount: this.formPayment.discount,
          subTotal: this.formPayment.subTotal,
          total: this.formPayment.price,
          description: this.formPayment.description.trim().toUpperCase(),
          idReservation: this.formClass.idReservationStudent,
          idUser: this.user.idUsuario,
          idPaymentType: this.formPayment.idPaymentType,
          idDepartament: this.idDepartament,
        };

        this.insertPayment(data);
      }
    },
    //events
    async eventPayment() {
      this.selectedItem = 0;
      this.setStudentData(null);
      this.student = {
        apellidos: this.selectedEvent.lastName,
        ci: this.selectedEvent.ci,
        idLevel: this.selectedEvent.idLevel,
        idUsuario: this.selectedEvent.idStudent,
        nombres: this.selectedEvent.firstName,
        numberStudents: this.selectedEvent.numberStudents,
        sigla: this.selectedEvent.initials,
      };
      this.info = {
        nombres: this.teacher.nombres,
        apellidos: this.teacher.apellidos,
        tipo: this.selectedEvent.initials,
        nroEstudiante: this.selectedEvent.numberStudents,
      };
      this.idPayment = "";
      this.formClass.idReservationStudent =
        this.selectedEvent.idReservationStudent;
      this.formClass.type = this.selectedEvent.type;
      await this.selectPaymentByIdReservation();

      this.dialogPayment = true;
    },
    async eventViewReceipt() {
      this.selectedItem = 1;
      this.setStudentData(null);
      this.student = {
        apellidos: this.selectedEvent.lastName,
        ci: this.selectedEvent.ci,
        idLevel: this.selectedEvent.idLevel,
        idUsuario: this.selectedEvent.idStudent,
        nombres: this.selectedEvent.firstName,
        numberStudents: this.selectedEvent.numberStudents,
        sigla: this.selectedEvent.initials,
      };
      this.info = {
        nombres: this.teacher.nombres,
        apellidos: this.teacher.apellidos,
        tipo: this.selectedEvent.initials,
        nroEstudiante: this.selectedEvent.numberStudents,
      };
      this.formClass.idLevel = this.selectedEvent.idLevel;
      //this.formClass.numberStudents = this.selectedEvent.numberStudents;
      this.formClass.idReservationStudent =
        this.selectedEvent.idReservationStudent;
      //this.formClass.idSubject = this.selectedEvent.idSubject;
      //this.formClass.date = this.selectedEvent.start.substring(0,10);
      //this.formClass.timeStart = this.selectedEvent.start.substring(11,19);
      //this.formClass.timeEnd = this.selectedEvent.end.substring(11,19);
      this.formClass.type = this.selectedEvent.type;
      this.idPayment = "";
      await this.selectPaymentByIdReservation();
      this.selectedOpen = false;
    },
    async eventChangeLesson() {
      this.selectedItem = 2;
      this.dialogChangeLesson = true;
      this.searchTeachers = [];
      this.selectedTeacher = {};
    },
    async eventChangeAllLesson() {
      this.selectedItem = 3;
      this.dialogChangeLesson = true;
      this.searchTeachers = [];
      this.selectedTeacher = {};
    },
    async updateLesson(data) {
      try {
        this.loadingData = true;
        let response = await axios.put("lessons/updateLesson", data);
        if (response.status == 200) {
          this.notification("success", response.data["msg"]);
          console.log(this.student);
          if (this.student.hrsRestante != undefined) {
            console.log(data.hoursLeft);
            if (data.hoursLeft >= 0) {
              this.student.hrsRestante = data.hoursLeft;
              this.setStudentData(this.student);
            }
          }
          this.closeEventUpdate();
          this.selectGroupTeacher();
          this.loadingData = false;
        }
      } catch (error) {
        this.loadingData = false;
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    updateEvent() {
      if (this.$refs.formClass.validate()) {
        const datetimestart =
          this.formClass.date + " " + this.formClass.timeStart;
        const datetimeend = this.formClass.date + " " + this.formClass.timeEnd;

        const updateTimeStart =
          this.formClass.date + " " + this.formClass.updateTimeStart;
        const updateTimeEnd =
          this.formClass.date + " " + this.formClass.updateTimeEnd;

        let result = null;

        if (this.student.hrsRestante != undefined) {
          const timeStart = new Date(datetimestart);
          const timeEnd = new Date(datetimeend);
          const timeUpdateStart = new Date(updateTimeStart);
          const timeUpdateEnd = new Date(updateTimeEnd);

          if (this.student.hrsRestante == 0) {
            const res =
              (timeUpdateEnd.getTime() - timeUpdateStart.getTime()) / 1000 / 60;
            result = (timeEnd.getTime() - timeStart.getTime()) / 1000 / 60;

            if (result > res) {
              this.notification(
                "warning",
                `No tiene suficientes minutos para asignar, por favor vuelva a intentarlo.`
              );
              result = null;
              return;
            } else if (res > result) {
              result = res - result;
            }

            if (res == result) {
              result = null;
            }
          } else if (this.student.hrsRestante > 0) {
            const res =
              (timeUpdateEnd.getTime() - timeUpdateStart.getTime()) / 1000 / 60;
            result = (timeEnd.getTime() - timeStart.getTime()) / 1000 / 60;
            result = result - res;
            console.log(result);
            const validate = this.student.hrsRestante - result;

            console.log(validate);

            if (validate < 0) {
              this.notification(
                "warning",
                `Solo tiene ${this.student.hrsRestante} minutos para asignar, por favor vuelva a asignar el horario.`
              );
              return;
            }

            result = validate;
          }
        }

        let data = {
          idSubject: this.formClass.idSubject,
          idTeacher: this.$route.params.idTeacher,
          idLevel: this.formClass.idLevel,
          idSchedule: this.selectedEvent.idSchedule,
          startDate: datetimestart,
          endDate: datetimeend,
          idLesson: this.selectedEvent.idClass,
          numberStudent: this.formClass.numberStudents,
          hoursLeft: result,
          idReservation: this.selectedEvent.idReservationStudent,
          description: this.formClass.description.trim().toUpperCase(),
          virtual: this.formClass.virtual,
        };

        this.updateLesson(data);
      }
    },
    async selectHoursLeftByIdReservation(id) {
      try {
        let response = await axios.get(
          `lessons/selectHoursLeftByIdReservation/${id}`
        );
        if (response.status == 200) {
          return response.data;
        }
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async editEvent() {
      let hoursLeft = 0;
      if (this.selectedEvent.type == 5) {
        hoursLeft = await this.selectHoursLeftByIdReservation(
          this.selectedEvent.idReservationStudent
        );
      }

      this.setStudentData(null);

      this.student = {
        apellidos: this.selectedEvent.lastName,
        ci: this.selectedEvent.ci,
        idLevel: this.selectedEvent.idLevel,
        idUsuario: this.selectedEvent.idStudent,
        nombres: this.selectedEvent.firstName,
        numberStudents: this.selectedEvent.numberStudents,
        sigla: this.selectedEvent.initials,
        idReserva: this.selectedEvent.idReservationStudent,
      };

      if (this.selectedEvent.type == 5) {
        this.student.hrsRestante = hoursLeft.hrsRestante;
        this.setStudentData(this.student);
      }

      this.info = {
        nombres: this.teacher.nombres,
        apellidos: this.teacher.apellidos,
        tipo: this.selectedEvent.initials,
        nroEstudiante: this.selectedEvent.numberStudents,
      };
      this.formClass.idLevel = this.selectedEvent.idLevel;
      this.formClass.numberStudents = this.selectedEvent.numberStudents;
      this.formClass.idReservationStudent =
        this.selectedEvent.idReservationStudent;
      this.formClass.idSubject = this.selectedEvent.idSubject;
      this.formClass.description = this.selectedEvent.description;
      this.formClass.date = this.selectedEvent.start.substring(0, 10);
      this.formClass.timeStart = this.selectedEvent.start.substring(11, 19);
      this.formClass.timeEnd = this.selectedEvent.end.substring(11, 19);
      this.formClass.updateTimeStart = this.selectedEvent.start.substring(
        11,
        19
      );
      this.formClass.updateTimeEnd = this.selectedEvent.end.substring(11, 19);
      this.formClass.type = this.selectedEvent.type;
      this.idPayment = "";
      this.selectPaymentByIdReservation();
      this.dialogEventUpdate = true;
      this.selectedOpen = false;
    },
    async deleteEvent(url, data) {
      try {
        let response = await axios.put(url, data);
        if (response.status == 200) {
          this.notification("success", response.data["msg"]);
          this.closeDelete();
          if (this.student.hrsRestante != undefined) {
            this.student.hrsRestante = data.hoursLeft;
            this.setStudentData(this.student);
          }
          this.selectGroupTeacher();
          this.loadingData = false;
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
        this.loadingData = false;
      }
    },
    removeEventConfirm() {
      let data;
      let url;
      this.loadingData = true;
      if (this.selectedEvent.idClass != null) {
        let hoursLeft = 0;
        if (this.selectedEvent.type == 5) {
          const timeStart = new Date(this.selectedEvent.start);
          const timeEnd = new Date(this.selectedEvent.end);

          const minutes = (timeEnd.getTime() - timeStart.getTime()) / 1000 / 60;
          hoursLeft = minutes + this.student.hrsRestante;
        }
        url = "lessons/deleteLesson";
        data = {
          state: 0,
          idLesson: this.selectedEvent.idClass,
          idReservation:
            this.selectedEvent.type == 5
              ? this.selectedEvent.idReservationStudent
              : 0,
          hoursLeft: hoursLeft,
        };
      } else if (this.selectedEvent.idActivitieTeacher != null) {
        url = "activities/deleteActivitieTeacher";
        data = {
          state: 0,
          idActivitieTeacher: this.selectedEvent.idActivitieTeacher,
        };
      } else {
        url = "groupTeachers/deleteGroupTeacher";
        data = {
          state: 0,
          idGroupTeacher: this.selectedEvent.idGroupTeacher,
        };
      }

      this.deleteEvent(url, data);
    },
    async removeEvent() {
      if (
        this.user.idRol != 1 &&
        (this.selectedEvent.type == 4 ||
          this.selectedEvent.type == 3 ||
          this.selectedEvent.type == 2)
      ) {
        this.notification(
          "warning",
          "Una vez pagada la clase no puede eliminarla"
        );
        return;
      }

      if (this.selectedEvent.type == 5) {
        let hoursLeft = 0;
        hoursLeft = await this.selectHoursLeftByIdReservation(
          this.selectedEvent.idReservationStudent
        );
        this.setStudentData(null);

        this.student = {
          apellidos: this.selectedEvent.lastName,
          ci: this.selectedEvent.ci,
          idLevel: this.selectedEvent.idLevel,
          idUsuario: this.selectedEvent.idStudent,
          nombres: this.selectedEvent.firstName,
          numberStudents: this.selectedEvent.numberStudents,
          sigla: this.selectedEvent.initials,
          idReserva: this.selectedEvent.idReservationStudent,
        };
        this.formClass.idReservationStudent =
          this.selectedEvent.idReservationStudent;
        this.student.hrsRestante = hoursLeft.hrsRestante;
        this.setStudentData(this.student);
      }

      this.dialogDelete = true;
    },
    getTextColor(event) {
      if (event.idGroupTeacher != null) {
        return true;
      } else {
        if (event.type == 3 && event.virtual) {
          return true;
        } else if (event.type == 1) {
          return false;
        } else {
          return true;
        }
      }
    },
    getEventTextColor(event) {
      if (event.idGroupTeacher != null) {
        return "white";
      } else {
        if (event.type == 3 && event.virtual) {
          return "white";
        } else if (event.type == 1) {
          return "grey darken-1";
        } else {
          return "white";
        }
      }
    },
    getEventColor(event) {
      if (event.idGroupTeacher != null) {
        return "light-blue";
      } else {
        if (
          event.idReservationStudent == this.formClass.idReservationStudent &&
          Object.keys(this.student).length != 0
        ) {
          return "amber";
        }
        if (event.type == 3 && event.virtual) {
          return "pink";
        } else if (event.type == 1) {
          return "yellow accent-1";
        } else if (event.type == 2) {
          return "blue darken-1";
        } else if (event.type == 3) {
          return "green";
        } else if (event.type == 4) {
          return "purple";
        } else if (event.type == 5) {
          return "light-green";
        } else if (event.type == 8) {
          return "red";
        } else {
          return "light-blue";
        }
      }
    },
    showEvent({ nativeEvent, event }) {
      console.log(event);
      this.dialogClass = false;
      this.dialogGroup = false;
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    removeStudentConfirm() {
      this.dialogInfo = false;
      this.student = {};
      this.formClass.idLevel = "";
      this.formClass.idStudent = "";
      this.formClass.idSubjectTeacher = "";
      this.formClass.numberStudents = "";
      this.formClass.type = "";
      this.formClass.timeEnd = "";
      this.formClass.timeStart = "";
      this.formClass.date = "";
      this.formClass.idReservationStudent = 0;
      this.formClass.updateTimeStart = "";
      this.formClass.updateTimeEnd = "";
      this.payments = [];
      this.selectedOpen = false;
      this.receipt = "";
      this.numberClass = 0;

      this.setStudentData(null);
    },
    removeStudent() {
      this.dialogInfo = true;
    },
    async insertLesson(data) {
      try {
        let response = await axios.post("lessons/insertLesson", data);
        if (response.status == 200) {
          this.notification("success", response.data["msg"]);
          this.closeClass();
          console.log(response.data);
          this.selectGroupTeacher();
          if (this.numberClass == 0) {
            this.formClass.idReservationStudent =
              response.data["idReservationStudent"];
          }
          this.numberClass++;
          if (this.student.hrsRestante != undefined) {
            this.student.hrsRestante = data.hoursLeft;
            this.setStudentData(this.student);
          }
          this.loadingData = false;
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
        this.loadingData = false;
      }
    },
    saveClass() {
      if (this.$refs.formClass.validate()) {
        const datetimestart =
          this.formClass.date + " " + this.formClass.timeStart + ":00";
        const datetimeend =
          this.formClass.date + " " + this.formClass.timeEnd + ":00";

        let result = null;

        if (this.student.hrsRestante != undefined) {
          const timeStart = new Date(datetimestart);
          const timeEnd = new Date(datetimeend);

          if (this.student.hrsRestante == 0) {
            this.notification(
              "warning",
              `Ya se asignaron todas las horas no quedan minutos por asignar.`
            );
            return;
          }

          result = (timeEnd.getTime() - timeStart.getTime()) / 1000 / 60;
          const validate = this.student.hrsRestante - result;

          if (validate < 0) {
            this.notification(
              "warning",
              `Solo tiene ${this.student.hrsRestante} minutos para asignar, por favor vuelva a asignar el horario.`
            );
            return;
          }

          result = validate;
        }
        //console.log(`timeEnd: ${timeEnd.getTime()} \ntimeStart: ${timeStart.getTime()} \ntimeResult: ${(result/1000)/60}`);
        let data = {
          idTeacher: this.$route.params.idTeacher,
          idSubject: this.formClass.idSubject,
          idStudent: this.student.idUsuario,
          idLevel: this.student.idLevel,
          startDate: datetimestart,
          endDate: datetimeend,
          idReservation: this.formClass.idReservationStudent,
          numberStudent: this.student.numberStudents,
          description: this.formClass.description.trim().toUpperCase(),
          virtual: this.formClass.virtual,
          hoursLeft: result,
          idDepartament: this.idDepartament,
          statusType:
            this.formClass.idReservationStudent > 0 && this.studentData == null
              ? this.formClass.type
              : 0,
        };

        this.loadingData = true;
        this.insertLesson(data);
      }
    },
    clickRowSearchStudent(item) {
      if (this.$refs.formStudent.validate()) {
        this.student = Object.assign({}, item, {
          idLevel: this.formClass.idLevel,
          sigla: this.lessonLevels.find(
            (value) => value.idNivel === this.formClass.idLevel
          ).sigla,
          numberStudents: this.formClass.numberStudents,
        });
        this.closeStudentClass();
      }
    },
    optionChange(evt) {
      this.optionName = evt.option;
      this.formSearchStudent.value = "";
    },
    searchStudentByParams() {
      let names = "";
      let surnames = "";
      let ci = "";
      if (this.formSearchStudent.option.idOption == 1) {
        names = null;
        surnames = null;
        ci =
          this.formSearchStudent.value == ""
            ? null
            : this.formSearchStudent.value;
      } else if (this.formSearchStudent.option.idOption == 2) {
        names = null;
        surnames =
          this.formSearchStudent.value == ""
            ? null
            : this.formSearchStudent.value;
        ci = null;
      } else if (this.formSearchStudent.option.idOption == 3) {
        names =
          this.formSearchStudent.value == ""
            ? null
            : this.formSearchStudent.value;
        surnames = null;
        ci = null;
      }
      let url = `${this.idDepartament}/${names}/${surnames}/${ci}`;
      this.loadingSearch = true;
      this.selectSearchStudent(url);
    },
    async selectSearchStudent(url) {
      try {
        let response = await axios.get(`users/selectSearchStudentClass/${url}`);
        if (response.status == 200) {
          this.searchStudents = response.data;
          this.loadingSearch = false;
          if (response.data.length <= 0) {
            this.notification("primary", "No se encontro registros");
          }
        }
      } catch (error) {
        console.log(error);
        this.loadingSearch = false;
      }
    },
    async insertGroupTeacher(data) {
      try {
        let response = await axios.post(
          "groupTeachers/insertGroupTeacher",
          data
        );
        if (response.status == 200) {
          this.notification("success", response.data["msg"]);
          this.selectGroupByYear();
          this.selectGroupTeacher();
          this.dialogGroup = false;
          this.formGroup.idGroup = "";
          this.formGroup.weeks = "";
          this.formGroup.dateEnd = "";
          this.formGroup.dateStart = "";
          this.loadingData = false;
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
        this.loadingData = false;
      }
    },
    async insertActivitieTeacher(data) {
      try {
        let response = await axios.post(
          "activities/insertActivitieTeacher",
          data
        );
        if (response.status == 200) {
          this.notification("success", response.data["msg"]);
          this.selectGroupByYear();
          this.selectGroupTeacher();
          this.dialogGroup = false;
          this.formGroup.idGroup = "";
          this.formGroup.weeks = "";
          this.formGroup.dateEnd = "";
          this.formGroup.dateStart = "";
          this.activitiesSwitch = false;
          this.loadingData = false;
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
        this.loadingData = false;
      }
    },
    saveGroupSchedule() {
      //const [hour,minute] = this.formGroup.timeStart.split(':');
      //const [hour2,minute2] = this.formGroup.timeEnd.split(':');

      if (this.$refs.formGroup.validate()) {
        const datetimestart =
          this.formGroup.date + " " + this.formGroup.timeStart + ":00";
        const datetimeend =
          this.formGroup.date + " " + this.formGroup.timeEnd + ":00";

        this.loadingData = true;
        if (this.activitiesSwitch) {
          let data = {
            description: "BLOQUEADO",
            idTeacher: this.$route.params.idTeacher,
            startDate: datetimestart,
            endDate: datetimeend,
            weeks: this.formGroup.weeks,
            idDepartament: this.idDepartament,
          };

          this.insertActivitieTeacher(data);
        } else {
          let data = {
            idGroup: this.formGroup.idGroup,
            idTeacher: this.$route.params.idTeacher,
            startDate: datetimestart,
            endDate: datetimeend,
            weeks: this.formGroup.weeks,
          };

          this.insertGroupTeacher(data);
        }
      }
    },
    groupFilter(item, queryText) {
      const textOne = item.nombreGrupo.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    setToday() {
      this.focus = "";
      if (this.studentData != null) {
        this.today = new Date().toISOString().substring(0, 10);
      }
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    startDrag({ event, timed }) {
      //console.log(event);
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        const mouseRounded = this.roundTime(mouse, false);
        this.createStart = this.roundTime(mouse);

        let date = new Date(mouse);
        date.setHours(date.getHours() - 4);

        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        const dateStart = new Date(min);
        const dateEnd = new Date(max);

        //console.log(mouse);
        this.createEvent = {
          name: `Event #${this.events.length}`,
          color: "#03A9F4",
          start: min,
          end: max,
          timed: true,
        };

        if (Object.keys(this.student).length != 0) {
          this.formClass.date = date.toISOString().substring(0, 10);
          this.formClass.timeStart =
            (dateStart.getHours() < 10 ? "0" : "") +
            dateStart.getHours() +
            ":" +
            ((dateStart.getMinutes() < 10 ? "0" : "") + dateStart.getMinutes());
          this.formClass.timeEnd =
            (dateEnd.getHours() < 10 ? "0" : "") +
            dateEnd.getHours() +
            ":" +
            ((dateEnd.getMinutes() < 10 ? "0" : "") + dateEnd.getMinutes());
        } else {
          this.formGroup.date = date.toISOString().substring(0, 10);
          this.formGroup.timeStart =
            (dateStart.getHours() < 10 ? "0" : "") +
            dateStart.getHours() +
            ":" +
            ((dateStart.getMinutes() < 10 ? "0" : "") + dateStart.getMinutes());
          this.formGroup.timeEnd =
            (dateEnd.getHours() < 10 ? "0" : "") +
            dateEnd.getHours() +
            ":" +
            ((dateEnd.getMinutes() < 10 ? "0" : "") + dateEnd.getMinutes());
        }

        this.events.push(this.createEvent);
        this.eventItem = this.createEvent;
        //console.log(this.createEvent);
      }
    },
    //al extender la nota
    extendBottom(event) {
      //console.log(event);
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        let date = new Date(mouse);
        date.setHours(date.getHours() - 4);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        const dateStart = new Date(min);
        const dateEnd = new Date(max);

        this.createEvent.start = min;
        this.createEvent.end = max;

        if (Object.keys(this.student).length != 0) {
          this.formClass.date = date.toISOString().substring(0, 10);
          this.formClass.timeStart =
            (dateStart.getHours() < 10 ? "0" : "") +
            dateStart.getHours() +
            ":" +
            ((dateStart.getMinutes() < 10 ? "0" : "") + dateStart.getMinutes());
          this.formClass.timeEnd =
            (dateEnd.getHours() < 10 ? "0" : "") +
            dateEnd.getHours() +
            ":" +
            ((dateEnd.getMinutes() < 10 ? "0" : "") + dateEnd.getMinutes());
        } else {
          this.formGroup.date = date.toISOString().substring(0, 10);
          this.formGroup.timeStart =
            (dateStart.getHours() < 10 ? "0" : "") +
            dateStart.getHours() +
            ":" +
            ((dateStart.getMinutes() < 10 ? "0" : "") + dateStart.getMinutes());
          this.formGroup.timeEnd =
            (dateEnd.getHours() < 10 ? "0" : "") +
            dateEnd.getHours() +
            ":" +
            ((dateEnd.getMinutes() < 10 ? "0" : "") + dateEnd.getMinutes());
        }
      }
    },
    endDrag() {
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;

      if (Object.keys(this.student).length != 0) {
        this.dialogClass = true;
      } else {
        this.dialogGroup = true;
      }
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 30; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    /*getEventColor (event) {
            console.log(event);
            /*const rgb = parseInt(event.color.substring(1), 16)
            const r = (rgb >> 16) & 0xFF
            const g = (rgb >> 8) & 0xFF
            const b = (rgb >> 0) & 0xFF

            return event === this.dragEvent
            ? `rgba(${r}, ${g}, ${b}, 0.7)`
            : event === this.createEvent
                ? `rgba(${r}, ${g}, ${b}, 0.7)`
                : event.color*
        },*/
    getEvents({ start, end }) {
      const endDate = new Date(end.date);
      endDate.setDate(endDate.getDate() + 1);

      this.startDate = start.date;
      this.endDate = endDate.toISOString().substring(0, 10);

      this.selectGroupTeacher();
      this.titleCalendar = this.$refs.calendar.title.toUpperCase();
      /*const events = []

            const min = new Date(`${start.date}T08:00:00`).getTime()
            const max = new Date(`${end.date}T23:59:59`).getTime()
            const days = (max - min) / 86400000
            const eventCount = this.rnd(days, days + 20)

            for (let i = 0; i < eventCount; i++) {
            const timed = this.rnd(0, 3) !== 0
            const firstTimestamp = this.rnd(min, max)
            const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000
            const start = firstTimestamp - (firstTimestamp % 900000)
            const end = start + secondTimestamp

            events.push({
                name: this.rndElement(this.names),
                color: this.rndElement(this.colors),
                start,
                end,
                timed,
            })
            }*/

      //this.events = events
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    async selectGroupTeacher() {
      try {
        this.events = [];
        let response = await axios.get(
          `groupTeachers/selectGroupTeacher/${this.idDepartament}/${this.$route.params.idTeacher}/${this.startDate}/${this.endDate}`
        );
        let response2 = await axios.get(
          `lessons/selectLessons/${this.idDepartament}/${this.$route.params.idTeacher}/${this.startDate}/${this.endDate}`
        );
        let response3 = await axios.get(
          `activities/selectActivitieTeachers/${this.idDepartament}/${this.$route.params.idTeacher}/${this.startDate}/${this.endDate}`
        );
        if (response.status == 200 && response2.status == 200) {
          if (response.data.length > 0) {
            console.log(response.data);
            response.data.map((value) => {
              value.fechaInicial = this.formatDateTime(value.fechaInicial);
              value.fechaFinal = this.formatDateTime(value.fechaFinal);

              this.events.push({
                idGroupTeacher: value.idGrupoDocente,
                idSchedule: value.idCalendario,
                type: value.tipo,
                originalName: value.nombreGrupo,
                initialDate: value.fechaInicio,
                name: "Grupo - " + value.nombreGrupo,
                start: value.fechaInicial,
                end: value.fechaFinal,
              });
            });
          }
          console.log(response2.data);
          if (response2.data.length > 0) {
            response2.data.map((value) => {
              value.fechaInicial = this.formatDateTime(value.fechaInicial);
              value.fechaFinal = this.formatDateTime(value.fechaFinal);

              this.events.push({
                idStudent: value.idEstudiante,
                firstName: value.nombres,
                lastName: value.apellidos,
                ci: value.ci,
                idLevel: value.idNivel,
                nameLevel: value.nombreNivel,
                numberStudents: value.nroAlumnos,
                initials: value.sigla,
                description: value.descripcion,
                idClass: value.idClase,
                idSchedule: value.idCalendario,
                idSubject: value.idMateria,
                subject: value.nombreMateria,
                idReservationStudent: value.idReserva,
                idTeacher: value.idDocente,
                virtual: value.virtual,
                type: value.tipo,
                originalName: value.codigoMateria,
                name:
                  value.sigla +
                  " " +
                  value.nroAlumnos +
                  " - " +
                  value.codigoMateria +
                  "\n" +
                  value.nombres +
                  "\n" +
                  value.apellidos,
                start: value.fechaInicial,
                end: value.fechaFinal,
                phone: value.telefono,
              });
            });
          }
          if (response3.data.length > 0) {
            response3.data.map((value) => {
              value.fechaInicial = this.formatDateTime(value.fechaInicial);
              value.fechaFinal = this.formatDateTime(value.fechaFinal);

              this.events.push({
                idActivitieTeacher: value.idDocenteActividad,
                idSchedule: value.idCalendario,
                type: value.tipo,
                originalName: value.descripcion,
                name: value.descripcion,
                start: value.fechaInicial,
                end: value.fechaFinal,
              });
            });
          }
          //this.$refs.calendar.updateTimes();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectTeacher() {
      try {
        let response = await axios.get(
          `users/selectUserById/${this.$route.params.idTeacher}`
        );
        if (response.status == 200) {
          this.teacher = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectSubjectTeacher() {
      try {
        let response = await axios.get(
          `subjects/selectSubjectTeacher/${this.$route.params.idTeacher}/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.subjects = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectGroupByYear() {
      try {
        const year = new Date().getFullYear();
        let response = await axios.get(
          `groups/selectGroupByYear/${year}/${this.idDepartament}/${this.$route.params.idTeacher}`
        );
        if (response.status == 200) {
          console.log(response.data);
          this.groups = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectLessonLevels() {
      try {
        let response = await axios.get(
          `lessonLevels/selectLessonLevels/1/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.lessonLevels = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectPaymentTypes() {
      try {
        let response = await axios.get(
          `paymentTypes/selectPaymentType/1/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.paymentTypes = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    notification(type, text) {
      this.snackbar = true;
      this.colorSnackbar = type;
      this.textSnackbar = text;
    },
    closeGroupSchedule() {
      this.dialogGroup = false;
      this.formGroup.idGroup = "";
      this.formGroup.weeks = "";
      this.formGroup.dateEnd = "";
      this.formGroup.dateStart = "";
      this.formGroup.timeStart = "";
      this.formGroup.timeEnd = "";
      this.formGroup.date = "";

      const i = this.events.indexOf(this.eventItem);
      if (i !== -1) {
        this.events.splice(i, 1);
      }
      this.$refs.formGroup.resetValidation();
    },
    closeStudentClass() {
      this.dialogStudent = false;
      this.formSearchStudent.ci = "";
      this.formSearchStudent.names = "";
      this.formSearchStudent.surnames = "";
      this.formSearchStudent.value = "";
      this.formClass.idLevel = "";
      this.formClass.idStudent = "";
      this.formClass.idSubjectTeacher = "";
      this.formClass.numberStudents = "";
      this.formClass.type = "";
      this.formClass.updateTimeStart = "";
      this.formClass.updateTimeEnd = "";
      this.$refs.formStudent.resetValidation();
      this.searchStudents = [];
    },
    closeClass() {
      this.dialogClass = false;
      this.formClass.idLevel = "";
      this.formClass.idStudent = "";
      this.formClass.idSubjectTeacher = "";
      this.formClass.numberStudents = "";
      this.formClass.timeEnd = "";
      this.formClass.timeStart = "";
      this.formClass.updateTimeStart = "";
      this.formClass.updateTimeEnd = "";
      this.formClass.date = "";
      this.formClass.virtual = false;
      this.formClass.idSubject = "";
      this.formClass.description = "";
      this.$refs.formClass.resetValidation();
      const i = this.events.indexOf(this.eventItem);
      if (i !== -1) {
        this.events.splice(i, 1);
      }
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedOpen = false;
      this.selectedEvent = {};
      this.selectedElement = null;
    },
    closeEventUpdate() {
      this.dialogEventUpdate = false;
      this.formClass.idLevel = "";
      this.formClass.idStudent = "";
      this.formClass.idSubjectTeacher = "";
      this.formClass.numberStudents = "";
      this.formClass.timeEnd = "";
      this.formClass.timeStart = "";
      this.formClass.updateTimeStart = "";
      this.formClass.updateTimeEnd = "";
      this.formClass.date = "";
      this.formClass.idSubject = "";
      this.formClass.virtual = false;
      this.formClass.description = "";
      this.$refs.formClass.resetValidation();
    },
    closePayment() {
      this.dialogPayment = false;
      this.formPayment.balance = "";
      this.formPayment.price = "";
      this.formPayment.movingBalance = "";
      this.formPayment.discount = "0";
      this.formPayment.subTotal = "";
      this.formPayment.description = "";
      this.formPayment.idPayment = "";
      this.formPayment.monthlyPayment = "";
      this.formPayment.idMonthlyPayment = "";
      this.formPayment.monthlyAmount = "";
      this.formPayment.oldDiscount = "";
      this.formPayment.oldSubTotal = "";
      this.formPayment.idPaymentType = 1;
      this.$refs.form.resetValidation();
    },
    closePaymentDone() {
      this.dialogInfo = false;
      this.formClass.idLevel = "";
      this.formClass.idStudent = "";
      this.formClass.idSubjectTeacher = "";
      this.formClass.numberStudents = "";
      this.formClass.type = "";
      this.formClass.timeEnd = "";
      this.formClass.timeStart = "";
      this.formClass.updateTimeStart = "";
      this.formClass.updateTimeEnd = "";
      this.formClass.date = "";
      this.formClass.idReservationStudent = 0;
      this.payments = [];
      this.selectedOpen = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formatDateTime(datetime) {
      if (!datetime) return null;

      const date = datetime.substring(0, 10);
      const time = datetime.substring(11, 19);
      return date + " " + time;
    },
    formatDateTimeReceipt(date) {
      if (!date) return null;
      let dateSub = date.substring(0, 10);
      let timeSub = date.substring(11, 19);
      const [year, month, day] = dateSub.split("-");
      return `${day}-${month}-${year} ${timeSub}`;
    },
    formatDateTimeClass(start, end) {
      if (!start || !end) return null;
      let dateSub = start.substring(0, 10);
      let timeStart = start.substring(11, 16);
      let timeEnd = end.substring(11, 16);
      const [year, month, day] = dateSub.split("-");
      return `${day}-${month}-${year} (${timeStart} - ${timeEnd})`;
    },
  },
};
</script>

<style scoped lang="scss">
.block {
  display: flex;
  min-width: 100% !important;
  max-width: none;
  justify-content: left;
}
.text-medium {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.0178571429em;
}
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>
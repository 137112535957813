<template>
    <v-layout align-start>
        <v-container>
            <v-breadcrumbs class="pl-0" :items="items">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        :disabled="item.disabled"
                    >
                        {{ item.text}}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
            <v-card outlined tile class="pb-2">
                <v-card-title style="color: #1E88E5;" class="ml-2 pb-0">
                    Pagos
                </v-card-title>
                <v-card-text class="pb-0" >
                    <v-row class="ml-1">
                        <v-col cols="12" sm="12" md="12">
                            <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Grupo: <span class="caption ">{{group.nombreGrupo}}</span></p>
                            <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Ci: <span class="caption ">{{student.ci}}</span></p>
                            <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Nombres: <span class="caption">{{student.nombres}}</span></p>
                            <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Apellidos: <span class="caption">{{student.apellidos}}</span></p>
                            <p style="color:#4c4c4c;" class="mb-1 subtitle-2 font-weight-medium">Telefono: <span class="caption">{{student.telefono}}</span></p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-data-table
                :headers="headers"
                :items="payments"
                class="elevation-1 font-weight-light caption"
                
                fixed-header
            >
                <template v-slot:[`item.nroMensualidad`]="{item}">
                    <v-icon :color="!item.paymentComplete ? 'error':'success'">{{!item.paymentComplete ? 'mdi-close-circle' : 'mdi-check-circle'}}</v-icon> {{item.nroMensualidad}}
                </template>
                <template v-slot:[`item.estado`]="{item}">
                    {{item.estado ? 'Activo' : 'Inactivo'}}
                </template>
                <template v-slot:[`item.accion`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{on,attrs}">
                            <v-btn
                                icon
                                color="success"
                                class="mr-2"
                                @click="toPay(item)"
                                v-bind="attrs"
                                v-on="on"
                                :disabled="!item.paymentActive"
                            >
                                <v-icon >mdi-cash</v-icon>
                            </v-btn>
                        </template>
                        <span>Pagar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{on,attrs}">
                            <v-btn
                                icon
                                color="primary"
                                class="mr-2"
                                @click.stop="editItem(item)"
                                v-bind="attrs"
                                v-on="on"
                                :disabled="!item.active"
                            >
                                <v-icon >mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{on,attrs}">
                            <v-btn
                                icon
                                color="primary"
                                class="mr-2"
                                @click.stop="generateReport(item)"
                                v-bind="attrs"
                                v-on="on"
                                :disabled="!item.paymentComplete"
                            >
                                <v-icon >mdi-printer</v-icon>
                            </v-btn>
                        </template>
                        <span>Imprimir Recibo</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-if="user.idRol == 1" v-slot:activator="{on,attrs}">
                            <v-btn
                                icon
                                :color="item.estado ? 'error' : 'success'"
                                class="mr-2"
                                @click="deleteItem(item)"
                                v-bind="attrs"
                                v-on="on"
                                :disabled="!item.active"
                            >
                                <v-icon v-if="user.idRol == 1" >{{item.estado ? 'mdi-cancel' : 'mdi-check'}}</v-icon>
								<v-icon v-else >mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="user.idRol == 1">{{item.estado ? 'Desactivar' : 'Activar'}}</span>
						<span v-else>Eliminar</span>
                    </v-tooltip>
                </template>
            </v-data-table>
            <v-dialog
				v-model="dialog"
				max-width="600px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-form ref="form">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        class="py-0"
                                    >
                                        <p class="mb-2">Total a pagar</p>
                                        <v-text-field
                                            v-model="formPayment.subTotal"
                                            class="pt-0"
                                            placeholder="Total"
                                            single-line
                                            dense
                                            outlined
                                            :disabled="disabled"
											@blur="calculateBalance"
                                            :rules="subTotalRules"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        class="py-0"
                                    >
                                        <p class="mb-2">Descuento</p>
                                        <v-text-field
                                            v-model="formPayment.discount"
                                            class="pt-0"
                                            placeholder="Descuento"
                                            single-line
                                            dense
                                            outlined
											@blur="calculateBalance"
                                            :rules="discountRules"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        class="py-0"
                                    >
                                        <p class="mb-2">Saldo</p>
                                        <v-text-field
                                            v-model="movingBalance"
                                            class="pt-0"
                                            placeholder="Saldo"
                                            single-line
                                            dense
                                            outlined
                                            disabled
                                        >
                                        </v-text-field>
                                    </v-col>
									<v-col
										cols="12"
										sm="6"
										md="6"
										class="py-0"
									>
										<p class="mb-2">Tipo Pago</p>
										<v-select
											v-model="formPayment.idPaymentType"
											:items="paymentTypes"
											item-text="nombreTipoPago"
											item-value="idTipoPago"
											label="Seleccione"
											persistent-hint
											dense
											outlined
											single-line
											:rules="paymentTypeRules"
										></v-select>
									</v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        class="py-0"
                                    >
                                        <p class="mb-2">Descripcion</p>
                                        <v-text-field
                                            v-model="formPayment.description"
                                            class="pt-0"
                                            placeholder="Descripcion"
                                            single-line
                                            dense
                                            outlined
                                        >
                                        </v-text-field>

                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        :disabled="loadingData"
                        @click="close"
                    >
                        <span class="text-capitalize">Cancelar</span>
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        dark
                        :loading="loadingData"
                        @click="savePayment"
                    >
                        <span class="text-capitalize">Guardar</span>						
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="400px">
                <v-card>
                    <p style="color: #1874BF" class="text-h6 text-center pt-4">Informe de Pago</p>
                    <v-card-text class="text-center">
                        ¿Esta seguro de eliminar el pago con el monto {{editedItem.cancelado}} Bs.?
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
                        <v-btn dark color="blue darken-1" @click="deleteItemConfirm">Si</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-snackbar
				v-model="snackbar"
				:color="colorSnackbar"
                text
			>
				<span class="text-center">{{ textSnackbar }}</span>
			</v-snackbar>
			<ReceiptPayment ref="receipt" :itemPayment="itemPayment" :student="student" :user="user" :group="group"/>
        </v-container>
    </v-layout>
</template>

<script>
import axios from 'axios';
import ReceiptPayment from '@/components/receipt/receipt-payment-component.vue';
import { mapState } from 'vuex';
export default {
    components: {
        ReceiptPayment
    },
    data(){
        return {
            semester: {},
            group: {}, 
            items: [
				{
					text: 'Semestre',
					disabled: false,
					href: '/semester',
				},
                {
					text: 'Grupos',
					disabled: false,
					href: `/semester/${this.$route.params.idSemester}/group`,
				},
			],
            student: {},
            course: {},
            payments: [], 
            headers: [
				{text: "Nro Pagos", value: "nroMensualidad", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Mensualidad Bs.", value: "monto", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Descuento Bs.", value: "descuento", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Total Bs.", value: "saldo", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Cancelado Bs.", value: "cancelado", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Fecha Límite", value: "fechaRegistro", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Acciones", value: "accion", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			],
            balanceRules: [
				value => !!value || 'Ingrese un saldo',
                value => (!isNaN(value)) || 'Debe ingresar un número',
			],
            discountRules: [
                value => !!value || 'Ingrese un descuento',
                value => (!isNaN(value)) || 'Debe ingresar un número',
            ],
            subTotalRules: [
                value => !!value || 'Ingrese un total',
                value => (!isNaN(value)) || 'Debe ingresar un número',
            ],
			paymentTypeRules: [
                value => !!value || 'Seleccione un Tipo de Pago'
            ],
            dialog: false,
            loadingData: false,
            formPayment: {
                idPayment: '',
                paymentDate: new Date().toISOString().substring(0, 10),
                discount: '0',
                oldDiscount: '',
                balance: '',
                subTotal: '',
                oldSubTotal: '',
                description: '',
                monthlyPayment: '',
                monthlyAmount: '',
                idMonthlyPayment: '',
                idUser: '',
                idGruopUser: '',
				idPaymentType: 1,
				movingBalance: '',
            },
            editedIndex: -1,
            editedItem: {},
            dialogDelete: false,
            snackbar: false,
            colorSnackbar: '',
            textSnackbar: '',
            htmlToPdfOptions: {
                margin: [0.5,0.5,0.5,0.5],
            
				filename: `Recibo N°.pdf`,
            
                image: {
                    type: 'jpeg', 
                    quality: 0.98
                },
            
                enableLinks: false,
            
                html2canvas: {
                    scale: 1,
                    useCORS: true
                },
            
                jsPDF: {
                    unit: 'in',
                    format: 'letter',
                    orientation: 'portrait'
                }
            },
            itemPayment: {},
			idPayment: '',
			paymentTypes: [],
            disabled: false,
        }
    },
    computed: {
        ...mapState(['idDepartament','user']),
        nombreGrupo(){
            return this.group.nombreGrupo;
        },
        formTitle () {
			return this.editedIndex === -1 ? 'Registrar Pago' : 'Editar Pago';
		},
		movingBalance(){
			return Number(this.formPayment.balance) - (Number(this.formPayment.subTotal) + Number(this.formPayment.discount));
		}
    },
    created(){
        this.selectGroupById();
        //this.selectSemesterById();
        this.selectUserById();
        this.selectMonthlyPayments();
		this.selectPaymentTypes();
    },
    methods: {
		calculateBalance(){
			this.formPayment.movingBalance = Number(this.formPayment.balance) - (Number(this.formPayment.subTotal) + Number(this.formPayment.discount));
		},
        generateReport(item) {
            this.itemPayment = item;
            this.$refs.receipt.generatePdf();
        },
        clickRow(item){
			this.$router.push(`/semester/${this.$route.params.idSemester}/group/${this.$route.params.idGroup}/student/${item.idUsuario}`);
		},
        toPay(item){
            this.formPayment.balance = item.saldo;
			this.formPayment.movingBalance = item.saldo;
            this.formPayment.monthlyPayment = item.nroMensualidad;
            this.formPayment.idUser = this.user.idUsuario;
            this.formPayment.idMonthlyPayment = item.idMensualidad;
            this.formPayment.idGruopUser = item.idGrupoUsuario;
            this.dialog = true;
        },
        async deletePaymentStudent(data){
            try {
                let response = await axios.put('payments/deletePayment',data);
                if(response.status == 200){
                    this.closeDelete();
                    this.notification('success',response.data['msg']);
                    this.selectMonthlyPayments();
                }
            } catch (error) {
                console.log(error);
                this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
            }
        },
        async updatePayment(data) {
            try {
                let response = await axios.put('payments/updatePayment',data);
                if (response.status == 200) {
                    this.close();
                    this.notification('success',response.data['msg']);
                    this.selectMonthlyPayments();
                    this.loadingData = false;
                }
            } catch (error) {
                console.log(error);
                this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
                this.loadingData = false;
            }
        },
        editItem(item){
            this.editedIndex = 1;
            this.disabled = this.user.idRol == 1 ? false : true;
            this.formPayment.idPayment = item.idPago;
            this.formPayment.idMonthlyPayment = item.idMensualidad;
            this.formPayment.idGruopUser = item.idGrupoUsuario;
            this.formPayment.description = item.descripcion;
            this.formPayment.subTotal = item.monto.toString();
            this.formPayment.balance = item.saldo+item.monto+item.descuento;
			this.formPayment.movingBalance = item.saldo+item.monto+item.descuento;
            this.formPayment.discount = item.descuento.toString();
            this.formPayment.monthlyAmount = item.montoMensualidad;
            this.formPayment.oldDiscount = item.descuento;
            this.formPayment.oldSubTotal = item.monto;
			this.formPayment.idPaymentType = item.idTipoPago;
            this.dialog = true;
        },
        deleteItem (item) {
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm () {
			let data = {
                state: 0,
                idPayment: this.editedItem.idPago,
                idMonthlyPayment: this.editedItem.idMensualidad,
                subTotal: this.editedItem.monto,
                discount: this.editedItem.descuento,
                balance: this.editedItem.saldo,
                monthlyAmount: this.editedItem.montoMensualidad,
			}
			this.deletePaymentStudent(data);
		},
        async insertPayment(data) {
            try {
                let response = await axios.post('payments/insertPayment',data);
                if (response.status == 200) {
                    console.log(response);
                    this.close();
                    this.notification('success',response.data['msg']);
					this.idPayment = response.data['idPago'];
                    this.selectMonthlyPayments();
                    this.loadingData = false;
                }
            } catch (error) {
                console.log(error);
                this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
                this.loadingData = false;
            }
        },  
        savePayment(){
            if (this.$refs.form.validate()) {
				this.idPayment = '';
                if (this.formPayment.balance < this.formPayment.discount) {
                    this.notification('warning','El descuento no puede ser mayor al saldo');
                    return;
                }

                if (this.formPayment.balance < this.formPayment.subTotal) {
                    this.notification('warning','El total a pagar no debe ser mayor al saldo');
                    return;
                }

                if ((this.formPayment.balance < Number(this.formPayment.subTotal)+Number(this.formPayment.discount))) {
                    this.notification('warning','La suma del total mas el descuento no debe ser mayor al saldo');
                    return;
                }
				if (Number(this.formPayment.subTotal) == 0 && Number(this.formPayment.discount) == 0) {
					this.notification('warning','El total a pagar o el descuento deben tener un valor mayor a 0');
					return;
				}

                this.loadingData = true;
                if (this.editedIndex > -1) {
                    let data = {
                        idPayment: this.formPayment.idPayment,
                        idMonthlyPayment: this.formPayment.idMonthlyPayment,
						idPaymentType: this.formPayment.idPaymentType,
                        idGroupUser: this.formPayment.idGruopUser,
                        description: this.formPayment.description,
                        subTotal: this.formPayment.subTotal,
                        balance: this.formPayment.balance,
                        discount: this.formPayment.discount,
                        monthlyAmount: this.formPayment.monthlyAmount,
                        oldDiscount: this.formPayment.oldDiscount,
                        oldSubTotal: this.formPayment.oldSubTotal,
                    }
                    
                    this.updatePayment(data);
                } else {
                    let data = {
                        paymentDate: this.formPayment.paymentDate,
                        discount: this.formPayment.discount,
                        balance: this.formPayment.balance,
                        subTotal: this.formPayment.subTotal,
                        description: this.formPayment.description,
                        monthlyPayment: this.formPayment.monthlyPayment,
                        idMonthlyPayment: this.formPayment.idMonthlyPayment,
                        idUser: this.formPayment.idUser,
						idPaymentType: this.formPayment.idPaymentType,
                        idDepartament: this.idDepartament,
                        idGroupUser: this.formPayment.idGruopUser,
                    }
                    
                    this.insertPayment(data);
                }
            }
        },
        async selectUserById(){
            try {
                let response = await axios.get(`users/selectUserById/${this.$route.params.idStudent}`);
                if (response.status == 200) {
                    this.student = response.data;
                    let names = this.student.nombres.split(' ');
                    let surnames = this.student.apellidos.split(' ');
                    this.items.push(
                        {
                            text: names[0]+' '+surnames[0],
                            disabled: true,
                            href: '/'
                        },
                    );
                }
            } catch (error) {
                console.log(error);
            }
        },
        async selectSemesterById() {
            try {
                let response = await axios.get(`semesters/selectSemesterById/${this.$route.params.idSemester}`);
                if (response.status == 200) {
                    this.semester = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async selectStudentCourseByIdGroupAndIdUser(group){
            let date = new Date(group.fechaInicio);
                    date.setDate(date.getDate()+1);

                    console.log(date);
                    for (let index = 0; index < group.cuotas; index++) {
                       
                        this.payments.push(
                            {
                                nro: index+1,
                                mensualidad: group.costo,
                                descuento: 0,
                                total: group.costo,
                                cancelado: 0,
                                fechaLimite: date.toLocaleDateString(),
                                estado: true,
                            }
                        );
                        date.setMonth(date.getMonth()+1)
                        
                    }
            try {
                let response = await axios.get(`courses/selectStudentCourseByIdGroupAndIdUser/${this.$route.params.idGroup}/${this.$route.params.idStudent}`);
                if (response.status == 200) {
                    this.course = response.data;
                    this.selectMonthlyPayments(response.data['idGrupoUsuario']);
                }
            } catch (error) {
                console.log(error);
            }
        }, 
        async selectPayments(idMonthlyPayment){
            let response = await axios.get(`payments/selectPayments/${idMonthlyPayment}`);
            return response;
        },
        async selectPaymentsByIdGroupAndIdUser(idGroup,idUser){
            let response = await axios.get(`payments/selectPaymentsByIdGroupAndIdUser/${idGroup}/${idUser}`);
            return response;
        },
        async selectMonthlyPayments() {
            try {
                this.payments = [];
                let response = await axios.get(`monthlypayments/SelectMonthlyPayments/${this.$route.params.idGroup}/${this.$route.params.idStudent}`);
                console.log(response.data);
                let response2 = await axios.get(`payments/selectPaymentsByIdGroupAndIdUser/${this.$route.params.idGroup}/${this.$route.params.idStudent}`);
                if (response.status == 200 && response2.status == 200) {
                    if (response2.data.length > 0) {
                        response.data.map((value) => {
                            value.fechaRegistro = this.formatDate(value.fechaRegistro);
                        });
                        response2.data.map((value,index) => {
                            value.fechaRegistro = this.formatDate(value.fechaRegistro);
                            value.fechaPago = this.formatDateTime(value.fechaPago);
                            this.payments.push({
                                cancelado: value.subTotal,
                                estado: value.estado,
                                fechaRegistro: value.fechaRegistro,
                                fechaPago: value.fechaPago,
                                idGrupoUsuario: value.idGrupoUsuario,
                                idMensualidad: value.idMensualidad, 
                                monto: value.subTotal,
                                montoMensualidad: value.monto,
                                descuento: value.descuento,
                                descripcion: value.descripcion,
                                nroMensualidad: value.nroMensualidad,
                                idPago: value.idPago,
                                codigo: value.codigo,
								acuenta: value.acuenta,
                                saldo: value.saldo,
                                active: index+1 == response2.data.length ? true : !value.cancelado,
                                paymentActive: !value.cancelado,
								paymentComplete: value.cancelado,
								idTipoPago: value.idTipoPago,
								tipoPago: value.nombreTipoPago,
                                nombres: value.nombres,
                                apellidos: value.apellidos,
                            });

                            if (index+1 == response2.data.length) {
                                if(value.saldo > 0){
                                    this.payments.push({
                                        cancelado: 0,
                                        estado: value.estado,
                                        fechaRegistro: value.fechaRegistro,
                                        fechaPago: value.fechaPago,
                                        idGrupoUsuario: value.idGrupoUsuario,
                                        idMensualidad: value.idMensualidad, 
                                        monto: value.saldo,
                                        montoMensualidad: value.monto,
                                        descuento: 0,
                                        nroMensualidad: value.nroMensualidad,
                                        saldo: value.saldo,
                                        active: false,
                                        paymentActive: true,
										paymentComplete: false,
                                    });
                                }
                                for (let i = value.nroMensualidad; i < response.data[0].cuotas; i++) {
                                    this.payments.push({
                                        cancelado: 0,
                                        estado: response.data[i].estado,
                                        fechaRegistro: response.data[i].fechaRegistro,
                                        idGrupoUsuario: response.data[i].idGrupoUsuario,
                                        idMensualidad: response.data[i].idMensualidad, 
                                        monto: response.data[i].monto,
                                        descuento: 0,
                                        nroMensualidad: response.data[i].nroMensualidad,
                                        saldo: response.data[i].saldo,
                                        active: false,
                                        paymentActive: response.data[i].cancelado,
										paymentComplete: false
                                    });
                                }

                            }
                        });
                    }else{
                        response.data.map((value,index) => {
                            if (index == 0 && value.cancelado == false) {
                                value.cancelado = true;
                            }
                            value.fechaRegistro = this.formatDate(value.fechaRegistro);
                            this.payments.push({
                                cancelado: 0,
                                estado: value.estado,
                                fechaRegistro: value.fechaRegistro,
                                idGrupoUsuario: value.idGrupoUsuario,
                                idMensualidad: value.idMensualidad, 
                                monto: value.monto,
                                descuento: 0,
                                nroMensualidad: value.nroMensualidad,
                                saldo: value.saldo,
                                active: false,
                                paymentActive: value.cancelado,
								paymentComplete: false
                            });
                        });
                    }

					if (this.idPayment > 0) {
						const item = this.payments.find(value => value.idPago === this.idPayment);
						this.generateReport(item);
					}

                    //let dataResponse = [];
                    /*response.data.map(async (value,index) => {
                        value.fechaRegistro = this.formatDate(value.fechaRegistro);

                        let payment = await this.selectPayments(value.idMensualidad);

                        if (payment.data.length > 0) {
                            let paymentTotal = payment.data.length;
                            console.log('payment total'+paymentTotal);
                            payment.data.map((value2,index2) => {
                                console.log('index2'+index2);
                                this.payments.push({
                                    cancelado: value2.subTotal,
                                    estado: value.estado,
                                    fechaRegistro: value.fechaRegistro,
                                    idGrupoUsuario: value.idGrupoUsuario,
                                    idMensualidad: value2.idMensualidad, 
                                    monto: value2.subTotal,
                                    descuento: value2.descuento,
                                    descripcion: value2.descripcion,
                                    nroMensualidad: value2.nroMensualidad,
                                    idPago: value2.idPago,
                                    saldo: value2.saldo,
                                    active: paymentTotal == index2 ? true : !value2.cancelado,
                                    paymentActive: !value2.cancelado
                                });
                            });

                            if(value.saldo > 0){
                                this.payments.push({
                                cancelado: 0,
                                estado: value.estado,
                                fechaRegistro: value.fechaRegistro,
                                idGrupoUsuario: value.idGrupoUsuario,
                                idMensualidad: value.idMensualidad, 
                                monto: value.saldo,
                                descuento: 0,
                                nroMensualidad: value.nroMensualidad,
                                saldo: value.saldo,
                                active: false,
                                paymentActive: true
                            });
                            }
                        }
                        else{
                            if (index == 0 && value.cancelado == false) {
                                value.cancelado = true;
                            }
                            this.payments.push({
                                cancelado: 0,
                                estado: value.estado,
                                fechaRegistro: value.fechaRegistro,
                                idGrupoUsuario: value.idGrupoUsuario,
                                idMensualidad: value.idMensualidad, 
                                monto: value.monto,
                                descuento: 0,
                                nroMensualidad: value.nroMensualidad,
                                saldo: value.saldo,
                                active: false,
                                paymentActive: value.cancelado
                            });
                        }
                        
                    });   */     
                }
            } catch (error) {
                console.log(error);
            }
        },
        async selectGroupById(){
            try {
                let response = await axios.get(`groups/selectGroupById/${this.$route.params.idGroup}`);
                if (response.status == 200) {
                    this.group = response.data;
					if(this.$route.params.idSemester > 0){
						this.items.push(
							{
								text: this.group.nombreGrupo,
								disabled: true,
								href: '/'
							},
						);
					}else {
						this.items = [
							{
								text: 'Pago Mensualidades',
								disabled: false,
								href: '/monthly-pay-student'
							},
							{
								text: this.group.nombreGrupo,
								disabled: true,
								href: '/'
							},
						]
					}
                    
                    //this.selectMonthlyPayments(response.data);
                    //this.selectStudentCourseByIdGroupAndIdUser(response.data);
                }
            } catch (error) {
                console.log(error);
            }
        },
		async selectPaymentTypes(){
			try {
				let response = await axios.get(`paymentTypes/selectPaymentType/1/${this.idDepartament}`);
				if (response.status == 200) {
					this.paymentTypes = response.data;
				}
			} catch (error) {
				console.log(error);
			}
		},
        close(){
            this.dialog = false;
            this.disabled = false;
            this.$refs.form.resetValidation();
            this.editedIndex = -1;
            this.formPayment.balance = '';
			this.formPayment.movingBalance = '';
            this.formPayment.discount = '0';
            this.formPayment.subTotal = '';
            this.formPayment.description = '';
            this.formPayment.idPayment = '';
            this.formPayment.monthlyPayment = '';
            this.formPayment.idMonthlyPayment = '';
            this.formPayment.idGruopUser = '';
            this.formPayment.monthlyAmount = '';
            this.formPayment.oldDiscount = '';
            this.formPayment.oldSubTotal = '';
			this.formPayment.idPaymentType = 1;
        },
        closeDelete () {
			this.dialogDelete = false;
			this.editedItem = '';
		},
        notification(type,text){
			this.snackbar = true;
			this.colorSnackbar = type;
			this.textSnackbar = text;
		},
        formatDate (date) {
            if (!date) return null;
            let dateSub = date.substring(0, 10);
            const [year, month, day] = dateSub.split('-');
            return `${day}/${month}/${year}`;
        },
		formatDateTime(date){
			if (!date) return null;
			let dateSub = date.substring(0,10);
			let timeSub = date.substring(11,19);
			const [year,month,day] = dateSub.split('-');
			return `${day}-${month}-${year} ${timeSub}`
		}
    }
}
</script>

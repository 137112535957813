<template>
  <!--vue-html2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="true"
    :preview-modal="true"
    :paginate-elements-by-height="1200"
    :pdf-quality="1"
    :manual-pagination="false"
    pdf-format="letter"
    pdf-orientation="landscape"
    pdf-content-width="800px"
    :html-to-pdf-options="htmlToPdfOptions"
    @progress="progress"
    ref="html2Pdf"
  -->
  <div id="pdf" ref="pdf" class="mx-5 section" slot="pdf-content">
    <div ref="header" class="row mt-0 pl-0">
      <div class="col-6 pb-0 pl-0 pt-0">
        <p class="mt-0 mb-0 text-small section">{{ departament }} - BOLIVIA</p>
        <p
          v-if="user.idDepartamento == idDepartament"
          class="mt-0 mb-0 text-medium section"
        >
          {{ user.sucursal }}
        </p>
        <p
          v-if="user.idDepartamento == idDepartament"
          class="mt-0 mb-0 text-medium section"
        >
          {{ user.telefonoSucursal }}
        </p>
      </div>
      <div class="col-6 pb-0">
        <div class="row justify-content-end">
          <img
            :src="require('../../assets/blueLogo2.png')"
            height="80"
            alt="..."
          />
        </div>
      </div>
    </div>
    <div id="title" class="container mt-n2 pt-0 pb-2 mb-2">
      <div class="row mt-0 justify-content-center">
        <div class="col-12 align-self-center py-0">
          <p class="font-weight-bold h6 mb-0 text-center section">
            REPORTE DE INGRESOS {{ type == 1 ? "GENERAL" : "INDIVIDUAL" }}
          </p>
          <p class="mb-2 text-small font-weight-light text-center section">
            {{ dateReport }}
          </p>
        </div>
      </div>
    </div>
    <div id="detail" class="row mt-0 mb-1">
      <div class="col-8">
        <div class="row">
          <p class="mt-1 font-weight-bold text-small section">
            {{ type == 1 ? "USUARIO: " : "CAJERO: " }}
          </p>
          <p v-if="type == 1" class="mt-1 pl-1 text-small section">
            {{ user.nombres + " " + user.apellidos }}
          </p>
          <p v-else class="mt-1 pl-1 text-small section">
            {{
              user.idRol == 1
                ? details.name
                : user.nombres + " " + user.apellidos
            }}
          </p>
        </div>
      </div>
      <div class="col-4">
        <div class="row justify-content-between">
          <p class="mt-0 text-small section">Fecha:</p>
          <p class="mt-0 text-small section">{{ formatDateTimeReceipt() }}</p>
        </div>
      </div>
    </div>
    <div v-if="individualIngress.length > 0" class="row mt-0">
      <div class="col-12 py-0 pb-1">
        <p class="text-center mb-0 font-weight-bold small section">
          Ingresos Individuales
        </p>
      </div>
      <table
        id="table-ingress"
        cellspacing="0"
        class="table table-sm table-bordered mb-0"
      >
        <thead class="thead-dark">
          <tr>
            <th class="font-weight-bold py-0 px-1 section" scope="col">Nro</th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Fecha
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Recibo
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">Tipo</th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Detalle
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Total
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Descuento
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Por Cobrar
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Ingreso
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in individualIngress" :key="item.idPago">
            <td
              style="border-left: 1px solid #1486bd"
              class="text-tb-small py-0 px-1 text-center pb-1 section"
            >
              {{ item.nro }}
            </td>
            <td class="text-tb-small py-0 px-1 text-center pb-1 section">
              {{ item.fechaPago }}
            </td>
            <td class="text-tb-small py-0 px-1 text-center pb-1 section">
              {{ item.codigo }}
            </td>
            <td class="text-tb-small py-0 px-1 text-center pb-1 section">
              {{ item.tipo }}
            </td>
            <td class="text-tb-small py-0 px-1 pb-1 section">
              {{ item.detalle }}
            </td>
            <td class="text-tb-small py-0 px-1 text-right pb-1 section">
              {{ item.total }}
            </td>
            <td class="text-tb-small py-0 px-1 text-right pb-1 section">
              {{ item.descuento }}
            </td>
            <td class="text-tb-small py-0 px-1 text-right pb-1 section">
              {{ item.saldo }}
            </td>
            <td class="text-tb-small py-0 px-1 text-right pb-1 section">
              {{ item.subTotal }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-12 p-0 pt-1">
        <p class="text-right text-medium mb-0 section">
          Total Bs.
          {{
            resume.totalIndividualIngress > 0
              ? resume.totalIndividualIngress.toFixed(2)
              : resume.totalIndividualIngress
          }}
        </p>
      </div>
    </div>
    <div v-if="groupIngress.length > 0" class="row mt-4">
      <div class="col-12 py-0 pb-1">
        <p class="text-center mb-0 font-weight-bold small section">
          Ingresos Grupales
        </p>
      </div>
      <table
        id="table-ingress-group"
        cellspacing="0"
        class="table table-sm table-bordered mb-0"
      >
        <thead class="thead-dark">
          <tr>
            <th class="font-weight-bold py-0 px-1 section" scope="col">Nro</th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Fecha
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Recibo
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">Tipo</th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Detalle
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Total
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Descuento
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Por Cobrar
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Ingreso
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in groupIngress" :key="item.idPago">
            <td
              style="border-left: 1px solid #1486bd"
              class="text-tb-small py-0 px-1 text-center pb-1 section"
            >
              {{ item.nro }}
            </td>
            <td class="text-tb-small py-0 px-1 text-center pb-1 section">
              {{ item.fechaPago }}
            </td>
            <td class="text-tb-small py-0 px-1 text-center pb-1 section">
              {{ item.codigo }}
            </td>
            <td class="text-tb-small py-0 px-1 text-left pb-1 section">
              {{ item.tipo }}
            </td>
            <td class="text-tb-small py-0 px-1 pb-1 section">
              {{ item.detalle }}
            </td>
            <td class="text-tb-small py-0 px-1 text-right pb-1 section">
              {{ item.total }}
            </td>
            <td class="text-tb-small py-0 px-1 text-right pb-1 section">
              {{ item.descuento }}
            </td>
            <td class="text-tb-small py-0 px-1 text-right pb-1 section">
              {{ item.saldo }}
            </td>
            <td class="text-tb-small py-0 px-1 text-right pb-1 section">
              {{ item.subTotal }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-12 p-0 pt-1">
        <p class="text-right text-medium mb-0 section">
          Total Bs.
          {{
            resume.totalGroupIngress > 0
              ? resume.totalGroupIngress.toFixed(2)
              : resume.totalGroupIngress
          }}
        </p>
      </div>
    </div>
    <div v-if="ingress.length > 0" class="row mt-4">
      <div class="col-12 py-0 pb-1">
        <p class="text-center mb-0 font-weight-bold small section">
          Ingresos por Venta de libros, Practicas y Otros
        </p>
      </div>
      <table cellspacing="0" class="table table-sm table-bordered mb-0">
        <thead class="thead-dark">
          <tr>
            <th class="font-weight-bold py-0 px-1 section" scope="col">Nro</th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Fecha
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Recibo
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">Tipo</th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Nombre
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Detalle
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Ingreso
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in ingress" :key="item.idIngreso">
            <td
              style="border-left: 1px solid #1486bd"
              class="text-tb-small py-0 px-1 text-center pb-1 pb-1 section"
            >
              {{ item.nro }}
            </td>
            <td class="text-tb-small py-0 px-1 text-center pb-1 pb-1 section">
              {{ item.fechaPago }}
            </td>
            <td class="text-tb-small py-0 px-1 text-center pb-1 pb-1 section">
              {{ item.codigo }}
            </td>
            <td class="text-tb-small py-0 px-1 text-center pb-1 pb-1 section">
              {{ item.tipo }}
            </td>
            <td class="text-tb-small py-0 px-1 pb-1 pb-1 section">
              {{ item.nombre }}
            </td>
            <td class="text-tb-small py-0 px-1 pb-1 pb-1 section">
              {{ item.detalle }}
            </td>
            <td class="text-tb-small py-0 px-1 text-right pb-1 pb-1 section">
              {{ item.total }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-12 p-0 pt-1">
        <p class="text-right text-medium mb-0 section">
          Total Bs.
          {{
            resume.totalIngress > 0
              ? resume.totalIngress.toFixed(2)
              : resume.totalIngress
          }}
        </p>
      </div>
    </div>
    <div class="col-12">
      <p class="text-center text-medium mb-0 font-weight-bold py-2 section">
        Total Ingresos Bs.
        {{
          resume.totalIngress +
            resume.totalGroupIngress +
            resume.totalIndividualIngress >
          0
            ? (
                resume.totalIngress +
                resume.totalGroupIngress +
                resume.totalIndividualIngress
              ).toFixed(2)
            : resume.total
        }}
      </p>
    </div>
    <div v-if="egress.length > 0" class="row mt-4">
      <div class="col-12 py-0 pb-1">
        <p class="text-center mb-0 font-weight-bold small section">Egreso</p>
      </div>
      <table cellspacing="0" class="table table-sm table-bordered mb-0">
        <thead class="thead-dark">
          <tr>
            <th class="font-weight-bold py-0 px-1 section" scope="col">Nro</th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Fecha
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Recibo
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Nombre
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Detalle
            </th>
            <th class="font-weight-bold py-0 px-1 section" scope="col">
              Egreso
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in egress" :key="item.idEgreso">
            <td
              style="border-left: 1px solid #1486bd"
              class="text-tb-small py-0 px-1 text-center pb-1 section"
            >
              {{ item.nro }}
            </td>
            <td class="text-tb-small py-0 px-1 text-center pb-1 section">
              {{ item.fechaRegistro }}
            </td>
            <td class="text-tb-small py-0 px-1 text-center pb-1 section">
              {{ item.codigo }}
            </td>
            <td class="text-tb-small py-0 px-1 pb-1 section">
              {{ item.nombre }}
            </td>
            <td class="text-tb-small py-0 px-1 pb-1 section">
              {{ item.detalle }}
            </td>
            <td class="text-tb-small py-0 px-1 text-right pb-1 section">
              {{ item.total }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-12 p-0 pt-1">
        <p class="text-right text-medium mb-0 section">
          Total Bs.
          {{
            resume.totalEgress > 0
              ? resume.totalEgress.toFixed(2)
              : resume.totalEgress
          }}
        </p>
      </div>
      <div class="col-12">
        <p class="text-center text-medium mb-0 font-weight-bold py-2 section">
          Total Egreso Bs.
          {{
            resume.totalEgress > 0
              ? resume.totalEgress.toFixed(2)
              : resume.totalEgress
          }}
        </p>
      </div>
    </div>
    <div class="col-12">
      <p class="text-center mb-0 font-weight-bold py-2 section">
        Saldo Efectivo en Caja Bs.
        {{
          (
            resume.totalIngress +
            resume.totalGroupIngress +
            resume.totalIndividualIngress -
            resume.totalEgress
          ).toFixed(2)
        }}
      </p>
    </div>
    <div class="row">
      <div class="col-12 px-0">
        <div class="row justify-content-around mt-5">
          <div class="col-5 d-flex justify-content-center pt-1 px-0">
            <div class="border-top border-secondary">
              <p class="h6 small pt-1 px-2 section">{{ "CONTADORA" }}</p>
            </div>
          </div>
          <div
            v-if="type == 1"
            class="col-5 d-flex justify-content-center pt-1 px-0"
          >
            <div class="border-top border-secondary">
              <p class="h6 small pt-1 px-2 section">{{ "ENCARGADO" }}</p>
            </div>
          </div>
          <div v-else class="col-5 d-flex justify-content-center pt-1 px-0">
            <div class="border-top border-secondary">
              <p class="h6 small pt-1 px-2 section">
                {{
                  user.idRol == 1
                    ? details.name
                    : user.nombres + " " + user.apellidos
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--/vue-html2pdf-->
</template>

<script>
import jsPDF from "jspdf";
import { applyPlugin } from "jspdf-autotable";
applyPlugin(jsPDF);
//import html2pdf from "html2pdf.js";
//import VueHtml2pdf from "vue-html2pdf";
import { mapState } from "vuex";
export default {
  props: {
    individualIngress: {
      type: Array,
      default: () => [],
    },
    groupIngress: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
      default: () => {},
    },
    ingress: {
      type: Array,
      default: () => [],
    },
    egress: {
      type: Array,
      default: () => [],
    },
    resume: {
      type: Object,
      default: () => {},
    },
    departament: {
      type: String,
      default: () => "",
    },
    type: {
      type: Number,
      default: () => 0,
    },
    details: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    //VueHtml2pdf,
  },
  data() {
    return {
      htmlToPdfOptions: {
        margin: [0.4, 0, 0.4, 0],

        filename: `Reporte de Ingreso.pdf`,

        image: {
          type: "jpeg",
          quality: 0.98,
        },

        enableLinks: false,

        html2canvas: {
          scale: 1,
          useCORS: true,
        },
        pagebreak: {
          mode: ["avoid-all"],
        },
        jsPDF: {
          unit: "in",
          format: "letter",
          orientation: "portrait",
        },
      },
      startDate: "",
      endDate: "",
      dateReport: "",
      opt: {
        margin: 1,
        filename: "myfile.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      },
    };
  },
  computed: {
    ...mapState(["idDepartament"]),
  },
  created() {},
  watch: {
    details(newValue) {
      this.startDate = "";
      this.endDate = "";
      if (Object.keys(newValue).includes("startDate")) {
        this.startDate = `del ${this.formatDate(newValue.startDate)}`;
      }
      if (Object.keys(newValue).includes("endDate")) {
        this.endDate = ` al ${this.formatDate(newValue.endDate)}`;
      }

      this.dateReport = this.startDate + this.endDate;
    },
  },
  methods: {
    progress(status) {
      if (status == 100) {
        this.$emit("downloadStop");
      }
    },
    generatePdf() {
      //const element = document.getElementById("pdf");
      //html2pdf().set(this.opt).from(element).save();
      //this.$refs.html2Pdf.generatePdf();
      const pdf = new jsPDF("p", "px", "letter");
      const header = this.$refs.pdf.innerHTML;
      //pdf.text("Ingresos Individuales", 10, 10);
      /*pdf.html(header.toString());
      pdf.autoTable({
        html: "#table-ingress",
        useCss: true,
      });*/
      //pdf.text("Ingresos Grupales", 10, 10);
      //pdf.autoTable({ html: "#table-ingress-group", useCss: true });
      /*pdf.html(header, {
        callback: (pdf) => {
          pdf.save();
          //pdf.output("pdfjsnewwindow");
        },
        x: 30,
        y: 15,
        width: 400,
        height: 1040,
        windowWidth: 800,
      });*/
      pdf.html(header, {
        callback: (pdf) => {
          pdf.save(`Reporte de Ingreso.pdf`);
          this.$emit("downloadStop");
          //pdf.output("pdfjsnewwindow");
        },
        x: 10,
        y: 15,
        width: 400,
        height: 1040,
        windowWidth: 792,
        margin: 20,
      });
    },
    formatDate(date) {
      const nameMonth = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      const nameDay = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
      const dateTemp = new Date(date);
      dateTemp.setDate(dateTemp.getDate() + 1);

      const [year, month, day] = date.split("-");
      return `${nameDay[dateTemp.getDay()]} ${day} ${
        nameMonth[Number(month) - 1]
      } ${year}`;
    },
    formatDateTimeReceipt() {
      let date = new Date();
      date.setHours(date.getHours() - 4);
      let dateSub = date.toISOString().substring(0, 10);
      let timeSub = date.toISOString().substring(11, 19);
      const [year, month, day] = dateSub.split("-");
      return `${day}-${month}-${year} ${timeSub}`;
    },
  },
};
</script>
<style scoped>
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.section {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.text-small {
  font-size: 13px;
}
.text-medium {
  font-size: 11px;
}
.text-tb-small {
  font-size: 10px;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h6,
.h6 {
  font-size: 1rem;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
table {
  border-collapse: collapse;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
.table-bordered {
  border: 0px solid #1486bd;
}

.table-bordered th {
  border: 0;
}
.table-bordered td {
  border: 1px solid #1486bd;
  border-left: 0;
}
.table .thead-dark th {
  color: #fff;
  background-color: #1486bd;
  border-color: #1486bd;
  font-size: 12px;
}
table,
tr,
td {
  page-break-inside: avoid;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.pl-2,
.px-2 {
  padding-left: 1rem !important;
}

.pr-2,
.px-2 {
  padding-right: 1rem !important;
}

.text-right {
  text-align: right !important;
}
small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}
.p-0 {
  padding: 0 !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
</style>
<template>
    <v-layout>
        <v-container>
            <v-breadcrumbs class="pl-0" :items="items">
				<template v-slot:item="{ item }">
					<v-breadcrumbs-item
						:to="item.href"
						:disabled="item.disabled"
					>
						{{ item.text}}
					</v-breadcrumbs-item>
				</template>
			</v-breadcrumbs>
            <v-card outlined>
                <v-card-text>
                    <p class="text-h6 mb-0">Asistencia</p>
                    <v-container>
                        <v-form ref="formGrade">
                        <v-row class="d-flex flex-row">
                            <v-col
                                cols="12"
                                sm="2"
                                md="2"
                                class="py-0"
                            >
                                <p class="mb-2">Semestre</p>
                                <v-autocomplete
                                    v-model="formGroup.idSemester"
                                    :items="semesters"
                                    :filter="semesterFilter"
                                    outlined
                                    dense
                                    item-text="nombreSemestre"
                                    item-value="idSemestre"
                                    label="Seleccione"
                                    single-line
                                    @change="findGroup"
                                    :rules="semesterRules"
                                    no-data-text="No hay semestres disponibles"
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="2"
                                md="2"
                                class="py-0"
                            >
                                <p class="mb-2">Grupo</p>
                                <v-autocomplete
                                    v-model="formGroup.idGroup"
                                    :items="groups"
                                    :filter="groupFilter"
                                    outlined
                                    dense
                                    item-text="nombreGrupo"
                                    item-value="idGrupo"
                                    label="Seleccione"
                                    single-line
                                    @change="findSubject"
                                    :rules="groupRules"
                                    no-data-text="No hay grupos disponibles"
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="3"
                                md="3"
                                class="py-0"
                            >
                                <p class="mb-2">Materia</p>
                                <v-select
                                    v-model="formGroup.idSubject"
                                    :items="subjects"
                                    item-text="nombreMateria"
                                    item-value="idMateria"
                                    label="Seleccione"
                                    persistent-hint
                                    dense
                                    outlined
                                    single-line
									@change="changeSubject"
                                    :rules="subjectRules"
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="2"
                                md="2"
                                class="py-0"
                            >
                                <p class="mb-2">Fecha Asistencia</p>
                                <v-menu
                                    ref="menuDay"
                                    v-model="menuDay"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="formGroup.date"
                                        placeholder="Fecha"
                                        readonly
                                        single-line
                                        dense
                                        outlined
                                        :rules="dateRules"
                                        v-bind="attrs"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="formGroup.date"
                                        no-title
                                        first-day-of-week="1"
                                        :allowed-dates="permittedDate"
                                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                                        min="2010-01-01"
                                        @change="changeDate"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="3"
                                md="3"
                                class="py-0"
                                align-self="center"
                            >
                            <v-btn
                                color="#1486bd"
                                dark
                                elevation="0"
								:loading="loading"
                                @click="findStudents"
                            >
                                <span class="text-capitalize text-body">Buscar Grupo</span> 
                            </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    
                    </v-container>
                    <v-row v-show="students.length > 0" class="ma-0" justify="end" align="center">
                        <v-checkbox
                            class="mr-2"
                            v-model="checkAll"
                            label="Seleccionar Todo"
                            @change="checkAlls"
                        ></v-checkbox>
                        <v-btn @click="saveStudentAttendance" :disabled="studentSelecteds.length <= 0" :loading="loadingAttendance" color="#1486bd" dark elevation="0">
                            <span>Guardar Asistencia</span>
                        </v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
			<v-container v-if="loading" class="d-flex justify-center">
				<v-progress-circular 
					indeterminate
					color="primary"
				></v-progress-circular>
			</v-container>
			<template v-if="students.length > 0">
				<v-data-table
					:headers="headers"
					:items="students"
					item-key="idUsuario"
					class="elevation-1 font-weight-light caption td-click"
					fixed-header
                    :items-per-page="-1"
                    hide-default-footer
				>
                    <template v-slot:[`item.presente`]="{ item }">
                        <v-container class="pa-0">
                            <v-simple-checkbox
                                v-model="item.presente"
                                color="primary"
                                @input="checkAttendance(item)"
                            >
                            </v-simple-checkbox>
                        </v-container>
                    </template>
				</v-data-table>
			</template>
			<v-snackbar
				v-model="snackbar"
				:color="colorSnackbar"
				text
			>
				<span class="text-center">{{ textSnackbar }}</span>
			</v-snackbar>
        </v-container>
    </v-layout>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
export default {
    data(){
		return {
			dialog: false,
			dialogDelete: false,
			headers: [
				{text: "Nro", value: "nro", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Estudiante", value: "estudiante", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                {text: "Ci", value: "ci", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Presente", value: "presente", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
                //{text: "Acciones", value: "accion", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			],
			students: [],
            semesters: [],
            groups: [],
            subjects: [],
			editedIndex: -1,
            formGroup:{
                idGroup: '',
                idSemester: '',
                idSubject: '',
                date: null,
            },
            semesterRules: [
                value => !!value || 'Seleccione un semestre'
            ],
            groupRules: [
                value => !!value || 'Seleccione un grupo'
            ],
            subjectRules: [
                value => !!value || 'Seleccione una materia',
            ],
            dateRules: [
                value => !!value || 'Seleccione una fecha',
            ],
			loading: false,
			loadingData: false,
			snackbar: false,
			colorSnackbar: '',
			textSnackbar: '',
			editedItem: {},
			items: [
				{
					text: 'Asistencia',
					disabled: false,
					href: '/attendance',
				},
			],
            turns: [],
            menuDay: null,
            studentSelecteds: [],
            checkAll: false,
            loadingAttendance: false,
		}
	},
	computed: {
		...mapState(['user','idDepartament']),
	},
	watch: {
	},
	created () {
        this.selectSemesterTeacher();
	},
	methods: {
        checkAlls(value){
            this.studentSelecteds = [];
            if (value) {
                this.students.map((student)=>{
                    student.presente = true;
                    let data = {
                        idStudent: student.idUsuario,
                        attendance: student.presente
                    }
                    this.studentSelecteds.push(data); 
                });
            }else{
                this.studentSelecteds = [];
                this.students.map((student) => {
                    student.presente = false;
                });
            }
            console.log(value);
        },
        changeDate(){
            this.students = [];
            this.studentSelecteds = [];
            this.checkAll = false;
        },
        async insertAttendance(data){
            try {
				let response = await axios.post('attendances/insertAttendance',data);
				if (response.status == 200) {
					//this.closeGrade();
                    this.loadingAttendance = false;
                    this.studentSelecteds = [];
                    this.checkAll = false;
                    this.loading = true;
                    this.selectStudentsAttendance(this.formGroup.idGroup);
                    console.log(response.data);
					this.notification('success',response.data['msg']);
				}
			} catch (error) {
				console.log(error);
                this.loadingAttendance = false;
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
        },
        async checkAttendance(item){
            console.log(item);
            const findStudent = await this.studentSelecteds.find((student) => student.idStudent === item.idUsuario);

            if (findStudent != undefined) {
                if (item.presente) {
                    this.studentSelecteds.find((student) => {
                        student.attendance = item.presente;
                    });
                }else{
                    const index = this.studentSelecteds.findIndex((student) => student.idStudent === item.idUsuario);
                    this.studentSelecteds.splice(index,1);
                }
                
            }else{
                let data = {
                    idStudent: item.idUsuario,
                    attendance: item.presente
                }
                this.studentSelecteds.push(data);
            }
        },
        saveStudentAttendance(){
            this.loadingAttendance = true;
            const data = {
                idGroup: this.formGroup.idGroup,
                idTeacher: this.user.idUsuario,
                idSubject: this.formGroup.idSubject,
                date: this.formGroup.date,
                studentsAttendance: this.studentSelecteds
            }

            this.insertAttendance(data);
        },
        permittedDate(date) {
            const dateConst = new Date(date);
            return dateConst.getDay() !== 6;
        },
		changeSubject(){
            this.formGroup.date = '';
			this.students = [];
            this.studentSelecteds = [];
            this.checkAll = false;
		},
        async selectStudentsAttendance(idGroup){
			this.students = [];
            try {
                let response = await axios.get(`courses/selectStudentsAttendance/${idGroup}`,{params:{idTeacher: this.user.idUsuario,idSubject: this.formGroup.idSubject,date: this.formGroup.date }});
                if (response.status == 200) {
                    response.data.map((value,index) => {
                        this.students.push({
                            nro: index+1,
                            idUsuario: value.idUsuario,
                            estudiante: value.estudiante,
                            ci: value.ci,
                            telefono: value.telefono,
                            email: value.email,
                            presente: value.presente,
                        });
                    });
                    this.loading = false;
                }
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },
        findStudents(){
            if (this.$refs.formGrade.validate()) {
                this.loading = true;
                this.students = [];
                this.selectStudentsAttendance(this.formGroup.idGroup);
            }
        },
        async selectSubjectByGroup(idGroup){
            try {
                let response = await axios.get(`subjects/selectSubjectByGroup/${idGroup}/${this.idDepartament}/${this.user.idUsuario}`);
                if (response.status == 200) {
                    this.subjects = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        findSubject(item){
            this.subjects = [];
            this.formGroup.idSubject = '';
            this.formGroup.date = '';
			this.students = [];
            this.studentSelecteds = [];
            this.checkAll = false;

            this.selectSubjectByGroup(item);
        },
        groupFilter (item, queryText) {
			const textOne = item.nombreGrupo.toLowerCase();
			const searchText = queryText.toLowerCase();

			return textOne.indexOf(searchText) > -1;
		},
        async selectGroupBySemester(idSemester){
            try {
                let response = await axios.get(`groupTeachers/selectGroupBySemester/${this.idDepartament}/${this.user.idUsuario}/${idSemester}`);
                if (response.status == 200) {
                    this.groups = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        findGroup(item){
            this.groups = [];
            this.subjects = [];
            this.formGroup.idGroup = '';
            this.formGroup.idSubject = '';
            this.formGroup.date = '';
			this.students = [];
            this.studentSelecteds = [];
            this.checkAll = false;
            
            this.selectGroupBySemester(item);
        },
        semesterFilter (item, queryText) {
			const textOne = item.nombreSemestre.toLowerCase();
			const searchText = queryText.toLowerCase();

			return textOne.indexOf(searchText) > -1;
		},
        async selectSemesterTeacher(){
            try {
                let response = await axios.get(`semesters/selectSemesterTeacher/${this.idDepartament}`);
                if (response.status == 200) {
                    this.semesters = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        },

		notification(type,text){
			this.snackbar = true;
			this.colorSnackbar = type;
			this.textSnackbar = text;
		},
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
    }
}
</script>

<style>

</style>
<template>
	<v-layout align-start>
		<v-container>
			<v-breadcrumbs class="pl-0" :items="items">
				<template v-slot:item="{ item }">
					<v-breadcrumbs-item
						:to="item.href"
						:disabled="item.disabled"
					>
						{{ item.text}}
					</v-breadcrumbs-item>
				</template>
			</v-breadcrumbs>
			<v-toolbar
				flat
			>
				<v-toolbar-title>Semestres</v-toolbar-title>
				
				<v-spacer></v-spacer>
				<v-btn
					color="#1486bd"
					dark
					@click="dialog = true"
				>
					<span class="text-capitalize text-body">nuevo</span> 
				</v-btn>
				<v-dialog
				v-model="dialog"
				max-width="500px"
				>
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ formTitle }}</span>
						</v-card-title>

						<v-card-text>
							<v-container>
								<v-form ref="form">
									<p class="mb-2">Nombre</p>
									<v-text-field
										v-model="nameSemester"
										class="pt-0"
										label="Nombre"
										placeholder="Nombre"
										single-line
										dense
										outlined
										:rules="nameRules"
									>
									</v-text-field>
								</v-form>
							</v-container>
						</v-card-text>

						<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							:disabled="loadingData"
							@click="close"
						>
							<span class="text-capitalize">Cancelar</span>
						</v-btn>
						<v-btn
							color="blue darken-1"
							dark
							:loading="loadingData"
							@click="saveSemester"
						>
							<span class="text-capitalize">Guardar</span>						
						</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="400px">
					<v-card>
						<p style="color: #1874BF" class="text-h6 text-center pt-4">Informe de semestre</p>
						<v-card-text v-if="(user.idRol == 1)" class="text-center">
							{{`¿Esta seguro de ${editedItem.estado ? 'desactivar' : 'activar'} el semestre ${editedItem.nombreSemestre}?`}}
						</v-card-text>
						<v-card-text v-else class="text-center">
							¿Esta seguro de eliminar el semestre {{editedItem.nombreSemestre}}?
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
							<v-btn dark color="blue darken-1" @click="deleteItemConfirm">Si</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
			<v-container v-if="loading" class="d-flex justify-center">
				<v-progress-circular 
					indeterminate
					color="primary"
				></v-progress-circular>
			</v-container>
			<template v-if="semesters.length > 0">
				<v-data-table
					:headers="user.idRol == 1 ? headersAdmin : headers"
					:items="semesters"
					item-key="idSemestre"
					class="elevation-1 font-weight-light caption td-click"
					
					@click:row="clickRow"
					fixed-header
				>
					<template v-slot:[`item.estado`]="{item}">
						{{item.estado ? 'Activo' : 'Inactivo'}}
					</template>
					<template v-slot:[`item.accion`]="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{on,attrs}">
								<v-btn
									icon
									color="primary"
									class="mr-2"
									@click.stop="editItem(item)"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon >mdi-pencil</v-icon>
								</v-btn>
							</template>
							<span>Editar</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{on,attrs}">
								<v-btn
									v-if="user.idRol == 1"
									icon
									text
									:color="item.estado ? 'error' : 'success'"
									class="mr-2"
									@click.stop="deleteItem(item)"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon v-if="user.idRol == 1" >{{item.estado ? 'mdi-cancel' : 'mdi-check'}}</v-icon>
									<v-icon v-else >mdi-delete</v-icon>
								</v-btn>
							</template>
							<span v-if="user.idRol == 1">{{item.estado ? 'Desactivar' : 'Activar'}}</span>
							<span v-else>Eliminar</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</template>
			<template v-else>
				<v-card elevation="0" outlined v-if="loading == false">
					<v-card-text class="text-center pb-0">No existen Semestres Registrados.</v-card-text>
					<v-card-text class="text-center pt-0"><v-btn color="blue darken-1" dark small class="my-2 px-2" tile elevation="0" @click="selectSemesters">Volver a Intentar</v-btn></v-card-text>
				</v-card>
			</template>
			<v-snackbar
				v-model="snackbar"
				:color="colorSnackbar"
				text
			>
				<span class="text-center">{{ textSnackbar }}</span>
			</v-snackbar>
			<v-dialog v-model="dialogInfo" max-width="400px">
				<v-card>
					<p style="color: #1874BF" class="text-h6 text-center pt-4">Informe de semestre</p>
					<v-card-text class="text-center">
						No se puede ver los registros debido a que el semestre esta desactivado, para continuar debe activar el semestre.
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn dark color="blue darken-1" @click="dialogInfo = false">Aceptar</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-container>
	</v-layout>  
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
export default {
	data(){
		return {
			dialog: false,
			dialogDelete: false,
			headers: [
				{text: "Nombre", value: "nombreSemestre", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Acciones", value: "accion", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			],
			headersAdmin: [
				{text: "Nombre", value: "nombreSemestre", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Estado", value: "estado", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Acciones", value: "accion", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			],
			semesters: [],
			editedIndex: -1,
			nameSemester: '',
			idSemester: '',
			creationDate: new Date().toISOString().substring(0, 10),
			nameRules: [
				value => !!value || 'Ingrese un Semestre',
				value => /^(1|I|2|II)-\d{4}$/.test(value) || 'Debe ingresar un formato correcto',
			],
			loading: false,
			loadingData: false,
			snackbar: false,
			colorSnackbar: '',
			textSnackbar: '',
			editedItem: {},
			defaultItem: {
				name: '',
				calories: 0,
				fat: 0,
				carbs: 0,
				protein: 0,
			},
			items: [
				{
					text: 'Semestre',
					disabled: true,
					href: 'semester',
				}
			],
			dialogInfo: false,
		}
	},
	computed: {
		...mapState(['user','idDepartament']),
		formTitle () {
			return this.editedIndex === -1 ? 'Nuevo semestre' : 'Editar semestre';
		},
	},
	watch: {
		dialog (val) {
			val || this.close()
		},
		dialogDelete (val) {
			val || this.closeDelete()
		},
	},
	created () {
		this.selectSemesters();
	},
	methods: {
		clickRow(item){
			if (!item.estado) {
				this.dialogInfo = true;
				return;
			}
			this.$router.push(`/semester/${item.idSemestre}/group`);
		},
		async deleteSemester(data){
			try {
				let response = await axios.put('semesters/deleteSemester',data);
				if (response.status == 200) {
					this.closeDelete();
					this.notification('warning',response.data['msg']);
					this.selectSemesters();
				}
			} catch (error) {
				console.log(error);
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		async insertSemester(data){
			try {
				let response = await axios.post('semesters/insertSemester',data);
				if (response.status == 200) {
					if (response.data.type == 0) {
						this.loadingData = false;
						this.notification('success',response.data['msg']);
					}else {
						this.close();
						this.loadingData = false;
						this.notification('success',response.data['msg']);
						this.selectSemesters();
					}
					
				}
			} catch (error) {
				console.log(error);
				this.loadingData = false;
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		async updateSemester(data){
			try {
				let response = await axios.put('semesters/updateSemester',data);
				if (response.status == 200) {
					if (response.data.type == 0) {
						this.loadingData = false;
						this.notification('success',response.data['msg']);
					}else {
						this.close();
						this.loadingData = false;
						this.notification('success',response.data['msg']);
						this.selectSemesters();
					}
				}
			} catch (error) {
				console.log(error);
				this.loadingData = false;
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		saveSemester () {
			if (this.$refs.form.validate()) {
				if (this.editedIndex > -1) {
					//modificar
					let nameSemesterArray = this.nameSemester.split('-');
					let number = '';
					let year = nameSemesterArray[1];

					if (nameSemesterArray[0] == '1') {
						number = 'I'
					} else if(nameSemesterArray[0] == '2'){
						number = 'II'
					} else {
						number = nameSemesterArray[0]
					}

					let data = {
						idSemester: this.idSemester,
						nameSemester: number+' - '+year,
						numberSemester: number == 'I' ? 1 : 2,
						semesterYear: year,
						idDepartament: this.idDepartament,
					}
					this.loadingData = true;
					this.updateSemester(data);
				} else {
					let nameSemesterArray = this.nameSemester.split('-');
					let number = '';
					let year = nameSemesterArray[1];

					if (nameSemesterArray[0] == '1') {
						number = 'I'
					} else if(nameSemesterArray[0] == '2'){
						number = 'II'
					} else {
						number = nameSemesterArray[0]
					}
					
					
					let data = {
						nameSemester: number+' - '+year,
						creationDate: this.creationDate,
						numberSemester: number == 'I' ? 1 : 2,
						semesterYear: year,
						idDepartament: this.idDepartament
					};
					
					this.loadingData = true;
					
					this.insertSemester(data);
				}
			}
		},

		async selectSemesters(){
			let url = this.user.idRol == 1 ? `semesters/selectSemesters/${this.idDepartament}/null/null` : `semesters/selectSemesters/${this.idDepartament}/null/1`;
			try {
				this.loading = true;
				let response = await axios.get(url);
				if (response.status == 200) {
					this.semesters = response.data;
					this.loading = false;
				}
			} catch (error) {
				console.log(error);
				this.loading = false;
			}
		},

		notification(type,text){
			this.snackbar = true;
			this.colorSnackbar = type;
			this.textSnackbar = text;
		},

		editItem (item) {
			
			this.editedIndex = 1;
			this.editedItem = Object.assign({}, item);
			let name = this.editedItem.nombreSemestre.split('-'); 
			this.idSemester = this.editedItem.idSemestre;
			this.nameSemester = name[0].trim()+'-'+name[1].trim();
			this.dialog = true;
		},

		deleteItem (item) {
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm () {
			let data = {
				idSemester: this.editedItem.idSemestre,
				state: this.editedItem.estado ? 0 : 1,
			}
			this.deleteSemester(data);
		},

		close () {
			this.nameSemester = '';
			this.editedIndex = -1;
			this.$refs.form.reset();
			this.dialog = false;
		},

		closeDelete () {
			this.dialogDelete = false;
			this.editedItem = '';
		},

		
	}
}
</script>

<style>
	.td-click td{
		cursor: pointer;
	}
</style>
<template>
	<v-layout align-start>
		<v-container>
			<v-breadcrumbs class="pl-0" :items="items">
				<template v-slot:item="{ item }">
					<v-breadcrumbs-item
						:to="item.href"
						:disabled="item.disabled"
					>
						{{ item.text}}
					</v-breadcrumbs-item>
				</template>
			</v-breadcrumbs>
			<v-toolbar
				flat
			>
				<v-toolbar-title>Egreso</v-toolbar-title>
				<v-tooltip top>
					<template v-slot:activator="{on,attrs}">
						<v-btn
							icon
							:color="search ? 'red' : 'blue'"
							class="mx-1"
							@click.stop="openAdvancedSearch"
							v-bind="attrs"
							v-on="on"
						>
						<v-icon> {{ search ? 'mdi-magnify-close' : 'mdi-magnify' }}</v-icon>
						</v-btn>
					</template>
					<span>{{search ? 'Cerrar Busqueda' : 'Busqueda Avanzada'}}</span>
				</v-tooltip>
				<v-spacer></v-spacer>
				<v-btn
					color="red darken-2"
					dark
					@click="openDialog(1)"
				>
					<span class="text-capitalize text-body-2">Agregar egreso</span> 
				</v-btn>
				<v-dialog
				v-model="dialog"
				max-width="650px"
				>
					<v-card>
						<v-card-title>
							<span class="text-h5">{{ formTitle }}</span>
						</v-card-title>

						<v-card-text>
							<v-container>
								<v-form ref="form">
									<v-row>
										<v-col
											cols="12"
											sm="4"
											md="4"
											class="py-0"
										>
											<p class="mb-2">Pago</p>
											<v-text-field
												v-model="formEgress.total"
												class="pt-0"
												placeholder="Pago"
												single-line
												dense
												outlined
												:disabled="disabled"
												:rules="paymentRules"
											>
											</v-text-field>
										</v-col>
										<v-col
											cols="12"
											sm="8"
											md="8"
											class="py-0"
										>
											<p class="mb-2">Nombre</p>
											<v-text-field
												v-model="formEgress.name"
												class="pt-0"
												placeholder="Nombre"
												single-line
												dense
												outlined
												:rules="nameRules"
											>
											</v-text-field>
										</v-col>
										<v-col
											cols="12"
											sm="12"
											md="12"
											class="py-0"
										>
											<p class="mb-2">Detalle</p>
											<v-text-field
												v-model="formEgress.egressDetail"
												class="pt-0"
												placeholder="Detalle"
												single-line
												dense
												outlined
											>
											</v-text-field>
										</v-col>
									</v-row>
								</v-form>
							</v-container>
						</v-card-text>

						<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							:disabled="loadingData"
							@click="close"
						>
							<span class="text-capitalize">Cancelar</span>
						</v-btn>
						<v-btn
							color="blue darken-1"
							dark
							:loading="loadingData"
							@click="saveEgress"
						>
							<span class="text-capitalize">Guardar egreso</span>						
						</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="400px">
					<v-card>
						<p style="color: #1874BF" class="text-h6 text-center pt-4">Informe de Egreso</p>
						<v-card-text class="text-center">
							{{`¿Esta seguro de eliminar el egreso ${editedItem.detalleEgreso}?`}}
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
							<v-btn dark color="blue darken-1" @click="deleteItemConfirm">Si</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
			<div v-show="search">
				<v-card outlined tile>
					<v-card-text class="mt-1">
						<p class="font-weight-bold text-body-1" style="color: #1CA5DB;">Buscar Egreso</p>
						<v-form ref="formSearch">
							<v-row>
								<v-col
									cols="12"
									sm="4"
									md="4"
									class="py-0"
								>
									<p class="mb-2">Nombre</p>
									<v-text-field
										v-model="formSearch.name"
										class="pt-0"
										placeholder="Nombre"
										single-line
										dense
										outlined
									>
									</v-text-field>
								</v-col>
								<v-col
									cols="12"
									sm="4"
									md="4"
									class="py-0"
								>
									<p class="mb-2">Total</p>
									<v-text-field
										v-model="formSearch.total"
										class="pt-0"
										placeholder="Total"
										single-line
										dense
										outlined
									>
									</v-text-field>
								</v-col>
								<v-col
									cols="12"
									sm="4"
									md="4"
									class="py-0"
								>
									<p class="mb-2">Fecha Inicial</p>
									<v-menu
										ref="menuStart"
										v-model="menuStart"
										:close-on-content-click="false"
										transition="scale-transition"
										offset-y
										min-width="auto"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
											v-model="formSearch.startDate"
											placeholder="Fecha Inicial"
											readonly
											single-line
											dense
											outlined
											v-bind="attrs"
											v-on="on"
											></v-text-field>
										</template>
										<v-date-picker
											v-model="formSearch.startDate"
											no-title
											:active-picker.sync="activePicker"
											:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
											min="2010-01-01"
											@change="saveStart"
										></v-date-picker>
									</v-menu>
								</v-col>
								<v-col
									cols="12"
									sm="4"
									md="4"
									class="py-0"
								>
									<p class="mb-2">Fecha Final</p>
									<v-menu
										ref="menuEnd"
										v-model="menuEnd"
										:close-on-content-click="false"
										transition="scale-transition"
										offset-y
										min-width="auto"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
											v-model="formSearch.endDate"
											placeholder="Fecha Final"
											readonly
											single-line
											dense
											outlined
											v-bind="attrs"
											v-on="on"
											></v-text-field>
										</template>
										<v-date-picker
											v-model="formSearch.endDate"
											no-title
											:active-picker.sync="activePicker"
											:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
											min="2010-01-01"
											@change="saveEnd"
										></v-date-picker>
									</v-menu>
								</v-col>
								<v-col
									cols="12"
									sm="4"
									md="4"
									class="py-0 d-flex align-center"
								>
									<v-row>
										<v-btn
											class="ml-2"
											dark
											color="#1CA5DB" 
											elevation="0"
											@click="searchEgress"
										>
											<span class="text-capitalize text-body">Buscar</span> 
										</v-btn>
										<v-spacer />
										<div v-if="user.idRol == 1">
											<v-tooltip top>
												<template v-slot:activator="{on,attrs}">
													<v-btn 
														v-show="egress.length > 0"
														class="ml-3 mr-2"
														color="primary"
														fab
														depressed
														small
														dark
														v-bind="attrs"
														v-on="on"
														@click="print"
													>
														<v-icon>mdi-printer</v-icon>
													</v-btn>
												</template>
												<span>Imprimir Reporte</span>
											</v-tooltip>
											<v-tooltip top>
												<template v-slot:activator="{on,attrs}">
													<v-btn 
														v-show="egress.length > 0"
														class="ml-3 mr-2"
														color="#1D6F42"
														fab
														depressed
														small
														dark
														v-bind="attrs"
														v-on="on"
														@click="downloadXlsx"
													>
														<v-icon>mdi-microsoft-excel</v-icon>
													</v-btn>
												</template>
												<span>Descargar Excel</span>
											</v-tooltip>
										</div>
									</v-row>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
				</v-card>
			</div>
			<v-container v-if="loading" class="d-flex justify-center">
				<v-progress-circular 
					indeterminate
					color="primary"
				></v-progress-circular>
			</v-container>
			<template v-if="egress.length > 0">
				<v-data-table
					:headers="headers"
					:items="egress"
					item-key="idEgreso"
					class="elevation-1 font-weight-light caption"
					
					
					fixed-header
				>
					<template v-slot:[`item.estado`]="{item}">
						{{item.estado ? 'Activo' : 'Inactivo'}}
					</template>
					<template v-slot:[`item.accion`]="{ item }">
						<v-tooltip top>
							<template v-slot:activator="{on,attrs}">
								<v-btn
									icon
									color="primary"
									class="mr-2"
									@click.stop="editItem(item)"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</template>
							<span>Editar</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{on,attrs}">
								<v-btn
									icon
									color="light-blue "
									class="mr-2"
									@click.stop="printItem(item)"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-printer</v-icon>
								</v-btn>
							</template>
							<span>Imprimir</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{on,attrs}">
								<v-btn
									icon
									:color="'error'"
									class="mr-2"
									@click.stop="deleteItem(item)"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>{{'mdi-delete'}}</v-icon>
								</v-btn>
							</template>
							<span>{{'Eliminar'}}</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</template>
			<template v-else>
				<v-card elevation="0" outlined v-if="loading == false">
					<v-card-text class="text-center pb-0">No existen Egresos Registrados.</v-card-text>
					<v-card-text class="text-center pt-0"><v-btn color="blue darken-1" dark small class="my-2 px-2" tile elevation="0" @click="selectEgress">Volver a Intentar</v-btn></v-card-text>
				</v-card>
			</template>
			<v-snackbar
				v-model="snackbar"
				:color="colorSnackbar"
				text
			>
				<span class="text-center">{{ textSnackbar }}</span>
			</v-snackbar>
			<ReceiptEgress ref="receipt" :item="itemReceipt" :user="user" :departament="nameDepartament"/>
			<DialogDownload :dialog="dialogDownload"/>
			<ReportGeneralEgress ref="reportGeneralEgress" @downloadStop="dialogDownloadStop" :user="user" :egress="egress" :departament="nameDepartament" :details="detailsComponent" :totalEgress="totalEgress" />
		</v-container>
	</v-layout>  
</template>

<script>
import ReceiptEgress from '@/components/receipt/receipt-egress-component.vue';
import DialogDownload from '@/components/dialog/dialog-download-component.vue';
import ReportGeneralEgress from '@/components/report/report-general-egress-component.vue';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { mapState } from 'vuex';
export default {
	components:{
		ReceiptEgress,
		DialogDownload,
		ReportGeneralEgress,
	},	
	data(){
		return {
			dialog: false,
			dialogPackages: false,
			dialogPractices: false,
			dialogDelete: false,
			headers: [
				{text: "Nro Egreso", value: "codigo", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Nombre", value: "nombre", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Total Bs.", value: "total", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Detalle", value: "detalleEgreso", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				//{text: "Estado", value: "estado", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
				{text: "Acciones", value: "accion", align: 'center', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
			],
			egress: [],
			editedIndex: -1,
			formEgress: {
				idEgress: '',
				registerDate: '',
				name: '',
				egressDetail: '',
				total: '',
			},
			nameRules: [
				value => !!value || 'Ingrese un Nombre',
			],
			paymentRules: [
				value => !!value || 'Ingrese un Pago'
			],
			loading: false,
			loadingData: false,
			snackbar: false,
			colorSnackbar: '',
			textSnackbar: '',
			editedItem: {},
			defaultItem: {},
			items: [
				{
					text: 'Egreso',
					disabled: true,
					href: 'turn',
				}
			],
			itemReceipt: {},
			//advanced search
			search: false,
			formSearch: {
				name: '',
				total: '',
				startDate: '',
				endDate: '',
			},
			types: [],
			activePicker: null,
			date: null,
			menuStart: false,
			menuEnd: false,
			nameDepartament: '',
			disabled: false,
			dialogDownload: false,
			detailsComponent: {},
			totalEgress: 0,
		}
	},
	computed: {
		...mapState(['user','idDepartament']),
		formTitle () {
			return this.editedIndex === -1 ? 'Agregar Egreso' : 'Editar Egreso';
		},
	},
	watch: {
		dialog (val) {
			val || this.close();
		},
		dialogDelete (val) {
			val || this.closeDelete();
		},
		menuStart (val) {
			val && setTimeout(() => (this.activePicker = 'YEAR'))
		},
		menuEnd (val) {
			val && setTimeout(() => (this.activePicker = 'YEAR'))
		},
	},
	created () {
		this.selectEgress();
		this.user.idRol == 1 ? this.selectDepartamentById(): this.nameDepartament = this.user.departamento;
	},
	methods: {
		dialogDownloadStop(){
            this.dialogDownload = false;
        },
		//print data
		print(){
            this.dialogDownload = true;
            this.$refs.reportGeneralEgress.generatePdf();
        },
		downloadXlsx(){
            this.dialogDownload = true;
            let wb = XLSX.utils.book_new();
			if (this.egress.length > 0) {
                const data = XLSX.utils.json_to_sheet(this.egress,{
						header: [
                        'codigo',
                        'nombre',
                        'total',
                        'detalleEgreso', 
                        ]
					});
                data['A1'].v = 'Nro Egreso';
                data['B1'].v = 'Nombre';
                data['C1'].v = 'Total Bs';
                data['D1'].v = 'Detalle';
                XLSX.utils.book_append_sheet(wb, data, 'Egresos');	
            }
            
			XLSX.writeFile(wb,'ReporteEgresos.xlsx');	
            this.dialogDownload = false;
		},
		async selectDepartamentById(){
            try {
                let response = await axios.get(`departaments/selectDepartamentById/${this.idDepartament}`);
                if (response.status == 200) {
                    this.nameDepartament = response.data.nombreDepartamento;
                }
            } catch (error) {
                console.log(error);
            }
        },
		//module advanced search
		openAdvancedSearch(){
			this.search = !this.search;
			if (this.search == false) {
				this.formSearch.endDate = '';
				this.formSearch.name = '';
				this.formSearch.startDate = '';
				this.formSearch.total = '';
				this.selectEgress();
				this.egress = [];
			}
		},	
		async selectSearchEgress(url){
			try {
				this.egress = [];
				this.totalEgress = 0;
				this.loading = true;
				let response = await axios.get(`egress/selectSearchEgress/${url}`);
                if (response.status == 200) {
					response.data.map((value,index) => {
						value.nro = index +1;
						this.totalEgress = this.totalEgress + Number(value.total);
					});
                    this.egress = response.data;
                    if (response.data.length <= 0) {
                        this.notification('primary','No se encontro coincidencias');
                    }
					this.loading = false;
                }
			} catch (error) {
				console.log(error);
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
				this.loading = false;
			}
		},
		searchEgress(){
			let name = this.formSearch.name == '' ? 'null' : this.formSearch.name;
			let total = this.formSearch.total == '' ? 'null' : this.formSearch.total;
			let startDate = this.formSearch.startDate == '' ? 'null' : this.formSearch.startDate;
			let endDate = this.formSearch.endDate == '' ? 'null' : this.formSearch.endDate;
			let url = `${this.idDepartament}/${name}/${total}/${startDate}/${endDate}`;

			this.selectSearchEgress(url);
		},
		saveStart (date) {
			this.$refs.menuStart.save(date);
			delete this.detailsComponent.endDate;
            delete this.detailsComponent.startDate;
            this.detailsComponent = {
                ...this.detailsComponent,
                startDate: date    
            }
		},
		saveEnd (date) {
			this.$refs.menuEnd.save(date);
			delete this.detailsComponent.endDate;
            this.detailsComponent = {
                ...this.detailsComponent,
                endDate: date    
            }
		},
		//module egress
		printItem(item){
			const date = this.formatDateTimeReceipt(item.fechaRegistro);
			this.itemReceipt = item;
			this.itemReceipt.fechaRegistro = date;
			this.$refs.receipt.generatePdf();
		},
		async insertEgress(data){
			try {
				let response = await axios.post('egress/insertEgress',data);
				if (response.status == 200) {
					this.close();
					this.loadingData = false;
					this.notification('success',response.data['msg']);
					this.printItem(response.data.data);
					this.selectEgress();
				}
			} catch (error) {
				console.log(error);
				this.loadingData = false;
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		async updateEgress(data){
			try {
				let response = await axios.put('egress/updateEgress',data);
				if (response.status == 200) {
					this.close();
					this.loadingData = false;
					this.notification('success',response.data['msg']);
					this.selectEgress();
				}
			} catch (error) {
				console.log(error);
				this.loadingData = false;
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		async deleteEgress(data){
			try {
				let response = await axios.put('egress/deleteEgress',data);
				if (response.status == 200) {
					this.closeDelete();
					this.notification('warning',response.data['msg']);
					this.selectEgress();
				}
			} catch (error) {
				console.log(error);
				this.notification('error','Ocurrio algo inesperado. Por favor intente de nuevo.');
			}
		},
		async openDialog(option){
			if (option == 1) {
				this.dialog = true;
			} 
		},
		saveEgress() {
			if (this.$refs.form.validate()) {
				if (this.editedIndex > -1) {
					//modificar
					let data = {
						name: this.formEgress.name.trim().toUpperCase(),
						egressDetail: this.formEgress.egressDetail.trim().toUpperCase(),
						total: this.formEgress.total,
						idEgress: this.formEgress.idEgress
					};
					this.loadingData = true;
					this.updateEgress(data);
				} else {
					let data = {
						name: this.formEgress.name.trim().toUpperCase(),
						egressDetail: this.formEgress.egressDetail.trim().toUpperCase(),
						total: this.formEgress.total,
						idUser: this.user.idUsuario,
						idDepartament: this.user.idRol == 1 ? this.idDepartament : this.user.idDepartamento
					};
					this.loadingData = true;
					
					this.insertEgress(data);
				}
			}
		},
		
		async selectEgress(){
			const id = this.user.idRol == 1 ? this.idDepartament : this.user.idDepartamento;
			try {
				this.totalEgress = 0;
				this.loading = true;
				let response = await axios.get(`egress/selectEgress/${id}`);
				if (response.status == 200) {
					console.log(response.data);
					response.data.map((value,index) => {
						value.nro = index +1;
						this.totalEgress = this.totalEgress + Number(value.total);
					});
					this.egress = response.data;
					this.loading = false;
				}
			} catch (error) {
				console.log(error);
				this.loading = false;
			}
		},

		notification(type,text){
			this.snackbar = true;
			this.colorSnackbar = type;
			this.textSnackbar = text;
		},

		async editItem(item) {
			this.disabled = this.user.idRol == 1 ? false : true;
            this.editedIndex = 1;
            this.editedItem = Object.assign({}, item);
            this.formEgress.egressDetail = item.detalleEgreso;
            this.formEgress.idEgress = item.idEgreso;
            this.formEgress.name = item.nombre;
            this.formEgress.registerDate = item.fechaRegistro;
            this.formEgress.total = item.total;
            this.dialog = true;	
		},

		deleteItem(item) {
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			let data = {
				idEgress: this.editedItem.idEgreso,
				state: this.editedItem.estado ? 0 : 1,
			}
			this.deleteEgress(data);
		},

		close() {
            this.editedIndex = -1;
            this.dialog = false;
			this.disabled = false;
            this.formEgress.idEgress = '';
            this.formEgress.egressDetail = '';
            this.formEgress.name = '';
            this.formEgress.registerDate = '';
            this.formEgress.total = '';
			this.$refs.form.resetValidation();
		},

		closeDelete() {
			this.dialogDelete = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		formatDateTimeReceipt(date){
			if (!date) return null;
			let dateSub = date.substring(0,10);
			let timeSub = date.substring(11,19);
			const [year,month,day] = dateSub.split('-');
			return `${day}-${month}-${year} ${timeSub}`
		},
		
	}
}
</script>

<style>
	.td-click td{
		cursor: pointer;
	}
</style>
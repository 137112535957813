<template>
    <v-app-bar
        app
        color="#1486bd"
        :clipped-left="true"
        elevation="0"
        dark
    >
      <v-app-bar-nav-icon v-if="appIcon" @click="drawerL"></v-app-bar-nav-icon>

        <v-toolbar-title> <router-link class="white--text text-decoration-none" :to="selectHome">Isaac Newton</router-link></v-toolbar-title>
        <div class="ml-2" v-if="optionsGroup.length > 0">
            <v-menu
            open-on-hover
            bottom
            offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="white" v-bind="attrs" v-on="on" class="d-none d-sm-flex ml-4 text-capitalize font-weight-medium caption px-2" text dark>
                        {{'Grupos y Mensualidades'}}
                        <v-icon right small>
                            mdi-chevron-down
                        </v-icon>
                    </v-btn>
                </template>
                <v-list nav dense>
                    <v-list-item-group
                    color="#1486bd"
                    >
                    <v-list-item
                        v-for="(item, i) in optionsGroup"
                        :key="i"
                        :to="{ name: item.name}"
                    >
                        <v-list-item-icon class="mr-2">
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title class="text-caption font-weight-regular">{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
		</div>
        <div class="ml-2" v-if="optionsSchedule.length > 0">
            <v-menu
            open-on-hover
            bottom
            offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="white" v-bind="attrs" v-on="on" class="d-none d-sm-flex ml-4 text-capitalize font-weight-medium caption px-2" text dark>
                        {{'Horas Individuales'}}
                        <v-icon right small>
                            mdi-chevron-down
                        </v-icon>
                    </v-btn>
                </template>
                <v-list nav dense>
                    <v-list-item-group
                    color="#1486bd"
                    >
                    <v-list-item
                        v-for="(item, i) in optionsSchedule"
                        :key="i"
                        :to="{ name: item.name}"
                    >
                        <v-list-item-icon class="mr-2">
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title class="text-caption font-weight-regular">{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
		</div>
        <div class="ml-2" v-if="optionsIngress.length > 0">
            <v-menu
            open-on-hover
            bottom
            offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="white" v-bind="attrs" v-on="on" class="d-none d-sm-flex ml-4 text-capitalize font-weight-medium caption px-2" text dark>
                        {{'Ingresos y Egresos'}}
                        <v-icon right small>
                            mdi-chevron-down
                        </v-icon>
                    </v-btn>
                </template>
                <v-list nav dense>
                    <v-list-item-group
                    color="#1486bd"
                    >
                    <v-list-item
                        v-for="(item, i) in optionsIngress"
                        :key="i"
                        :to="{ name: item.name}"
                    >
                        <v-list-item-icon class="mr-2">
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title class="text-caption font-weight-regular">{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
		</div>
        <div class="ml-2" v-if="optionsStudentGrade.length > 0">
            <v-menu
            open-on-hover
            bottom
            offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="white" v-bind="attrs" v-on="on" class="d-none d-sm-flex ml-4 text-capitalize font-weight-medium caption px-2" text dark>
                        {{'Calificaciones'}}
                        <v-icon right small>
                            mdi-chevron-down
                        </v-icon>
                    </v-btn>
                </template>
                <v-list nav dense>
                    <v-list-item-group
                    color="#1486bd"
                    >
                    <v-list-item
                        v-for="(item, i) in optionsStudentGrade"
                        :key="i"
                        :to="{ name: item.name}"
                    >
                        <v-list-item-icon class="mr-2">
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title class="text-caption font-weight-regular">{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
		</div>
        <div class="ml-2" v-if="optionsStudentCredential.length > 0">
            <v-menu
            open-on-hover
            bottom
            offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="white" v-bind="attrs" v-on="on" class="d-none d-sm-flex ml-4 text-capitalize font-weight-medium caption px-2" text dark>
                        {{'Credenciales'}}
                        <v-icon right small>
                            mdi-chevron-down
                        </v-icon>
                    </v-btn>
                </template>
                <v-list nav dense>
                    <v-list-item-group
                    color="#1486bd"
                    >
                    <v-list-item
                        v-for="(item, i) in optionsStudentCredential"
                        :key="i"
                        :to="{ name: item.name}"
                    >
                        <v-list-item-icon class="mr-2">
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title class="text-caption font-weight-regular">{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
		</div>
        <div class="ml-2" v-if="optionsReport.length > 0">
            <v-menu
            open-on-hover
            bottom
            offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="white" v-bind="attrs" v-on="on" class="d-none d-sm-flex ml-4 text-capitalize font-weight-medium caption px-2" text dark>
                        {{'Reportes'}}
                        <v-icon right small>
                            mdi-chevron-down
                        </v-icon>
                    </v-btn>
                </template>
                <v-list nav dense>
                    <v-list-item-group
                    color="#1486bd"
                    >
                    <v-list-item
                        v-for="(item, i) in optionsReport"
                        :key="i"
                        :to="{ name: item.name}"
                    >
                        <v-list-item-icon class="mr-2">
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title class="text-caption font-weight-regular">{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
		</div>
        <div class="ml-2" v-if="optionsAdmin.length > 0">
            <v-menu
            open-on-hover
            bottom
            offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="white" v-bind="attrs" v-on="on" class="d-none d-sm-flex ml-4 text-capitalize font-weight-medium caption px-2" text dark>
                        {{'Administracion'}}
                        <v-icon right small>
                            mdi-chevron-down
                        </v-icon>
                    </v-btn>
                </template>
                <v-list nav dense>
                    <v-list-item-group
                    color="#1486bd"
                    >
                    <v-list-item
                        v-for="(item, i) in optionsAdmin"
                        :key="i"
                        :to="{ path: item.name}"
                    >
                        <v-list-item-icon class="mr-2">
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title class="text-caption font-weight-regular">{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
		</div>
        <v-spacer></v-spacer>
        <p class="ml-10 my-auto mr-2 d-none d-sm-flex d-md-flex">{{usuario}}</p>
        <v-menu
            bottom
            min-width="200px"
            rounded
            offset-y
        >
            <template v-slot:activator="{ on }">
            <v-btn
                class="mr-2"
                icon
                large
                v-on="on"
            >
                <v-avatar
                size="40"
                >
                    <span class="white--text text-h6">{{ userName }}</span>
                </v-avatar>
            </v-btn>
            </template>
            <v-card>
                <v-list-item-content class="justify-center pb-0">
                    <div class="mx-auto text-center">
                    <v-avatar
                        color="#1486bd"
                    >
                        <span class="white--text text-h5">{{ userName }}</span>
                    </v-avatar>
                    <h4 class="mt-2">{{ usuario}}</h4>
                    <p class="text-body mt-1 mb-1">
                        {{ user.rol }}
                    </p>
                    <v-divider class="my-0"></v-divider>
                    
                    </div>
                </v-list-item-content>
                <v-list-item class="px-2">
                    <v-btn
                        class="pl-3"
                        text
                        @click.stop="changeCredentials"
                    >
                        <v-icon left color="#757575">
                            mdi-lock
                        </v-icon>
                        <p class="mb-0 text-capitalize text-body-2 font-weight-medium" style="color: #757575">Cambiar contraseña</p>
                    </v-btn>
                </v-list-item>
                <v-divider class="my-0"></v-divider>
                <v-list-item class="px-2">
                    <v-btn
                        class="pl-0"
                        text
                        @click="logout"
                        style="width:100%; "
                    >   
                        <v-icon left color="#757575">
                            mdi-logout
                        </v-icon>
                        <p class="mb-0 text-capitalize text-body-2 font-weight-medium text-left" style="color: #757575">Cerrar sesión</p>
                    </v-btn>
                </v-list-item>
            </v-card>
        </v-menu>
        <CredentialChangeUser ref="credentialChange" @notification="notificationChange" @deleteUserSelected="deleteUserSelected" :user="userSelected"/>
    </v-app-bar>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import CredentialChangeUser from '@/components/credential/credential-change-user-component.vue';
export default {
    components:{
		CredentialChangeUser,
	},
    data(){
        return{
            users: {
                initials: 'JD',
                fullName: 'John Doe',
                email: 'john.doe@doe.com',
            },
            userSelected: {},
            snackbar: false,
			colorSnackbar: '',
			textSnackbar: '',
        }
    },
    computed: {
        ...mapState(['user','drawer','appIcon','optionsGroup','optionsSchedule','optionsAdmin','optionsReport','optionsIngress','optionsStudentGrade','optionsStudentCredential']),
        userName(){
            return this.user.nombres.substring(0,1) + this.user.apellidos.substring(0,1);
        },
        usuario(){
            let firstName = this.user.nombres.split(' ');
            let lastName = this.user.apellidos.split(' ');
            return firstName[0] + ' ' +lastName[0];
        },
        selectHome(){
            let res = '';
            if (this.user.idRol == 1) {
                res = 'home';
            } else if (this.user.idRol == 2) {
                res = 'home-administrative';
            }
            return res;
        }
    },
    created(){

    },
    methods: {
        ...mapMutations(['setDrawer']),
        ...mapActions(['logout']),
        //changeCredentials
		notificationChange(response){
			this.notification(response.type,response.text);
		},
		deleteUserSelected(){
			this.userSelected = {};
		},
		changeCredentials(){
			this.userSelected = this.user;
			this.$refs.credentialChange.openDialog();
		},
        drawerL(){
            console.log(this.drawer);
            this.setDrawer(!this.drawer);
        },
        notification(type,text){
			this.snackbar = true;
			this.colorSnackbar = type;
			this.textSnackbar = text;
		},
    }
}
</script>

<style>

</style>